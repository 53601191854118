const config = {
  name: 'airflex',
  displayFirstNestedRoute: true,
  animateRoutes: true,
  drawerWidth: 255,
  pictures: {
    logo: require('../assets/images/app/airflex-logo.png').default,
    loader: require('../assets/images/app/airflex-logo.png').default,
    signIn: require('../assets/images/app/bg-login.jpg').default,
    signUp: require('../assets/images/app/bg-login.jpg').default,
    logoLight: require('../assets/images/app/airflex-logo.png').default,
    logoDark: require('../assets/images/app/airflex-logo.png').default,
  },
}

export default config
