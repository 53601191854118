import React from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  useTheme,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import Button from './Button'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    h2: {
      fontSize: '2rem',
    },
  },
}))

const Dialog = ({
  title,
  subtitle = null,
  handleClose,
  open,
  handleValid,
  ctaCancel = 'Annuler',
  ctaValid = 'Valider',
  children = null,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography
          className={classes.title}
          id="alert-dialog-title"
          variant="h2"
          style={{ padding: 24 }}
        >
          {title}
        </Typography>
        {subtitle && (
          <DialogTitle id="alert-dialog-subtitle">{subtitle}</DialogTitle>
        )}
        {children && <DialogContent>{children}</DialogContent>}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {ctaCancel}
          </Button>
          <Button onClick={handleValid} variant="contained">
            {ctaValid}
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  )
}

export default Dialog
