import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { useForm, UseFormSetError } from 'react-hook-form'
import { CAMPAIGN_ACTION_TYPE } from '@airflex/lib-models'
import clsx from 'clsx'

import RichText from '../../RichText'
import CustomFileInput from '../../CustomFileInput'

export type InputsActionSocial = {
  type: CAMPAIGN_ACTION_TYPE
  title: string
  description: string
  url: string
  textButton: string
  image: string
  textSms: string
}

interface IChooseActionSocialProps {
  onSubmit: (data: any) => Promise<void>
  onSubmitAdd: (
    data: any,
    setError: UseFormSetError<InputsActionSocial>
  ) => void
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],

    '&:hover:not(&:disabled)': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

const ChooseActionSocial = ({
  onSubmit,
  onSubmitAdd,
}: IChooseActionSocialProps) => {
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
    getValues,
    setError,
  } = useForm<InputsActionSocial>({
    defaultValues: {
      type: CAMPAIGN_ACTION_TYPE.SOCIAL,
    },
    mode: 'onChange',
  })

  const rules = {
    type: register('type', {
      required: { value: true, message: 'Choisir un type' },
    }),
    title: register('title', {
      required: { value: true, message: 'Choisir un titre' },
    }),
    description: register('description', {
      required: { value: true, message: 'Choisir une description' },
    }),
    url: register('url', {
      required: { value: true, message: 'Choisir une url' },
    }),
    textButton: register('textButton', {
      required: { value: true, message: 'Choisir le texte du bouton' },
    }),
    image: register('image'),
  }

  const handleClearErrors = () => {
    clearErrors('title')
    clearErrors('description')
    clearErrors('url')
    clearErrors('textButton')
    clearErrors('image')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        label="Titre action Social"
        type="text"
        {...rules.title}
      />
      <div className={classes.label}>description</div>
      <RichText control={control} name={rules.description.name} />
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        label="Url du bouton"
        type="text"
        {...rules.url}
      />
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        label="Texte du bouton"
        type="text"
        {...rules.textButton}
      />
      <CustomFileInput
        clearOnFocus={handleClearErrors}
        control={control}
        deleteCurrentImage={null}
        name="image"
      />
      <button
        type="button"
        className={clsx(classes.btn, classes.btnBorder)}
        disabled={Object.keys(errors).some((val) => !!val)}
        onClick={() => onSubmitAdd(getValues(), setError)}
      >
        Ajouter une action
      </button>
      <button
        type="submit"
        className={clsx(classes.btn, classes.btnBlue)}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        Suivant
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`action_social-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseActionSocial
