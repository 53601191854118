import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import FormBrandProvider from './hooks/useFormBrand'
import Root from './components/Root'
import { AuthProvider } from './hooks/useAuth.tsx'
import { useThemeScheme } from './res/themes/theme-context'
import myTheme from './res/themes/myTheme'
import OpenDrawerProvider from './res/context/openDrawer'
import FormCampaignProvider from './hooks/useFormCampaign'
import FormWheelProvider from './hooks/useFormWheel'

export const queryClient = new QueryClient()

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  tokenKey: 'access_token', // The token key in the response
  endpoints: {
    user: '/me', // GET
    login: '/authentication/login', // POST
    register: '/register', // POST
  },
}

function App() {
  const { scheme } = useThemeScheme()

  const theme = myTheme(scheme)

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider config={config}>
          <ThemeProvider theme={theme}>
            <OpenDrawerProvider>
              <FormBrandProvider>
                <FormCampaignProvider>
                  <FormWheelProvider>
                    <CssBaseline />
                    <Root />
                  </FormWheelProvider>
                </FormCampaignProvider>
              </FormBrandProvider>
            </OpenDrawerProvider>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  )
}

export default App

// forceFirstNestedRoute
// name
// logo
