import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import {
  Drawer as DrawerDefault,
  List,
  useMediaQuery,
  ClickAwayListener,
} from '@material-ui/core'
import { RouteInfoTypes, RouteTypes } from '../res/types'
import config from '../res/config'

import SwitchTheme from './SwitchTheme'
import MenuButtonDrawer from './MenuButtonDrawer'
import RecursiveTreeView from './RecursiveTree'
import { useOpenDrawer } from '../res/context/openDrawer'

const DRAWER_WIDTH = config.drawerWidth

export interface IDrawerProps {
  routes: RouteTypes[]
  routeInfo?: RouteInfoTypes
}

export type DrawerStyleTypes = {
  open: boolean
}

const useStyles = makeStyles<Theme, DrawerStyleTypes>((theme) => ({
  /**
   * Drawer.
   */
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 15,
    width: DRAWER_WIDTH,
    transition: 'width 0.2s',

    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(-100%)',
    },
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    overflowX: 'hidden',
    transition: 'all 0.2s',
    padding: '132px 20px 72px',
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.down('md')]: {
      boxShadow: '5px 0 4rem rgb(0 0 0 / 10%)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(0)',
    },
  },
  drawerClose: {
    width: DRAWER_WIDTH / 2.655,
    padding: '132px 20px 72px',
    transition: 'all 0.2s',
    backgroundColor: theme.palette.background.default,
  },
  borderLeft: {
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },

  /**
   * Header.
   */
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 132,
    paddingTop: 12,
  },
  logo: {
    display: 'inline-block',
    marginRight: 28,
  },

  /**
   * Body.
   */
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
    scrollbarWidth: 'none',

    [theme.breakpoints.down('md')]: {
      overflow: 'visible',
    },
  },
  nav: {
    marginBottom: 16,
  },

  /**
   * Footer.
   */
  footer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 72,
    transition: 'padding 0.2s',

    padding: ({ open }) => (open ? '0 20px' : '0 12px'),
  },
}))

export default function Drawer(props: IDrawerProps) {
  const { routes } = props || {}
  const { open, setOpen } = useOpenDrawer()

  const classes = useStyles({ open })
  const theme = useTheme()

  const matchesMd = useMediaQuery(theme.breakpoints.down('md'))
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setOpen(!matchesMd)
    document.body.classList.remove('no-scroll')
  }, [matchesMd])

  const handleClickAway = () => {
    if (matchesMd && !matchesSm) {
      setOpen(false)
      document.body.classList.remove('no-scroll')
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <DrawerDefault
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
          paperAnchorDockedLeft: classes.borderLeft,
        }}
      >
        <div className={classes.header}>
          {open && (
            <div className={classes.logo}>
              {theme.palette.type === 'light' ? (
                <img src={config.pictures.logoLight} alt="AZOT" />
              ) : (
                <img src={config.pictures.logoDark} alt="AZOT" />
              )}
            </div>
          )}
          <MenuButtonDrawer />
        </div>
        <div className={classes.body}>
          <List className={classes.nav}>
            {routes.map((route, index) => {
              return (
                route.menu && (
                  <RecursiveTreeView
                    key={route.name}
                    route={route}
                    indexNode={index}
                  />
                )
              )
            })}
          </List>
        </div>
        <div className={classes.footer}>{/* <SwitchTheme /> */}</div>
      </DrawerDefault>
    </ClickAwayListener>
  )
}
