import React from 'react'
import { makeStyles } from '@material-ui/core'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { Control, useController } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  box: {
    height: 80,
    width: '100%',
    marginBottom: 15,
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    borderRadius: 12,
    transition: 'all .2s',
  },
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,

    '& + label': {
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 700,
      borderRadius: 12,
      transition: 'all .2s',
    },
    '& + label:hover': {
      backgroundColor: `${theme.palette.secondary.main}87`,
      color: 'white',
    },
    '& + label *': {
      pointerEvents: 'none',
    },
  },
  labelInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    border: '2px solid #0000',
    '& span': {
      marginLeft: 8,
    },
  },
  colors: {
    marginTop: 32,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderWidth: '1px 0 0',
    },
  },
  color: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginBottom: 15,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0',
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[100],
    },
  },
  prev: {
    flexShrink: 0,
    width: 48,
    height: 48,
    margin: '0 16px',
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
}))

interface ICustomFileInputProps {
  name: string
  control: Control<any, object>
  clearOnFocus: () => void
  defaultValue?: string | null
  deleteCurrentImage: (() => Promise<void>) | null
  text?: string
}

const CustomFileInput = ({
  control,
  name,
  clearOnFocus,
  defaultValue,
  deleteCurrentImage,
  text = 'Choisir une image',
}: ICustomFileInputProps) => {
  const classes = useStyles()
  const [value, setValue] = React.useState('')
  const [fileName, setFileName] = React.useState<string | null>(null)
  const { field } = useController({ control, name })

  const handleChange = (e: any) => {
    let newFileName
    if (e.target.files.length > 0) {
      newFileName = e.target.value.split('\\').pop()
      setValue(e.target.value)
      field.onChange(e.target.files)
    }
    if (newFileName) {
      if (newFileName.length > 15) {
        const arrayString = newFileName.split('.')
        const newString = `${arrayString[0].substring(0, 14)}...${
          arrayString[arrayString.length - 1]
        }`
        setFileName(newString)
      } else {
        setFileName(newFileName)
      }
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className={classes.box}>
        <input
          type="file"
          id="logo_file"
          accept="image/*"
          className={classes.inputFile}
          value={value}
          onChange={handleChange}
          onFocus={clearOnFocus}
        />
        <label htmlFor="logo_file" className={classes.labelInput}>
          <CloudUploadOutlinedIcon />
          <span>{fileName || text}</span>
        </label>
      </div>
      {defaultValue && (
        <div className={classes.color}>
          <div
            className={classes.prev}
            style={{
              backgroundImage: `url(${defaultValue})`,
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                background: 'rgba(0,0,0,0.5)',
                width: '100%',
                height: '100%',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {deleteCurrentImage && (
                <CloseIcon
                  style={{ fill: 'white', height: '2rem', width: '2rem' }}
                  onClick={() => deleteCurrentImage()}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomFileInput
