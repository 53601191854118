import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import NavigationIcon from '@material-ui/icons/Navigation'

import Button from './Button'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    padding: 32,
    marginBottom: 64,
    borderRadius: 24,
    background: theme.palette.background.paper,

    '&::before,&::after': {
      content: "''",
      position: 'absolute',
      borderRadius: 24,
    },
    '&::before': {
      top: 8,
      left: 8,
      right: 8,
      bottom: -8,
      zIndex: -1,
      background:
        theme.palette.type === 'light'
          ? 'rgba(255, 255, 255, 0.5)'
          : 'rgba(36,39,49,0.5)',
    },
    '&::after': {
      display: theme.palette.type === 'light' ? 'inline-block' : 'none',
      top: 32,
      left: 32,
      right: 32,
      bottom: -32,
      zIndex: -2,
      background: theme.palette.grey[200],
      filter: 'blur(86.985px)',
    },

    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 40,
      padding: 24,
    },
  },
  btnNext: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 3,
    width: 24,
    height: '24px !important',
    borderRadius: '50%',
    background: theme.palette.background.paper,
    boxShadow: '0 4px 8px rgb(0 0 0 / 15%)',
    padding: '0 !important',
    minWidth: 'inherit',
    border:
      theme.palette.type === 'dark'
        ? `1px solid ${theme.palette.grey[100]}`
        : 'none',
  },
  btnIcon: {
    color: theme.palette.grey[400],
    transform: 'rotate(30deg)',
    fontSize: 14,
  },

  chart: {
    height: 220,
    marginTop: -15,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },

  inner: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.33,
    color: theme.palette.text.hint,
  },
  price: {
    marginBottom: 32,
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.success.dark,
    [theme.breakpoints.down('md')]: {
      marginBottom: 24,
    },
  },
  label: {
    display: 'inline-block',
    marginBottom: 3,
    padding: '0 11px',
    borderRadius: 13,
    background: theme.palette.warning.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '26px',
    color: theme.palette.text.hint,
  },
  number: {
    fontSize: 32,
    lineHeight: 1.25,
    letterSpacing: -0.5,
    fontWeight: 500,
    color: theme.palette.text.hint,
  },
}))

export interface ICardWidgetProps {
  chart?: ReactNode
  title?: string
  label?: string | number
  number?: string | number
  price?: string | number
  btnNext?: () => void
  cta?: () => void
  ctaLabel?: string
  children?: ReactNode
}

function CardWidget({
  chart,
  title,
  label,
  number,
  price,
  btnNext,
  cta,
  ctaLabel,
  children,
}: ICardWidgetProps) {
  const classes = useStyles()

  if (children) {
    return (
      <div className={classes.root} data-cy="cardWidget">
        {children}
      </div>
    )
  }

  return (
    <div className={classes.root} data-cy="cardWidget">
      {btnNext && (
        <Button
          styles={classes.btnNext}
          onClick={btnNext}
          data="btnNext_widget"
        >
          <NavigationIcon classes={{ root: classes.btnIcon }} />
        </Button>
      )}
      {chart && (
        <div className={classes.chart}>
          <div id="chart_total-balance" style={{ minHeight: 220 }}>
            {chart}
          </div>
        </div>
      )}
      <div className={classes.inner}>
        {title && <div className={classes.title}>{title}</div>}
        {label && <div className={classes.label}>{label}</div>}
        {number && <div className={classes.number}>{number}</div>}
        {price && <div className={classes.price}>{price}</div>}
        {cta && ctaLabel && (
          <Button primary onClick={cta} data="cta_widget">
            {ctaLabel}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CardWidget
