import { Campaign } from '@airflex/lib-models'
import { getWheelsByCampaignId } from '@hooks/queries/useWheel'
import React from 'react'
import WheelStats from './WheelStats'

interface CampaignStatsInterface {
  campaign: Campaign
}

const CampaignStats = ({ campaign }: CampaignStatsInterface) => {
  const { data: wheel } = getWheelsByCampaignId(campaign._id || '')

  return (
    <div key={wheel?.data.id}>
      {wheel?.data && <WheelStats wheel={wheel.data} campaign={campaign} />}
    </div>
  )
}

export default CampaignStats
