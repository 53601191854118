import axios from 'axios'

// axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

declare module 'axios' {
  interface AxiosRequestConfig {
    urlParams?: Record<string, string>
  }
}

axios.interceptors.request.use((config) => {
  if (!config.url) {
    return config
  }

  const currentUrl = new URL(config.url, config.baseURL)
  // parse pathName to implement variables
  Object.entries(config.urlParams || {}).forEach(([k, v]) => {
    currentUrl.pathname = currentUrl.pathname.replace(
      `:${k}`,
      encodeURIComponent(v)
    )
  })

  return {
    ...config,
    url: currentUrl.pathname,
  }
})
