import { BrandGraphicChart } from '@airflex/lib-models'
import axios from 'axios'
import { QueryKey } from 'react-query'

const urls = {
  createBrandGraphicChart: '/brand-graphic-chart',
  getBrandGraphicCharts: '/brand-graphic-chart',
  getBrandGraphicChart: '/brand-graphic-chart/:brandId',
  updateBrandGraphicChart: '/brand-graphic-chart',
  updateBrandGraphicBgImage: '/brand-graphic-chart/:brandId/update-bgimagebody',
  deleteBrandGraphicBgImage: '/brand-graphic-chart/:brandId/delete-bgimagebody',
  deleteBrandGraphicChart: '/brand-graphic-chart',
}

export interface UpdateBrandGraphicBgImageVariables {
  formData: FormData
  id: string
}

export interface DeleteBrandGraphicBgImageVariables {
  id: string
}

type BrandGraphicChartTypes = {
  brand: string | undefined
  bgColorBody: string
  textColorBody: string
  btnPrimaryColor: string
  btnPrimaryTextColor: string
  btnSecondaryColor: string
  btnSecondaryTextColor: string
}

type DeleteBrandGraphicChartTypes = {
  id: string | undefined
  brand: string | undefined
}

const createBrandGraphicChart = (
  createBrandGraphicChartBody: BrandGraphicChartTypes
) => axios.post(urls.createBrandGraphicChart, createBrandGraphicChartBody)

const updateBrandGraphicChart = (
  updateBrandGraphicChartBody: BrandGraphicChartTypes
) => axios.put(urls.updateBrandGraphicChart, updateBrandGraphicChartBody)

const updateBrandGraphicBgImage = ({
  formData,
  id,
}: UpdateBrandGraphicBgImageVariables) =>
  axios.patch(urls.updateBrandGraphicBgImage, formData, {
    urlParams: { brandId: id },
  })

const deleteBrandGraphicBgImage = ({
  id,
}: DeleteBrandGraphicBgImageVariables) =>
  axios.patch(urls.deleteBrandGraphicBgImage, null, {
    urlParams: { brandId: id },
  })

const getBrandGraphicCharts = () => axios.get(urls.getBrandGraphicCharts)

const getBrandGraphicChart = (
  id: string | string[] | undefined
): Promise<BrandGraphicChart> => {
  if (typeof id === 'string') {
    return axios
      .get(urls.getBrandGraphicChart, { urlParams: { brandId: id } })
      .then((res) => res.data)
  }
  throw new Error('invalid brandId')
}

const deleteBrandGraphicChart = (
  deleteBrandChart: DeleteBrandGraphicChartTypes
) =>
  axios.delete(urls.deleteBrandGraphicChart, {
    data: { ...deleteBrandChart },
  })

const brandGraphicChartService = {
  createBrandGraphicChart,
  getBrandGraphicCharts,
  getBrandGraphicChart,
  updateBrandGraphicChart,
  updateBrandGraphicBgImage,
  deleteBrandGraphicBgImage,
  deleteBrandGraphicChart,
}

export default brandGraphicChartService
