import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import {
  TextField,
  Slider,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
} from '@material-ui/core'
import ColorPicker from 'material-ui-color-picker'
import {
  TIME_MESURE,
  Campaign,
  OFFER_STATUS,
  WheelOffer,
} from '@airflex/lib-models'
import { nanoid } from 'nanoid'

import { useFormWheel } from '../../../hooks/useFormWheel'

interface IChooseOfferWinProps {
  selectedCampaign: Campaign | null
  onSubmit: (data: any) => Promise<void>
  positionAngle: number
  defaultValue?: WheelOffer
  index: number
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiInputLabel-formControl[data-shrink=true]': {
      transform: 'translate(0, 4px) scale(1)',
    },
  },
  labelControl: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
    left: 22,
    transform: 'translate(0, 40px) scale(1)',
  },
  field: {
    position: 'relative',
    marginBottom: 15,
  },
  label: {
    position: 'absolute',
    top: 32,
    right: 24,
    left: 24,
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  labelSlider: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInputControl: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  fieldInput: {
    width: '100%',
    height: 80,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  fieldInputDelay: {
    width: '100%',
    height: 40,
    padding: '15px 22px 0 22px',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  focusError: {
    borderColor: theme.palette.error.main,
    '& .MuiInputBase-input:focus': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  // Color Picker
  inputColorPicker: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      width: '100%',
      height: 80,
      padding: '18px 22px 0',
      borderRadius: 12,
      border: '2px solid #0000',
      '&:focus': {
        borderColor: theme.palette.primary.light,
      },
      background:
        theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.hint,
      transition: 'all .2s',
      outline: 'none',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after':
      {
        border: 'none',
      },
  },

  checkbox: {
    background: 'rgba(228, 228, 228, 0.3)',
    padding: '16px',
    marginBottom: '16px',
    borderRadius: '8px',
    border: '1px solid rgba(228, 228, 228, 1)',
  },
}))

const AzotSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider)

const ChooseOfferWin = ({
  selectedCampaign,
  onSubmit,
  positionAngle,
  defaultValue,
  index: indexStep,
}: IChooseOfferWinProps) => {
  const [bgColor, setBgColor] = useState<string>()
  const [labelColor, setLabelColor] = useState<string>()
  const [useCode, setUseCode] = useState<boolean | undefined>(false)
  const [useMaxWinner, setUseMaxWinner] = useState<boolean | undefined>(false)
  const [useCouldBeUsedAfter, setUseCouldBeUsedAfter] = useState<boolean>(false)
  const [useActivatedAt, setUseActivatedAt] = useState<boolean>(false)
  const day = new Date().toISOString().split('T')[0]
  const nanoID = nanoid(8)

  const classes = useStyles()

  const { offers } = useFormWheel()

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<WheelOffer>({
    defaultValues: {
      status: OFFER_STATUS.WIN,
      code: defaultValue?.code || nanoID,
      percentage: defaultValue?.percentage || 0,
      positionAngle,
    },
  })

  const watchUseCode = watch('useCode')
  const watchUseMaxWinner = watch('useMaxWinner')
  const watchUseCouldBeUsedAfter = watch('useCouldBeUsedAfter')
  const watchUseActivatedAt = watch('useActivatedAt')

  useEffect(() => {
    if (defaultValue?.useCode) {
      setUseCode(defaultValue.useCode)
    }
    if (defaultValue?.useMaxWinner) {
      setUseMaxWinner(defaultValue.useMaxWinner)
    }
    if (defaultValue?.useCouldBeUsedAfter) {
      setUseCouldBeUsedAfter(defaultValue.useCouldBeUsedAfter)
    }
    if (defaultValue?.useActivatedAt) {
      setUseActivatedAt(defaultValue.useActivatedAt)
    }
  }, [defaultValue])

  useEffect(() => {
    if (!watchUseCode) {
      setValue('code', defaultValue?.code || nanoID, {
        shouldValidate: false,
      })
      clearErrors('code')
    }
    setUseCode(watchUseCode)
  }, [watchUseCode])
  useEffect(() => {
    if (!watchUseMaxWinner) {
      setValue('maxWinner', defaultValue?.maxWinner || 1, {
        shouldValidate: false,
      })
      clearErrors('maxWinner')
    }
    setUseMaxWinner(watchUseMaxWinner)
  }, [watchUseMaxWinner])
  useEffect(() => {
    if (!watchUseCouldBeUsedAfter) {
      setValue('couldBeUsedAfter', defaultValue?.couldBeUsedAfter || 1, {
        shouldValidate: false,
      })
      clearErrors('couldBeUsedAfter')
    }
    setUseCouldBeUsedAfter(watchUseCouldBeUsedAfter!)
  }, [watchUseCouldBeUsedAfter])
  useEffect(() => {
    if (!watchUseActivatedAt) {
      setValue(
        'activatedAt',
        defaultValue?.activatedAt
          ? new Date(defaultValue?.activatedAt).toISOString().split('T')[0]
          : selectedCampaign &&
              new Date(selectedCampaign?.startDate).toISOString().split('T')[0],
        {
          shouldValidate: false,
        }
      )
      clearErrors('activatedAt')
    }

    setUseActivatedAt(watchUseActivatedAt!)
  }, [watchUseActivatedAt])

  const moreThanDayStart = (startDate: any) => {
    if (startDate >= day) {
      return true
    }

    return "Date de début doit être supérieure à aujourd'hui"
  }

  const maxEndDate = (activatedAt: Date | string | null | undefined) => {
    if (!useActivatedAt) {
      return true
    }
    if (selectedCampaign && selectedCampaign?.endDate && activatedAt) {
      if (activatedAt <= selectedCampaign.endDate) {
        return true
      }
    }

    return "Date d'activation doit être antérieure à la fin de la campagne"
  }

  const lessThan100 = (percent: number) => {
    const listOffers = [...offers]

    const totalPercent = listOffers
      .filter(
        (o, index) => o.status === OFFER_STATUS.WIN && index !== indexStep
      )
      .reduce((acc, curr) => acc + curr.percentage, 0)

    if (totalPercent + Number(percent) <= 100) {
      return true
    }

    return `Le total des pourcentages des quartiers ne doit pas dépasser 100%, actuellement : ${totalPercent}%`
  }

  const moreThanZeroPercentage = (percent: number) => {
    if (percent > 0) {
      return true
    }

    return 'Le pourcentage doit être suppérieur à zero, sinon choisir un status LOSE'
  }

  const dateIsValid = (dateValue: any) => {
    const exDate = new Date(dateValue)
    if (Object.prototype.toString.call(exDate) === '[object Date]') {
      if (Number.isNaN(exDate.getTime())) {
        return "La date d'expiration n'est pas valide"
      }
      return true
    }

    return false
  }

  const rules = {
    label: register('label', {
      required: { value: true, message: 'Choisir un texte' },
    }),
    percentage: register('percentage', {
      required: { value: true, message: 'Choisir un pourcentage' },
      validate: {
        lessThan100,
        moreThanZeroPercentage,
      },
    }),
    expirationDate: register('expirationDate', {
      required: { value: true, message: "Choisir un date d'expiration" },
      validate: {
        moreThanDayStart,
        dateIsValid,
      },
    }),
    couldBeUsedAfter: register('couldBeUsedAfter', {
      min: {
        value: 1,
        message: 'La durée avant activation du coupon doit être supérieur à 0',
      },
    }),
    couldBeUsedAfterMesure: register('couldBeUsedAfterMesure'),
    useCouldBeUsedAfter: register('useCouldBeUsedAfter'),
    bgColor: register('bgColor', {
      required: { value: true, message: 'Choisir la couleur de fond' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    labelColor: register('labelColor', {
      required: { value: true, message: 'Choisir la couleur du texte' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    code: register('code', {
      required: { value: true, message: 'Choisir un code restaurant' },
      maxLength: {
        value: 8,
        message: 'Code maximum 8 caractères',
      },
    }),
    useCode: register('useCode'),
    useMaxWinner: register('useMaxWinner'),
    maxWinner: register('maxWinner', {
      min: {
        value: 1,
        message: 'Le nombre de gagnant maximum doit être supérieur à 0',
      },
    }),
    useActivatedAt: register('useActivatedAt'),
    activatedAt: register('activatedAt', {
      validate: {
        maxEndDate,
      },
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          couleur du texte
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.labelColor && classes.focusError
            ),
          }}
          name={rules.labelColor.name}
          inputRef={rules.labelColor.ref}
          value={labelColor}
          defaultValue={defaultValue?.labelColor || '#000000'}
          onChange={(color) => {
            setLabelColor(color)
            setValue('labelColor', color)
          }}
          onBlur={() => clearErrors('labelColor')}
        />
      </div>

      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          couleur du fond
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.bgColor && classes.focusError
            ),
          }}
          name={rules.bgColor.name}
          inputRef={rules.bgColor.ref}
          value={bgColor}
          defaultValue={defaultValue?.bgColor || '#FFFFFF'}
          onChange={(color) => {
            setBgColor(color)
            setValue('bgColor', color)
          }}
          onBlur={() => clearErrors('bgColor')}
        />
      </div>

      <div className={classes.checkbox}>
        <div className={classes.field} style={{ marginTop: 16 }}>
          <div className={classes.label} style={{ top: 0 }}>
            Mettre un délai de validation du coupon ?
          </div>
          <input
            type="checkbox"
            defaultChecked={defaultValue?.useCouldBeUsedAfter}
            style={{ margin: '0 16px 0 0' }}
            {...rules.useCouldBeUsedAfter}
          />
        </div>

        {useCouldBeUsedAfter && (
          <div className={classes.field}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div
                  className={classes.label}
                  style={{ transform: 'translateY(-12px)' }}
                >
                  Le coupon sera valide après :
                </div>
                <TextField
                  classes={{
                    root: classes.formControl,
                  }}
                  inputProps={{
                    className: classes.fieldInputDelay,
                    min: 1,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                  }}
                  label="Délais"
                  type="number"
                  defaultValue={defaultValue?.couldBeUsedAfter || 0}
                  {...rules.couldBeUsedAfter}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="select-type-action" className={classes.label}>
                    Temps
                  </InputLabel>
                  <Select
                    classes={{
                      root: classes.fieldInputDelay,
                    }}
                    labelId="select-type-action"
                    id="select-type-action"
                    defaultValue={
                      defaultValue?.couldBeUsedAfterMesure || 'HOUR'
                    }
                    {...rules.couldBeUsedAfterMesure}
                  >
                    <MenuItem key="time-select-0" value={TIME_MESURE.MINUTE}>
                      Minutes
                    </MenuItem>
                    <MenuItem key="time-select-1" value={TIME_MESURE.HOUR}>
                      Heures
                    </MenuItem>
                    <MenuItem key="time-select-2" value={TIME_MESURE.DAY}>
                      Jours
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInputControl,
        }}
        InputLabelProps={{
          className: classes.labelControl,
        }}
        id="date"
        label="Date d'expiration"
        type="date"
        defaultValue={
          defaultValue?.expirationDate
            ? new Date(defaultValue?.expirationDate).toISOString().split('T')[0]
            : selectedCampaign &&
              new Date(selectedCampaign.endDate).toISOString().split('T')[0]
        }
        {...rules.expirationDate}
      />

      <div className={classes.checkbox}>
        <div className={classes.field} style={{ marginTop: 16 }}>
          <div className={classes.label} style={{ top: 0 }}>
            Utiliser un code de validation ?
          </div>
          <input
            type="checkbox"
            defaultChecked={defaultValue?.useCode}
            style={{ margin: '0 16px 0 0' }}
            {...rules.useCode}
          />
        </div>

        {useCode && (
          <TextField
            classes={{
              root: classes.formControl,
            }}
            inputProps={{
              className: classes.fieldInputControl,
            }}
            InputLabelProps={{
              className: classes.labelControl,
            }}
            label="Code restaurant"
            type="text"
            defaultValue={defaultValue?.code}
            {...rules.code}
          />
        )}
      </div>

      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInputControl,
        }}
        InputLabelProps={{
          className: classes.labelControl,
        }}
        label="Texte du quartier"
        defaultValue={defaultValue?.label || 'Gagné '}
        type="text"
        {...rules.label}
      />

      <div className={classes.field}>
        <TextField
          classes={{
            root: classes.formControl,
          }}
          inputProps={{
            className: classes.fieldInputControl,
            step: 0.01,
            min: 0.01,
          }}
          InputLabelProps={{
            className: classes.labelControl,
          }}
          label="Pourcentage de gain"
          defaultValue={defaultValue?.percentage || 0.01}
          type="number"
          {...rules.percentage}
        />
      </div>

      <div className={classes.checkbox}>
        <div className={classes.field} style={{ marginTop: 16 }}>
          <div className={classes.label} style={{ top: 0 }}>
            Mettre une limite de gagnant ?
          </div>
          <input
            type="checkbox"
            defaultChecked={defaultValue?.useMaxWinner}
            style={{ margin: '0 16px 0 0' }}
            {...rules.useMaxWinner}
          />
        </div>

        {useMaxWinner && (
          <TextField
            classes={{
              root: classes.formControl,
            }}
            inputProps={{
              className: classes.fieldInputControl,
              min: 1,
            }}
            InputLabelProps={{
              className: classes.labelControl,
            }}
            label="Nombre de gagnant maximum"
            type="number"
            defaultValue={defaultValue?.maxWinner || 1}
            {...rules.maxWinner}
          />
        )}
      </div>

      <div className={classes.checkbox}>
        <div className={classes.field} style={{ marginTop: 16 }}>
          <div className={classes.label} style={{ top: 0 }}>
            Choisir une date d&apos;activation du lot
          </div>
          <input
            type="checkbox"
            defaultChecked={defaultValue?.useActivatedAt}
            style={{ margin: '0 16px 0 0' }}
            {...rules.useActivatedAt}
          />
        </div>

        {useActivatedAt && (
          <TextField
            classes={{
              root: classes.formControl,
            }}
            inputProps={{
              className: classes.fieldInputControl,
            }}
            InputLabelProps={{
              className: classes.labelControl,
            }}
            id="date"
            label="Date d'activation"
            type="date"
            defaultValue={
              defaultValue?.activatedAt
                ? new Date(defaultValue?.activatedAt)
                    .toISOString()
                    .split('T')[0]
                : selectedCampaign &&
                  new Date(selectedCampaign?.startDate)
                    .toISOString()
                    .split('T')[0]
            }
            {...rules.activatedAt}
          />
        )}
      </div>

      <button
        type="submit"
        className={clsx(classes.btn, classes.btnBlue)}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        Suivant
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`offer_win-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseOfferWin
