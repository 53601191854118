import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import RichText from '../../RichText'
import CustomFileInput from '../../CustomFileInput'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

interface IChooseLoginPageProps {
  onSubmit: (data: any) => Promise<void>
}

export type InputsLoginPage = {
  title: string
  description: string
  image: string
}

const ChooseLoginPage = ({ onSubmit }: IChooseLoginPageProps) => {
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<InputsLoginPage>()

  const rules = {
    title: register('title', {
      required: { value: true, message: 'Choisir un titre' },
    }),
    description: register('description', {
      required: { value: true, message: 'Choisir une description' },
    }),
    image: register('image'),
  }

  const handleClearErrors = () => {
    clearErrors('title')
    clearErrors('description')
    clearErrors('image')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        label="Titre page login"
        type="text"
        {...rules.title}
      />
      <div className={classes.label}>Description</div>
      <RichText control={control} name={rules.description.name} />
      <CustomFileInput
        clearOnFocus={handleClearErrors}
        control={control}
        name="image"
        deleteCurrentImage={null}
      />
      <button
        type="submit"
        className={classes.btn}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        suivant
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`login_page-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseLoginPage
