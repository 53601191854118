import {
  Brand,
  BrandGraphicChart,
  BrandPrivacyPolicy,
  BrandSettlement,
} from '@airflex/lib-models'
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from 'react'

export enum BRAND_FORM_TYPE {
  BRAND = 'BRAND',
  GRAPHIC = 'GRAPHIC',
  GRAPHIC_EDIT = 'GRAPHIC_EDIT',
  SETTLEMENT = 'SETTLEMENT',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
}

export type FormBrandContextTypes = {
  open: boolean
  type: BRAND_FORM_TYPE
  brandId: string | undefined
  toggleEdit: boolean
  brandData: Brand | undefined
  graphicData: BrandGraphicChart | undefined
  settlement: BrandSettlement | undefined
  privacyPolicy: BrandPrivacyPolicy | undefined
  setBrandData: Dispatch<Brand | undefined>
  setGraphicData: Dispatch<BrandGraphicChart | undefined>
  setBrandId: Dispatch<string | undefined>
  setToggleEdit: Dispatch<boolean>
  setOpen: Dispatch<boolean>
  setType: Dispatch<BRAND_FORM_TYPE>
  setSettlement: Dispatch<BrandSettlement | undefined>
  setPrivacyPolicy: Dispatch<BrandPrivacyPolicy | undefined>
}

const FormBrandContext = createContext<FormBrandContextTypes>(
  {} as FormBrandContextTypes
)

export interface IFormBrandProviderProps {
  children: ReactNode
}

export default function FormBrandProvider({
  children,
}: IFormBrandProviderProps) {
  const [open, setOpen] = useState(false)
  const [brandId, setBrandId] = useState<string | undefined>('')
  const [toggleEdit, setToggleEdit] = useState<boolean>(false)
  const [brandData, setBrandData] = useState<Brand | undefined>()
  const [graphicData, setGraphicData] = useState<
    BrandGraphicChart | undefined
  >()
  const [type, setType] = useState(BRAND_FORM_TYPE.BRAND)
  const [settlement, setSettlement] = useState<BrandSettlement | undefined>()
  const [privacyPolicy, setPrivacyPolicy] = useState<
    BrandPrivacyPolicy | undefined
  >()

  const context: FormBrandContextTypes = {
    open,
    type,
    brandId,
    brandData,
    graphicData,
    toggleEdit,
    settlement,
    privacyPolicy,
    setToggleEdit,
    setBrandData,
    setGraphicData,
    setBrandId,
    setOpen,
    setType,
    setSettlement,
    setPrivacyPolicy,
  }

  return (
    <FormBrandContext.Provider value={context}>
      {children}
    </FormBrandContext.Provider>
  )
}

export const useFormBrand = () => useContext(FormBrandContext)
