import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import trFr from '../locales/fr'
import trEn from '../locales/en'

const supportedLanguages = ['en', 'fr']

export const lngs: { [key: string]: { nativeName: string } } = {
  en: { nativeName: 'English' },
  fr: { nativeName: 'Français' },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: supportedLanguages,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: trEn,
      fr: trFr,
    },
  })

export default i18n
