import { Brand } from '@airflex/lib-models'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import brandsService from '../../services/brands.service'

const useBrandMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: createBrandName, isLoading: isLoadingCreateBrandName } =
    useMutation('createBrandName', brandsService.createBrand)

  const { mutateAsync: updateBrandLogo, isLoading: isLoadingUpdateBrandLogo } =
    useMutation('updateBrandLogo', brandsService.updateBrandLogo, {
      onSuccess: () => queryClient.invalidateQueries('getBrands'),
    })

  const {
    mutateAsync: updateBrandStatus,
    isLoading: isLoadingUpdateBrandStatus,
  } = useMutation('updateBrandStatus', brandsService.updateBrandStatus, {
    onSuccess: () => queryClient.invalidateQueries('getBrands'),
  })

  const {
    mutateAsync: updateGraphicChartStatus,
    isLoading: isLoadingUpdateGraphicChartStatus,
  } = useMutation(
    'updateGraphicChartStatus',
    brandsService.updateGraphicChartStatus,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrands'),
    }
  )

  const {
    mutateAsync: updateSettlementStatus,
    isLoading: isLoadingUpdateSettlementStatus,
  } = useMutation(
    'updateSettlementStatus',
    brandsService.updateSettlementStatus,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrands'),
    }
  )

  const {
    mutateAsync: updatePrivacyPolicyStatus,
    isLoading: isLoadingUpdatePrivacyPolicyStatus,
  } = useMutation(
    'updatePrivacyPolicyStatus',
    brandsService.updatePrivacyPolicyStatus,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrands'),
    }
  )

  const { mutateAsync: deleteBrand, isLoading: isLoadingDeleteBrand } =
    useMutation('deleteBrand', brandsService.deleteBrand, {
      onSuccess: () => queryClient.invalidateQueries('getBrands'),
    })

  const isLoading =
    isLoadingCreateBrandName ||
    isLoadingUpdateBrandLogo ||
    isLoadingDeleteBrand ||
    isLoadingUpdateBrandStatus ||
    isLoadingUpdateGraphicChartStatus ||
    isLoadingUpdateSettlementStatus ||
    isLoadingUpdatePrivacyPolicyStatus

  return {
    createBrandName,
    updateBrandLogo,
    updateBrandStatus,
    updateGraphicChartStatus,
    updateSettlementStatus,
    updatePrivacyPolicyStatus,
    deleteBrand,
    isLoading,
  }
}

export const getBrands = () => useQuery('getBrands', brandsService.getBrands)
export const getBrandById = (id: string) =>
  useQuery<Brand, Error>(
    ['getBrandById', id],
    () => brandsService.getBrandById(id),
    {
      enabled: false,
    }
  )

export const getBrandsStatus = () =>
  useQuery('getBrandsStatus', brandsService.getBrands, {
    select: (res) => {
      return res.data.filter((item: any) => item.status)
    },
  })

export default useBrandMutation
