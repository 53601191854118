import { Brand, Campaign, Wheel } from '@airflex/lib-models'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import wheelService from '../../services/wheel.service'

const useWheelMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: createWheel, isLoading: isLoadingCreateWheel } =
    useMutation('createWheel', wheelService.createWheel, {
      onSuccess: () => queryClient.invalidateQueries('getWheels'),
    })

  const {
    mutateAsync: updateWheelStatus,
    isLoading: isLoadingUpdateWheelStatus,
  } = useMutation('updateWheelStatus', wheelService.updateWheelStatus, {
    onSuccess: () => queryClient.invalidateQueries('getWheels'),
  })

  const {
    mutateAsync: updateWheelOffers,
    isLoading: isLoadingUpdateWheelOffers,
  } = useMutation('updateWheelOffers', wheelService.updateWheelOffers, {
    onSuccess: () => queryClient.invalidateQueries('getWheels'),
  })

  const { mutateAsync: softDeleteWheel, isLoading: isLoadingSoftDeleteWheel } =
    useMutation('softDeleteWheel', wheelService.softDeleteWheel, {
      onSuccess: () => queryClient.invalidateQueries('getWheels'),
    })

  const {
    mutateAsync: deleteWheel,
    isLoading: isLoadingDeleteWheeldeleteWheel,
  } = useMutation('deleteWheel', wheelService.deleteWheel, {
    onSuccess: () => queryClient.invalidateQueries('getWheels'),
  })

  const arrLoading = [
    isLoadingCreateWheel,
    isLoadingUpdateWheelStatus,
    isLoadingSoftDeleteWheel,
    isLoadingDeleteWheeldeleteWheel,
  ]

  const isLoading = arrLoading.some((value) => value)

  return {
    createWheel,
    updateWheelStatus,
    updateWheelOffers,
    softDeleteWheel,
    deleteWheel,
    isLoading,
  }
}

export const getWheels = () => useQuery('getWheels', wheelService.getWheels)
export const getWheelsStatus = () =>
  useQuery('getWheelsStatus', wheelService.getWheels, {
    select: (res) => {
      return res.data.filter((item: any) => item.status)
    },
  })
export const getWheelsByCampaignId = (campaignId: string) =>
  useQuery(['getWheelsByCampaignId', campaignId], () =>
    wheelService.getWheelByCampaignId(campaignId)
  )

export default useWheelMutation
