import { BrandGraphicChart } from '@airflex/lib-models'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import brandGraphicChartService from '../../services/brand-graphic-chart.service'

export const getBrandGraphicCharts = () =>
  useQuery(
    'getBrandGraphicCharts',
    brandGraphicChartService.getBrandGraphicCharts
  )

export const getBrandGraphicChart = (brandId: string) =>
  useQuery<BrandGraphicChart, Error>(
    ['getBrandGraphicChart', brandId],
    () => brandGraphicChartService.getBrandGraphicChart(brandId),
    {
      enabled: false,
    }
  )

const useBrandGraphicMutation = () => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: createBrandGraphic,
    isLoading: isLoadingCreateBrandGraphic,
  } = useMutation(
    'createBrandGraphic',
    brandGraphicChartService.createBrandGraphicChart,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrandGraphicChart'),
    }
  )

  const {
    mutateAsync: updateBrandGraphic,
    isLoading: isLoadingUpdateBrandGraphic,
  } = useMutation(
    'updateBrandGraphic',
    brandGraphicChartService.updateBrandGraphicChart
  )

  const {
    mutateAsync: updateBrandGraphicBgImage,
    isLoading: isLoadingUpdateBrandGraphicBgImage,
  } = useMutation(
    'updateBrandGraphicBgImage',
    brandGraphicChartService.updateBrandGraphicBgImage,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrandGraphicChart'),
    }
  )

  const {
    mutateAsync: deleteBrandGraphicChartBgImage,
    isLoading: isLoadingDeleteBrandGraphicBgImage,
  } = useMutation(
    'deleteBrandGraphicBgImage',
    brandGraphicChartService.deleteBrandGraphicBgImage,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrandGraphicChart'),
    }
  )

  const {
    mutateAsync: deleteBrandGraphic,
    isLoading: isLoadingDeleteBrandGraphic,
  } = useMutation(
    'deleteBrandLogo',
    brandGraphicChartService.deleteBrandGraphicChart,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrandGraphicChart'),
    }
  )

  const isLoading =
    isLoadingCreateBrandGraphic ||
    isLoadingUpdateBrandGraphic ||
    isLoadingDeleteBrandGraphic ||
    isLoadingUpdateBrandGraphicBgImage ||
    isLoadingDeleteBrandGraphicBgImage

  return {
    createBrandGraphic,
    updateBrandGraphic,
    updateBrandGraphicBgImage,
    deleteBrandGraphicChartBgImage,
    deleteBrandGraphic,
    isLoading,
  }
}

export default useBrandGraphicMutation
