import React, { forwardRef, useImperativeHandle } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import StepperDefault from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiStepper-root': {
        padding: '24px 0',
        background: 'none',
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)

interface IStepperProps {
  steps: string[]
}

const Stepper = forwardRef(({ steps }: IStepperProps, ref) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState(new Set<number>())

  const completedSteps = () => {
    return completed.size
  }

  const allStepsCompleted = () => {
    return completedSteps() === steps.length
  }

  const isLastStep = () => {
    return activeStep === steps.length - 1
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1

    setActiveStep(newActiveStep)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted(new Set<number>())
  }

  const handleComplete = () => {
    const newCompleted = new Set(completed)
    newCompleted.add(activeStep)
    setCompleted(newCompleted)

    if (completed.size !== steps.length) {
      handleNext()
    }
  }

  function isStepComplete(step: number) {
    return completed.has(step)
  }

  useImperativeHandle(ref, () => ({
    handleComplete() {
      handleComplete()
    },
    activeStep() {
      // if (activeStep !== steps.length - 1) {
      //   return activeStep + 1
      // }
      return activeStep
    },
    handleReset() {
      handleReset()
    },
  }))

  return (
    <div className={classes.root}>
      <StepperDefault nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const buttonProps: { optional?: React.ReactNode } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
                disabled
              />
            </Step>
          )
        })}
      </StepperDefault>
    </div>
  )
})

export default Stepper
