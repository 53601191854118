import React from 'react'
import ReactDOM from 'react-dom'
import i18next from 'i18next'
import './res/api'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeContextProvider } from './res/themes/theme-context'

import './res/translate'

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./tests/mocks/browser')
//   worker.start()
// }

// change lang html
const lang = i18next.language
document.documentElement.lang = lang

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <App />
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
