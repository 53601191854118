/* eslint-disable prettier/prettier */
import dateFormat from 'dateformat'

dateFormat.i18n = {
  dayNames: [
    'Dim',
    'Lun',
    'Mar',
    'Mer',
    'Jeu',
    'Ven',
    'Sam',
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ],
  monthNames: [
    'Jan',
    'Fev',
    'Mar',
    'Avr',
    'Mai',
    'Jui',
    'Juil',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ],
  timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
}

const dateFormated = (date) => {
  const d = new Date(date)
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (Number.isNaN(d.getTime())) {
      return date
    }
    dateFormat.masks.formatedCard = 'dd mmmm, yyyy'
    return dateFormat(date, 'formatedCard')
  }
  return date
}

export const dateFormatedCustomer = (date) => {
  dateFormat.masks.formatedCard = 'dd mmmm, yyyy'
  return dateFormat(date, 'formatedCard')
}

export const dateInstance = (date) => {
  dateFormat.masks.formatedInstance = 'dd/mm'
  return dateFormat(date, 'formatedInstance')
}

export const dateFormatedTime = (newDate) => {
  const date = new Date(newDate);

  const day = date.getUTCDate()
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()

  const options = {
    timeZone: 'Europe/Paris',
    hour: '2-digit',
    minute: '2-digit',
  }
  const formatter = new Intl.DateTimeFormat('fr-FR', options)
  const time = formatter.format(date)

  const formattedDate = `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month
    }/${year} - ${time}`

  return formattedDate
}

export const getCurrentWeek = () => {
  const today = new Date();
  const firstDayOfWeek = new Date(today);
  const lastDayOfWeek = new Date(today);

  firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
  const formattedFirstDay = `${firstDayOfWeek.getUTCDate()}/${firstDayOfWeek.getUTCMonth() + 1}/${firstDayOfWeek.getUTCFullYear()}`;

  lastDayOfWeek.setDate(today.getDate() - today.getDay() + 7);
  const formattedLastDay = `${lastDayOfWeek.getUTCDate()}/${lastDayOfWeek.getUTCMonth() + 1}/${lastDayOfWeek.getUTCFullYear()}`;

  const formattedWeek = `${formattedFirstDay} - ${formattedLastDay}`;
  return formattedWeek
}

export default dateFormated
