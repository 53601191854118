import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useForm, SubmitHandler } from 'react-hook-form'
import { CircularProgress } from '@material-ui/core'
import ColorPicker from 'material-ui-color-picker'

import CustomFileInput from '@components/CustomFileInput'
import useBrandGraphicMutation from '../../hooks/queries/useBrandGraphic'
import { useFormBrand } from '../../hooks/useFormBrand'
import useBrandMutation from '../../hooks/queries/useBrand'

export type CreateGraphicBrandStyleTypes = {
  active: boolean
}

export type Inputs = {
  bgColorBody: string
  bgImageBody: string
  textColorBody: string
  btnPrimaryColor: string
  btnPrimaryTextColor: string
  btnSecondaryColor: string
  btnSecondaryTextColor: string
}

type OnSubmitTypes = {
  bgColorBody: string
  bgImageBody: string
  textColorBody: string
  btnPrimaryColor: string
  btnPrimaryTextColor: string
  btnSecondaryColor: string
  btnSecondaryTextColor: string
}

interface ICreateGraphicProps {
  edit?: boolean
}

const useStyles = makeStyles((theme) => ({
  field: {
    position: 'relative',
    marginBottom: 15,
  },
  label: {
    position: 'absolute',
    top: 32,
    right: 24,
    left: 24,
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 80,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  focusError: {
    borderColor: theme.palette.error.main,
    '& .MuiInputBase-input:focus': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  // Color Picker
  inputColorPicker: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      width: '100%',
      height: 80,
      padding: '18px 22px 0',
      borderRadius: 12,
      border: '2px solid #0000',
      '&:focus': {
        borderColor: theme.palette.primary.light,
      },
      background:
        theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.hint,
      transition: 'all .2s',
      outline: 'none',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
}))

const CreateGraphicBrand = ({ edit }: ICreateGraphicProps) => {
  const [bgColor, setbgColor] = useState<string>()
  const [textColor, settextColor] = useState<string>()
  const [btnPrimaryColor, setbtnPrimaryColor] = useState<string>()
  const [btnPrimaryTextColor, setbtnPrimaryTextColor] = useState<string>()
  const [btnSecondaryColor, setbtnSecondaryColor] = useState<string>()
  const [btnSecondaryTextColor, setbtnSecondaryTextColor] = useState<string>()

  const classes = useStyles()
  const {
    isLoading,
    createBrandGraphic,
    updateBrandGraphic,
    updateBrandGraphicBgImage,
    deleteBrandGraphicChartBgImage,
    deleteBrandGraphic,
  } = useBrandGraphicMutation()

  const { updateBrandStatus, updateGraphicChartStatus } = useBrandMutation()

  const { brandId, setOpen, graphicData, setGraphicData, setToggleEdit } =
    useFormBrand()

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const rules = {
    bgColorBody: register('bgColorBody', {
      required: { value: true, message: 'Choisir une couleur de fond' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    bgImageBody: register('bgImageBody', {
      required: { value: false, message: 'Choisir une image' },
    }),
    textColorBody: register('textColorBody', {
      required: { value: true, message: 'Choisir une couleur de texte' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    btnPrimaryColor: register('btnPrimaryColor', {
      required: {
        value: true,
        message: 'Choisir une couleur boutton principal',
      },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    btnPrimaryTextColor: register('btnPrimaryTextColor', {
      required: {
        value: true,
        message: 'Choisir une couleur du texte boutton principal',
      },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    btnSecondaryColor: register('btnSecondaryColor', {
      required: {
        value: true,
        message: 'Choisir une couleur boutton secondaire',
      },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    btnSecondaryTextColor: register('btnSecondaryTextColor', {
      required: {
        value: true,
        message: 'Choisir une couleur du texte boutton secondaire',
      },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
  }

  const removeAll = () => {
    setOpen(false)
    setToggleEdit(false)
    setValue('bgColorBody', '')
    setValue('textColorBody', '')
    setValue('btnPrimaryColor', '')
    setValue('btnPrimaryTextColor', '')
    setValue('btnSecondaryColor', '')
    setValue('btnSecondaryTextColor', '')
  }

  const onSubmit: SubmitHandler<OnSubmitTypes> = async (data) => {
    if (edit) {
      const { data: updateResult } = await updateBrandGraphic({
        brand: brandId,
        ...data,
      })
      if (typeof brandId === 'string') {
        await updateGraphicChartStatus({
          id: brandId,
          graphicChartStatus: true,
        })
        await updateBrandStatus({
          id: brandId,
          status: true,
        })
      }
      if (updateResult) {
        removeAll()
        if (data.bgImageBody) {
          const formData = new FormData()
          formData.append('bgImageBody', data.bgImageBody[0])
          const { data: brandGraphicBgImage } = await updateBrandGraphicBgImage(
            { id: updateResult._id, formData }
          )
        }
      }
    } else {
      try {
        const { data: result } = await createBrandGraphic({
          brand: brandId,
          ...data,
        })
        if (result) {
          if (data.bgImageBody) {
            const formData = new FormData()
            formData.append('bgImageBody', data.bgImageBody[0])
            const { data: brandGraphicBgImage } =
              await updateBrandGraphicBgImage({ id: result._id, formData })
          }
          if (typeof brandId === 'string') {
            await updateGraphicChartStatus({
              id: brandId,
              graphicChartStatus: true,
            })
            await updateBrandStatus({
              id: brandId,
              status: true,
            })
          }
          setOpen(false)
          setValue('bgColorBody', '')
          setValue('bgImageBody', '')
          setValue('textColorBody', '')
          setValue('btnPrimaryColor', '')
          setValue('btnPrimaryTextColor', '')
          setValue('btnSecondaryColor', '')
          setValue('btnSecondaryTextColor', '')
        }
      } catch (e) {
        console.log(e)
        setError('textColorBody', {
          type: 'manual',
          message: 'Une erreur est survenue, veuillez recommencer',
        })
      }
    }
  }

  const handleClearErrors = () => {
    clearErrors('bgColorBody')
    clearErrors('bgImageBody')
    clearErrors('textColorBody')
    clearErrors('btnPrimaryColor')
    clearErrors('btnPrimaryTextColor')
    clearErrors('btnSecondaryColor')
    clearErrors('btnSecondaryTextColor')
  }

  const textButton = edit ? 'Enregister' : 'Créer'

  const handleDelete = async () => {
    try {
      if (typeof brandId === 'string') {
        await deleteBrandGraphic({
          id: graphicData?._id,
          brand: brandId,
        })
        await updateGraphicChartStatus({
          id: brandId,
          graphicChartStatus: false,
        })
        await updateBrandStatus({
          id: brandId,
          status: false,
        })
        removeAll()
      } else {
        setError('textColorBody', {
          type: 'manual',
          message: 'Une erreur est survenue, veuillez recommencer',
        })
      }
    } catch (e) {
      console.log(e)
      setError('textColorBody', {
        type: 'manual',
        message: 'Une erreur est survenue, veuillez recommencer',
      })
    }
  }

  const handleDeleteBgImage = async () => {
    if (graphicData && graphicData._id) {
      const { data: brandGraphicBgImage } =
        await deleteBrandGraphicChartBgImage({ id: graphicData._id })
      setGraphicData({ ...graphicData, bgImageBody: '' })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          fond
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.bgColorBody && classes.focusError
            ),
          }}
          name={rules.bgColorBody.name}
          inputRef={rules.bgColorBody.ref}
          defaultValue={edit ? graphicData?.bgColorBody : bgColor}
          value={bgColor}
          onChange={(color) => {
            setbgColor(color)
            setValue('bgColorBody', color)
          }}
          onBlur={() => {
            handleClearErrors()
          }}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          image de fond
        </div>
        <CustomFileInput
          name="bgImageBody"
          defaultValue={
            edit && graphicData?.bgImageBody ? graphicData?.bgImageBody : null
          }
          deleteCurrentImage={
            edit && graphicData?.bgImageBody ? handleDeleteBgImage : null
          }
          control={control}
          clearOnFocus={handleClearErrors}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          texte fond
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.textColorBody && classes.focusError
            ),
          }}
          name={rules.textColorBody.name}
          inputRef={rules.textColorBody.ref}
          defaultValue={edit ? graphicData?.textColorBody : textColor}
          value={textColor}
          onChange={(color) => {
            settextColor(color)
            setValue('textColorBody', color)
          }}
          onBlur={() => {
            handleClearErrors()
          }}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          bouton 1
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.btnPrimaryColor && classes.focusError
            ),
          }}
          name={rules.btnPrimaryColor.name}
          inputRef={rules.btnPrimaryColor.ref}
          defaultValue={edit ? graphicData?.btnPrimaryColor : btnPrimaryColor}
          value={btnPrimaryColor}
          onChange={(color) => {
            setbtnPrimaryColor(color)
            setValue('btnPrimaryColor', color)
          }}
          onBlur={() => {
            handleClearErrors()
          }}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          texte bouton 1
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.btnPrimaryTextColor && classes.focusError
            ),
          }}
          name={rules.btnPrimaryTextColor.name}
          inputRef={rules.btnPrimaryTextColor.ref}
          defaultValue={
            edit ? graphicData?.btnPrimaryTextColor : btnPrimaryTextColor
          }
          value={btnPrimaryTextColor}
          onChange={(color) => {
            setbtnPrimaryTextColor(color)
            setValue('btnPrimaryTextColor', color)
          }}
          onBlur={() => {
            handleClearErrors()
          }}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          bouton 2
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.btnSecondaryColor && classes.focusError
            ),
          }}
          name={rules.btnSecondaryColor.name}
          inputRef={rules.btnSecondaryColor.ref}
          defaultValue={
            edit ? graphicData?.btnSecondaryColor : btnSecondaryColor
          }
          value={btnSecondaryColor}
          onChange={(color) => {
            setbtnSecondaryColor(color)
            setValue('btnSecondaryColor', color)
          }}
          onBlur={() => {
            handleClearErrors()
          }}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          texte bouton 2
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.btnSecondaryTextColor && classes.focusError
            ),
          }}
          name={rules.btnSecondaryTextColor.name}
          inputRef={rules.btnSecondaryTextColor.ref}
          defaultValue={
            edit ? graphicData?.btnSecondaryTextColor : btnSecondaryTextColor
          }
          value={btnSecondaryTextColor}
          onChange={(color) => {
            setbtnSecondaryTextColor(color)
            setValue('btnSecondaryTextColor', color)
          }}
          onBlur={() => {
            handleClearErrors()
          }}
        />
      </div>
      <button
        type="submit"
        className={clsx(classes.btn, classes.btnBlue)}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        {isLoading ? (
          <CircularProgress
            size={20}
            style={{
              color: '#fff',
            }}
          />
        ) : (
          textButton
        )}
      </button>
      {edit && (
        <button
          type="button"
          className={clsx(classes.btn, classes.btnBorder)}
          onClick={handleDelete}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              style={{
                color: '#fff',
              }}
            />
          ) : (
            'Supprimer'
          )}
        </button>
      )}
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`graphic_chart-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

CreateGraphicBrand.defaultProps = {
  edit: false,
}

export default CreateGraphicBrand
