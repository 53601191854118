/* eslint-disable no-plusplus */
import { useTheme } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import useGetRoutes from './useGetRoutes'

const findRoute = (routes = [], pathToFind) => {
  return routes.find(({ path }) => path === pathToFind)
}

const display = (pathToFind, routes, defaultColor) => {
  const pathArray = pathToFind
    .split('/')
    .slice(1)
    .map((route) => `/${route}`)
  const depth = pathArray.length - 1
  let route = findRoute(routes, pathArray[0])
  const firstParent = route
  const color = firstParent?.color || defaultColor
  let parent = firstParent

  for (let i = 1; i < pathArray.length; i++) {
    const currentRoute = findRoute(route?.children, pathArray[i])
    route = currentRoute
    if (i === pathArray.length - 2) {
      parent = currentRoute
    }
  }

  return {
    route,
    parent,
    firstParent,
    depth,
    color,
  }
}

export default function useRouting() {
  const theme = useTheme()
  const location = useLocation()
  const routes = useGetRoutes()
  const defaultColor = theme.palette.primary.main

  return display(location.pathname, routes, defaultColor)
}
