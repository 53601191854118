import React, { ReactNode, ReactElement } from 'react'
import { makeStyles } from '@material-ui/core'
import { JsxElement } from 'typescript'

const useStyles = makeStyles((theme) => ({
  pageRow: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down(1419)]: {
      padding: '0 64px 64px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      padding: '0 64px 64px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px 32px',
    },
  },
  pageCol: {
    padding: '0 64px 64px',

    '&:first-child': {
      flex: '0 0 calc(100% - 426px)',
      maxWidth: 'calc(100% - 426px)',

      [theme.breakpoints.down(1419)]: {
        flex: '0 0 calc(100% - 290px)',
        maxWidth: 'calc(100% - 290px)',
        paddingRight: 64,
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: 48,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        padding: 0,
        marginBottom: 64,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 42,
      },
    },

    '&:nth-child(2)': {
      flex: '0 0 426px',
      width: 426,
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[100],

      [theme.breakpoints.down(1419)]: {
        flex: '0 0 290px',
        width: 290,
        border: 'none',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    [theme.breakpoints.down(1419)]: {
      padding: 0,
    },
  },
  pageContainer: {
    flexGrow: 1,
    padding: '0 48px 48px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px 32px',
    },
  },
}))

export interface ILayoutProps {
  column: boolean
  ColPrimary: ReactElement
  ColSecondary: JsxElement
  container: boolean
  children: ReactNode
  id: string
}

function Layout({
  column,
  ColPrimary,
  ColSecondary,
  children,
  container,
  id,
}: ILayoutProps) {
  const classes = useStyles()

  if (column) {
    return (
      <div className={classes.pageRow} id={id}>
        <div className={classes.pageCol}>{ColPrimary}</div>
        <div className={classes.pageCol}>{ColSecondary}</div>
      </div>
    )
  }

  if (container) {
    return <div className={classes.pageContainer}>{children}</div>
  }

  return children
}

export default Layout
