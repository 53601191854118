/* eslint-disable no-param-reassign */
import React from 'react'
// import { motion } from 'framer-motion'
import { Navigate } from 'react-router-dom'
import routeTypes from '../res/enums/routeTypes'
import routes from '../res/routes'

const flatFilter = (
  nestedProp,
  compareKey,
  compareId,
  arr,
  routeReplacement
) => {
  return arr.map((o) => {
    const keep = o[compareKey] !== compareId
    if (keep && o[nestedProp]) {
      o[nestedProp] = flatFilter(
        nestedProp,
        compareKey,
        compareId,
        o[nestedProp]
      )
    }
    const redirect = { ...o }

    // animateRoute(o)

    redirect.element = <Navigate to={routeReplacement || '/'} />
    return keep ? o : redirect
  })
}

// const animateRoute = (route) => {
//   if (route.type === routeTypes.PRIVATE) {
//     route.element = (
//       <motion.div
//         transition={{
//           type: 'spring',
//           damping: 20,
//           stiffness: 100,
//         }}
//         initial={{ y: 20, opacity: 0 }}
//         animate={{ y: 0, opacity: 1 }}
//         exit={{ y: -20, opacity: 0 }}
//       >
//         {route.element}
//       </motion.div>
//     )
//   }
// }

export default function useGetRoutes(isLoggedIn) {
  return flatFilter(
    'children',
    'type',
    isLoggedIn ? routeTypes.PUBLIC : routeTypes.PRIVATE,
    routes,
    isLoggedIn ? '/dashboard' : '/'
  )
}
