import { BrandPrivacyPolicy, BrandSettlement } from '@airflex/lib-models'
import brandLegalResourcesService from '@app/services/brand-legal-resources.service'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const getSettlementByBrandId = (brandId: string) =>
  useQuery<BrandSettlement, Error>(
    ['getBrandSettlement', brandId],
    () => brandLegalResourcesService.getSettlementByBrandId(brandId),
    {
      enabled: false,
    }
  )

export const getPrivacyPolicyByBrandId = (brandId: string) =>
  useQuery<BrandPrivacyPolicy, Error>(
    ['getPrivacyPolicy', brandId],
    () => brandLegalResourcesService.getPrivacyPolicyByBrandId(brandId),
    {
      enabled: false,
    }
  )

const useBrandLegalResourcesMutation = () => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: createBrandSettlement,
    isLoading: isLoadingCreateBrandSettlement,
  } = useMutation(
    'createBrandSettlement',
    brandLegalResourcesService.createSettlement,
    {
      onSuccess: () => queryClient.invalidateQueries('getBrandSettlement'),
    }
  )

  const {
    mutateAsync: updateSettlement,
    isLoading: isLoadingUpdateSettlement,
  } = useMutation(
    'updateSettlement',
    brandLegalResourcesService.updateSettlement
  )

  const {
    mutateAsync: createBrandPrivacyPolicy,
    isLoading: isLoadingCreateBrandPrivacyPolicy,
  } = useMutation(
    'createBrandPrivacyPolicy',
    brandLegalResourcesService.createPrivacyPolicy,
    {
      onSuccess: () => queryClient.invalidateQueries('getPrivacyPolicy'),
    }
  )

  const {
    mutateAsync: updatePrivacyPolicy,
    isLoading: isLoadingUpdatePrivacyPolicy,
  } = useMutation(
    'updatePrivacyPolicy',
    brandLegalResourcesService.updatePrivacyPolicy
  )

  const isLoading =
    isLoadingCreateBrandSettlement ||
    isLoadingUpdateSettlement ||
    isLoadingCreateBrandPrivacyPolicy ||
    isLoadingUpdatePrivacyPolicy

  return {
    createBrandSettlement,
    updateSettlement,
    createBrandPrivacyPolicy,
    updatePrivacyPolicy,
    isLoading,
  }
}

export default useBrandLegalResourcesMutation
