import wheelEmailService from '@app/services/wheel-email.service'
import { useQueryClient, useMutation } from 'react-query'

const useWheelEmailMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: resendEmail, isLoading: isLoadingResendEmail } =
    useMutation('resendEmail', wheelEmailService.createEmail, {
      onSuccess: () => queryClient.invalidateQueries('getVoucherByWheel'),
    })

  const arrLoading = [isLoadingResendEmail]

  const isLoading = arrLoading.some((value) => value)

  return {
    resendEmail,
    isLoading,
  }
}

export default useWheelEmailMutation
