import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import config from '../../res/config'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '10%',
    minWidth: '200px',
    animation: `$breath 3000ms infinite ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes breath': {
    '0%': {
      transform: 'scale(1,1)',
    },
    '50%': {
      transform: 'scale(1.1, 1.1)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },
}))

export default function LoadingScreen() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={config.pictures.loader} className={classes.image} alt="" />
    </div>
  )
}
