import React, { useState } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import CreateGraphicBrand from './form/CreateGraphicBrand'
import { BRAND_FORM_TYPE, useFormBrand } from '../hooks/useFormBrand'
import useBrandGraphicMutation from '../hooks/queries/useBrandGraphic'
import useBrandMutation from '../hooks/queries/useBrand'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  colors: {
    marginTop: 32,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      padding: '32px 0',
      borderColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[100],
      borderStyle: 'solid',
      borderWidth: '1px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderWidth: '1px 0 0',
    },
  },
  color: {
    display: 'flex',
    alignItems: 'center',
    padding: '32px 0',
    borderTopColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      padding: '24px 0',
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[100],
    },
  },
  prev: {
    flexShrink: 0,
    width: 48,
    height: 48,
    marginRight: 32,
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  box: {
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.333333333,
    color: theme.palette.text.hint,
  },
  btns: {
    display: 'flex',
    margin: '0 -4px',
  },
  btn: {
    flexGrow: 1,
    width: '100%',
    minWidth: 'auto',
    margin: '0 4px',
    marginTop: 40,
    padding: 0,
    height: 48,
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

const DisplayBrand = () => {
  const [error, setError] = useState(false)
  const classes = useStyles()

  const { isLoading, deleteBrandGraphic } = useBrandGraphicMutation()
  const { updateGraphicChartStatus, updateBrandStatus } = useBrandMutation()

  const {
    graphicData,
    setGraphicData,
    setToggleEdit,
    toggleEdit,
    setOpen,
    setType,
    brandId,
  } = useFormBrand()

  const removeAll = () => {
    setOpen(false)
    setToggleEdit(false)
    setGraphicData(undefined)
    setType(BRAND_FORM_TYPE.GRAPHIC)
  }

  const handleDelete = async () => {
    setError(false)
    try {
      if (typeof brandId === 'string') {
        await deleteBrandGraphic({
          id: graphicData?._id,
          brand: brandId,
        })
        await updateGraphicChartStatus({
          id: brandId,
          graphicChartStatus: false,
        })
        await updateBrandStatus({
          id: brandId,
          status: false,
        })
        removeAll()
      } else {
        setError(true)
      }
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  if (!graphicData) {
    return (
      <div className={classes.loading}>
        <CircularProgress
          size={60}
          style={{
            color: '#eee',
          }}
        />
      </div>
    )
  }

  if (!toggleEdit) {
    return (
      <div className={classes.root}>
        <div className={classes.colors}>
          <div className={classes.color}>
            <div
              className={classes.prev}
              style={{
                backgroundColor: graphicData?.bgColorBody,
              }}
            />
            <div className={classes.box}>
              <div className={classes.title}>Fond</div>
            </div>
          </div>
          {graphicData?.bgImageBody && (
            <div className={classes.color}>
              <div
                className={classes.prev}
                style={{
                  backgroundImage: `url(${graphicData?.bgImageBody})`,
                  backgroundSize: 'cover',
                }}
              />
              <div className={classes.box}>
                <div className={classes.title}>Image de fond</div>
              </div>
            </div>
          )}
          <div className={classes.color}>
            <div
              className={classes.prev}
              style={{
                backgroundColor: graphicData?.textColorBody,
              }}
            />
            <div className={classes.box}>
              <div className={classes.title}>Texte de Fond</div>
            </div>
          </div>
          <div className={classes.color}>
            <div
              className={classes.prev}
              style={{
                backgroundColor: graphicData?.btnPrimaryColor,
              }}
            />
            <div className={classes.box}>
              <div className={classes.title}>Bouton 1</div>
            </div>
          </div>
          <div className={classes.color}>
            <div
              className={classes.prev}
              style={{
                backgroundColor: graphicData?.btnPrimaryTextColor,
              }}
            />
            <div className={classes.box}>
              <div className={classes.title}>Texte bouton 1</div>
            </div>
          </div>
          <div className={classes.color}>
            <div
              className={classes.prev}
              style={{
                backgroundColor: graphicData?.btnSecondaryColor,
              }}
            />
            <div className={classes.box}>
              <div className={classes.title}>Bouton 2</div>
            </div>
          </div>
          <div className={classes.color}>
            <div
              className={classes.prev}
              style={{
                backgroundColor: graphicData?.btnSecondaryTextColor,
              }}
            />
            <div className={classes.box}>
              <div className={classes.title}>Texte bouton 2</div>
            </div>
          </div>
        </div>
        <div className={classes.btns}>
          <button
            type="button"
            className={clsx(classes.btn, classes.btnBlue)}
            onClick={() => setToggleEdit(true)}
          >
            {isLoading ? (
              <CircularProgress
                size={20}
                style={{
                  color: '#fff',
                }}
              />
            ) : (
              'Editer'
            )}
          </button>
          <button
            type="button"
            className={clsx(classes.btn, classes.btnBorder)}
            onClick={handleDelete}
          >
            {isLoading ? (
              <CircularProgress
                size={20}
                style={{
                  color: '#fff',
                }}
              />
            ) : (
              'Supprimer'
            )}
          </button>
        </div>
        {error && (
          <div className={classes.fieldError}>
            <span className={classes.invalidFeed}>
              Une erreur est survenue, veuillez recommencer
            </span>
          </div>
        )}
      </div>
    )
  }

  return <CreateGraphicBrand edit />
}

export default DisplayBrand
