/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { Edit, AddCircle, Delete } from '@material-ui/icons'

import {
  Brand,
  Customer,
  TIME_MESURE,
  WheelOffer,
  WheelVoucher,
} from '@airflex/lib-models'
import brandLegalResourcesService from '@app/services/brand-legal-resources.service'
import useBrandMutation from '@hooks/queries/useBrand'
import { BRAND_FORM_TYPE, useFormBrand } from '../hooks/useFormBrand'
import brandGraphicChartService from '../services/brand-graphic-chart.service'
import customerService from '../services/customer.service'
import dateFormated, { dateFormatedCustomer } from '../utils'
import Dialog from './MaterialUI/Dialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'table',
    width: '100%',
  },
  row: {
    display: 'table-row',
    borderRadius: 12,
    transition: 'all .2s',
    '&:nth-child(odd)': {
      background:
        theme.palette.type === 'light'
          ? `${theme.palette.grey[200]}33`
          : `${theme.palette.grey[200]}0a`,
    },
    '&:not(.head) .cell': {
      height: 80,
      fontSize: 14,
    },
  },
  noInteract: {
    cursor: 'initial',
    '&:not(.head):hover': {
      boxShadow: 'none',
    },
  },
  head: {
    cursor: 'initial',
    '& .cell': {
      height: 40,
      fontSize: 10,
      fontWeight: 600,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: theme.palette.grey[400],
      '&:first-child': {
        borderRadius: '12px 0 0 12px',
      },
      '&:last-child': {
        borderRadius: '0 12px 12px 0',
      },
    },
  },
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingLeft: 24,
    '&:first-child': {
      borderRadius: '12px 0 0 12px',
    },
    '&:last-child': {
      borderRadius: '0 12px 12px 0',
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
    },
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.text.hint,
    fontWeight: 700,
    '&.cell_small': {
      fontSize: 12,
    },
    '&.cell_right': {
      textAlign: 'right',
    },
    '&.cell_center': {
      textAlign: 'center',
    },
  },
  status: {
    position: 'relative',
    flexShrink: 0,
    fontSize: 20,
  },
  edit: {
    position: 'relative',
    flexShrink: 0,
    fontSize: 25,
    cursor: 'pointer',
    padding: 5,
    borderRadius: 50,
    '&:not(.head):hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  sucess: {
    color: theme.palette.success.main,
    marginRight: 5,
  },
  danger: {
    color: theme.palette.warning.main,
  },
  add: {
    color: theme.palette.warning.main,
    fontSize: 25,
    cursor: 'pointer',
  },
  delete: {
    color: '#343434',
    fontSize: 25,
    cursor: 'pointer',
  },
}))

export interface ITableProps {
  headLabels: string[]
  datas?: Brand[]
  datasWheel?: WheelOffer[]
  datasInstance?: WheelOffer[]
  datasCustomer?: WheelVoucher[]
}

const Table = ({
  headLabels,
  datas,
  datasWheel,
  datasInstance,
  datasCustomer,
}: ITableProps) => {
  const [popupDeleteBrand, setPopupDeleteBrand] = useState<boolean>(false)
  const [brandToDelete, setBrandToDelete] = useState<string | null>(null)

  const {
    setBrandId,
    setType,
    setOpen,
    setBrandData,
    setGraphicData,
    setSettlement,
    setPrivacyPolicy,
  } = useFormBrand()

  const { deleteBrand } = useBrandMutation()

  const classes = useStyles()

  const { getBrandGraphicChart } = brandGraphicChartService
  const { getSettlementByBrandId, getPrivacyPolicyByBrandId } =
    brandLegalResourcesService

  const [customers, setCustomers] = useState<Customer[]>()

  const fetchDataForCustomer = async () => {
    try {
      const { data } = await customerService.getCustomers()
      if (data) {
        setCustomers(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (datasCustomer) {
      fetchDataForCustomer()
    }
  }, [])

  const handleBrandGraphicChart = async (brand: Brand) => {
    setBrandId(brand._id)
    setBrandData(brand)

    const result = await getBrandGraphicChart(brand._id)

    if (result) {
      setGraphicData(result)
      setType(BRAND_FORM_TYPE.GRAPHIC_EDIT)
      setOpen(true)
    } else {
      setType(BRAND_FORM_TYPE.GRAPHIC)
      setOpen(true)
    }
  }

  const handleSettlement = async (brand: Brand) => {
    setBrandId(brand._id)
    setBrandData(brand)
    if (brand._id) {
      const result = await getSettlementByBrandId(brand._id)
      setSettlement(result)
    }
    setType(BRAND_FORM_TYPE.SETTLEMENT)
    setOpen(true)
  }

  const handlePrivacyPolicy = async (brand: Brand) => {
    setBrandId(brand._id)
    setBrandData(brand)
    if (brand._id) {
      const result = await getPrivacyPolicyByBrandId(brand._id)
      setPrivacyPolicy(result)
    }
    setType(BRAND_FORM_TYPE.PRIVACY_POLICY)
    setOpen(true)
  }

  const handleDeleteBrand = async (id: string) => {
    setPopupDeleteBrand(true)
    setBrandToDelete(id)
  }
  const handleConfirmDelete = async () => {
    if (brandToDelete) {
      try {
        await deleteBrand(brandToDelete)
        setPopupDeleteBrand(false)
        setBrandToDelete(null)
      } catch (e) {
        console.log(e)
      }
    }
  }
  const handleCancelDelete = async () => {
    setPopupDeleteBrand(false)
    setBrandToDelete(null)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={clsx(classes.row, classes.head, 'head')}>
          {headLabels &&
            headLabels.map((label) => (
              <div
                key={`table_row_head_${label}`}
                className={clsx(classes.cell, 'cell')}
              >
                {label}
              </div>
            ))}
        </div>
        {datas?.map((item) => (
          <div
            className={classes.row}
            key={item._id}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <div className={clsx(classes.cell, 'cell')}>
              <div className={classes.text}>{item.name}</div>
            </div>
            <div className={clsx(classes.cell, 'cell')}>
              <img src={item.logo} alt="" width={32} />
            </div>
            <div className={clsx(classes.cell, 'cell')}>
              {item.graphicChartStatus ? (
                <div className={clsx(classes.cellContainer)}>
                  <CheckBoxOutlinedIcon
                    className={clsx(classes.status, classes.sucess)}
                  />
                  <Edit
                    className={clsx(classes.edit)}
                    onClick={() => handleBrandGraphicChart(item)}
                  />
                </div>
              ) : (
                <AddCircle
                  className={clsx(classes.add)}
                  onClick={() => handleBrandGraphicChart(item)}
                />
              )}
            </div>
            <div className={clsx(classes.cell, 'cell')}>
              {item.settlementStatus ? (
                <div className={clsx(classes.cellContainer)}>
                  <CheckBoxOutlinedIcon
                    className={clsx(classes.status, classes.sucess)}
                  />
                  <Edit
                    className={clsx(classes.edit)}
                    onClick={() => handleSettlement(item)}
                  />
                </div>
              ) : (
                <AddCircle
                  className={clsx(classes.add)}
                  onClick={() => handleSettlement(item)}
                />
              )}
            </div>
            <div className={clsx(classes.cell, 'cell')}>
              {item.privacyPolicyStatus ? (
                <div className={clsx(classes.cellContainer)}>
                  <CheckBoxOutlinedIcon
                    className={clsx(classes.status, classes.sucess)}
                  />
                  <Edit
                    className={clsx(classes.edit)}
                    onClick={() => handlePrivacyPolicy(item)}
                  />
                </div>
              ) : (
                <AddCircle
                  className={clsx(classes.add)}
                  onClick={() => handlePrivacyPolicy(item)}
                />
              )}
            </div>
            <div className={clsx(classes.cell, 'cell')}>
              {item._id && (
                <Button onClick={() => handleDeleteBrand(item._id || '')}>
                  <Delete className={clsx(classes.delete)} />
                </Button>
              )}
            </div>
          </div>
        ))}
        {datasWheel?.map((offer, idx) => {
          return (
            <div
              className={clsx(classes.row, classes.noInteract)}
              key={offer.positionAngle}
            >
              <div className={clsx(classes.cell, 'cell')}>
                <div className={classes.text}>{idx + 1}</div>
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                <div className={classes.text}>{offer.label}</div>
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                <div className={classes.text}>
                  {offer.useCode && offer.code ? offer.code : '-'}
                </div>
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                {offer.useActivatedAt && offer.activatedAt ? (
                  <div className={classes.text}>
                    {dateFormated(offer.activatedAt)}
                  </div>
                ) : (
                  '-'
                )}
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                {offer.expirationDate ? (
                  <div className={classes.text}>
                    {dateFormated(offer.expirationDate)}
                  </div>
                ) : (
                  '-'
                )}
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                {offer.percentage ? (
                  <div className={classes.text}>{offer.percentage}%</div>
                ) : (
                  '-'
                )}
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                {offer.useMaxWinner && offer.maxWinner ? (
                  <div className={classes.text}>{offer.maxWinner}</div>
                ) : (
                  '-'
                )}
              </div>
              <div className={clsx(classes.cell, 'cell')}>
                {offer.useCouldBeUsedAfter && offer.couldBeUsedAfter ? (
                  <div className={classes.text}>
                    {offer.couldBeUsedAfterMesure === TIME_MESURE.MINUTE &&
                      `${offer.couldBeUsedAfter * 60}min`}
                    {offer.couldBeUsedAfterMesure === TIME_MESURE.HOUR &&
                      offer.couldBeUsedAfter < 24 &&
                      `${offer.couldBeUsedAfter}h`}
                    {offer.couldBeUsedAfterMesure === TIME_MESURE.HOUR &&
                      offer.couldBeUsedAfter >= 24 &&
                      `${offer.couldBeUsedAfter / 24}j`}
                    {offer.couldBeUsedAfterMesure === TIME_MESURE.DAY &&
                      `${offer.couldBeUsedAfter / 24}j`}
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </div>
          )
        })}
        {datasInstance?.map((offer, idx) => (
          <div
            className={clsx(classes.row, classes.noInteract)}
            key={offer.positionAngle}
          >
            <div className={clsx(classes.cell, 'cell')} style={{ height: 50 }}>
              <div className={clsx(classes.text, 'cell_small')}>{idx + 1}</div>
            </div>
            <div className={clsx(classes.cell, 'cell')} style={{ height: 50 }}>
              <div className={clsx(classes.text, 'cell_small')}>
                {offer.label}
              </div>
            </div>
            <div className={clsx(classes.cell, 'cell')} style={{ height: 50 }}>
              <div className={clsx(classes.text, 'cell_small')}>
                {offer.code ? offer.code : '-'}
              </div>
            </div>
            <div className={clsx(classes.cell, 'cell')} style={{ height: 50 }}>
              <div className={clsx(classes.text, 'cell_small')}>
                {offer.percentage}%
              </div>
            </div>
            <div className={clsx(classes.cell, 'cell')} style={{ height: 50 }}>
              <div className={clsx(classes.text, 'cell_small')}>
                {offer.maxWinner ? offer.maxWinner : '-'}
              </div>
            </div>
          </div>
        ))}
        {customers &&
          datasCustomer?.map((item, idx) => {
            const customer = customers.find((val) => val._id === item.customer)

            const { code, updatedAt } = item.offer as WheelOffer
            const wheel = item.wheel as string

            return (
              <div
                className={clsx(classes.row, classes.noInteract)}
                key={item._id}
              >
                <div
                  className={clsx(classes.cell, 'cell')}
                  style={{ height: 50 }}
                >
                  <div className={clsx(classes.text, 'cell_small')}>
                    {item.type}
                  </div>
                </div>
                <div
                  className={clsx(classes.cell, 'cell')}
                  style={{ height: 50 }}
                >
                  <div className={clsx(classes.text, 'cell_small')}>
                    {customer && customer.email}
                  </div>
                </div>
                <div
                  className={clsx(classes.cell, 'cell')}
                  style={{ height: 50 }}
                >
                  <div className={clsx(classes.text, 'cell_small')}>{code}</div>
                </div>
                <div
                  className={clsx(classes.cell, 'cell')}
                  style={{ height: 50 }}
                >
                  <div className={clsx(classes.text, 'cell_small')}>
                    {dateFormatedCustomer(updatedAt)}
                  </div>
                </div>
                <div
                  className={clsx(classes.cell, 'cell')}
                  style={{ height: 50 }}
                >
                  <div className={clsx(classes.text, 'cell_small')}>
                    {wheel.slice(0, 7)}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      {popupDeleteBrand && (
        <Dialog
          open={popupDeleteBrand}
          title="Êtes-vous sûr de vouloir supprimer ce restaurant ?"
          handleClose={handleCancelDelete}
          handleValid={handleConfirmDelete}
        />
      )}
    </>
  )
}

export default Table
