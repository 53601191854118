import { Brand } from '@airflex/lib-models'
import axios from 'axios'

const urls = {
  createBrand: '/brands',
  getBrands: '/brands',
  getBrandById: '/brands/:id',
  updateBrandLogo: '/brands/:brandId/update-logo',
  updateBrandStatus: '/brands/:brandId/update-brand-status',
  updateGraphicChartStatus: '/brands/:brandId/update-graphic-chart-status',
  updateSettlementStatus: '/brands/:brandId/update-settlement-status',
  updatePrivacyPolicyStatus: '/brands/:brandId/update-privacy-policy-status',
  deleteBrand: '/brands',
}

export interface UpdateBrandLogoVariables {
  formData: FormData
  id: string
}

export interface UpdateBrandStatusVariables {
  status: boolean
  id: string
}
export interface UpdateGraphicChartStatusVariables {
  graphicChartStatus: boolean
  id: string
}

export interface UpdateSettlementVariables {
  settlementStatus: boolean
  id: string
}

export interface UpdatePrivacyPolicyVariables {
  privacyPolicyStatus: boolean
  id: string
}

type CreateBrandTypes = {
  name: string
}

const createBrand = (createBrandBody: CreateBrandTypes) =>
  axios.post(urls.createBrand, createBrandBody)

const getBrands = () =>
  axios.get(urls.getBrands, {
    params: {
      offset: 0,
      limit: 0,
    },
  })
const getBrandById = (id: string): Promise<Brand> =>
  axios.get(urls.getBrandById, { urlParams: { id } }).then(({ data }) => data)

const updateBrandLogo = ({ formData, id }: UpdateBrandLogoVariables) =>
  axios.patch(urls.updateBrandLogo, formData, { urlParams: { brandId: id } })

const updateBrandStatus = ({ status, id }: UpdateBrandStatusVariables) =>
  axios.patch(
    urls.updateBrandStatus,
    { status },
    {
      urlParams: { brandId: id },
    }
  )

const updateGraphicChartStatus = ({
  graphicChartStatus,
  id,
}: UpdateGraphicChartStatusVariables) =>
  axios.patch(
    urls.updateGraphicChartStatus,
    { graphicChartStatus },
    {
      urlParams: { brandId: id },
    }
  )

const updateSettlementStatus = ({
  settlementStatus,
  id,
}: UpdateSettlementVariables) =>
  axios.patch(
    urls.updateSettlementStatus,
    { settlementStatus },
    { urlParams: { brandId: id } }
  )

const updatePrivacyPolicyStatus = ({
  privacyPolicyStatus,
  id,
}: UpdatePrivacyPolicyVariables) =>
  axios.patch(
    urls.updatePrivacyPolicyStatus,
    { privacyPolicyStatus },
    { urlParams: { brandId: id } }
  )

const deleteBrand = (brandId: string) =>
  axios.delete(urls.deleteBrand, {
    data: { id: brandId },
  })

const brandsService = {
  createBrand,
  getBrands,
  getBrandById,
  updateBrandLogo,
  updateBrandStatus,
  updateGraphicChartStatus,
  updateSettlementStatus,
  updatePrivacyPolicyStatus,
  deleteBrand,
}

export default brandsService
