import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { WheelOffer, OFFER_STATUS, Campaign } from '@airflex/lib-models'
import { SubmitHandler } from 'react-hook-form'

import ChooseOfferWin from './ChooseOfferWin'
import ChooseOfferLose from './ChooseOfferLose'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

const offerStatus = Object.keys(OFFER_STATUS).map((value) => value)

interface IChooseOfferProps {
  selectedCampaign: Campaign | null
  onSubmit: SubmitHandler<WheelOffer>
  positionAngle: number
  defaultValue?: WheelOffer
  index: number
}

const ChooseOffer = ({
  selectedCampaign,
  onSubmit,
  positionAngle,
  defaultValue,
  index,
}: IChooseOfferProps) => {
  const [offer, setOffer] = useState<string>()
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOffer(event.target.value as string)
  }

  useEffect(() => {
    if (defaultValue?.status) {
      setOffer(defaultValue?.status || '')
    } else {
      setOffer('')
    }
  }, [defaultValue])

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-type-offer" className={classes.label}>
          Choisir le status du quartier
        </InputLabel>
        <Select
          classes={{
            root: classes.fieldInput,
          }}
          labelId="select-type-offer"
          id="select-type-offer"
          defaultValue={defaultValue?.status}
          onChange={handleChange}
        >
          {offerStatus.map((item) => (
            <MenuItem key={`offer-select-${item}`} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {offer === OFFER_STATUS.WIN && (
        <ChooseOfferWin
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmit}
          positionAngle={positionAngle}
          defaultValue={defaultValue}
          index={index}
        />
      )}
      {offer === OFFER_STATUS.LOSE && (
        <ChooseOfferLose
          onSubmit={onSubmit}
          positionAngle={positionAngle}
          defaultValue={defaultValue}
        />
      )}
    </div>
  )
}

export default ChooseOffer
