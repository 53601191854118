import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { emailTypes } from '@hooks/useFormWheel'

import RichText from '../../RichText'

interface IChooseEmailProps {
  onSubmit: (data: any) => Promise<void>
  defaultValue: emailTypes
}

export type InputsChooseEmail = {
  titleEmail: string
  contentEmail: string
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiInputLabel-formControl[data-shrink=true]': {
      transform: 'translate(0, 4px) scale(1)',
    },
  },
  labelControl: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
    left: 22,
    transform: 'translate(0, 40px) scale(1)',
  },
  field: {
    position: 'relative',
    marginBottom: 15,
  },
  label: {
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  labelSlider: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInputControl: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  fieldInput: {
    width: '100%',
    height: 80,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  focusError: {
    borderColor: theme.palette.error.main,
    '& .MuiInputBase-input:focus': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  // Color Picker
  inputColorPicker: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      width: '100%',
      height: 80,
      padding: '18px 22px 0',
      borderRadius: 12,
      border: '2px solid #0000',
      '&:focus': {
        borderColor: theme.palette.primary.light,
      },
      background:
        theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.hint,
      transition: 'all .2s',
      outline: 'none',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
}))

const ChooseEmail = ({ onSubmit, defaultValue }: IChooseEmailProps) => {
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<InputsChooseEmail>()

  const rules = {
    titleEmail: register('titleEmail', {
      required: { value: true, message: 'Choisir un objet pour email' },
    }),
    contentEmail: register('contentEmail', {
      required: { value: true, message: "Choisir le contenu de l'email" },
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInputControl,
        }}
        InputLabelProps={{
          className: classes.labelControl,
        }}
        label="Objet email"
        type="text"
        defaultValue={defaultValue?.titleEmail}
        {...rules.titleEmail}
      />
      <div className={classes.label}>Corps de l&apos;email</div>
      <RichText
        control={control}
        name={rules.contentEmail.name}
        defaultValue={defaultValue?.contentEmail || ''}
      />
      <button
        type="submit"
        className={clsx(classes.btn, classes.btnBlue)}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        Suivant
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`offer_win-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseEmail
