import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

import { getSettlementByBrandId } from '../../../hooks/queries/useBrandLegalResources'
import CreatePrivacyPolicy from '../../../components/form/CreatePrivacyPolicy'
import CreateSettlement from '../../../components/form/CreateSettlement'
import Layout from '../../../components/Layout'
import CardWidget from '../../../components/CardWidget'
import Table from '../../../components/Table'
import DisplayBrand from '../../../components/DisplayBrand'
import CreateGraphicBrand from '../../../components/form/CreateGraphicBrand'
import { CreateBrand } from '../../../components/form/CreateBrand'

import { getBrands } from '../../../hooks/queries/useBrand'
import { BRAND_FORM_TYPE, useFormBrand } from '../../../hooks/useFormBrand'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    flexGrow: 1,
    padding: '0 48px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px 32px',
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  content: {
    flexGrow: 1,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 48,
      padding: 0,
    },
  },
  contentTitle: {
    fontSize: 18,
    lineHeight: 1.333333,
    fontWeight: 500,
    marginBottom: 12,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  btns: {
    display: 'flex',
    position: 'absolute',
    top: 32,
    right: 32,
    [theme.breakpoints.down('xs')]: {
      position: 'initial',
      justifyContent: 'center',
    },
  },
  btn: {
    minWidth: 114,
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnIcon: {
    fontSize: 16,
    fill: '#fff',
    marginRight: 8,
    verticalAlign: 'middle',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.333333,
    marginBottom: 32,
    color: theme.palette.text.hint,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  // sidebar
  sidebar: {
    flexShrink: 0,
    width: 500,
    marginRight: ({ open }) => (open ? 0 : -500),
    padding: '0 64px 64px 0',
    transition: 'margin .2s',

    [theme.breakpoints.down('1339')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: 0,
      overflow: 'auto',
      padding: 20,
      background: '#1b1d21e6',
      opacity: ({ open }) => (open ? 1 : 0),
      visibility: ({ open }) => (open ? 'visible' : 'hidden'),
      transition: 'all .2s',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      borderRadius: 0,
    },
  },
  warp: {
    position: 'relative',

    [theme.breakpoints.down('1339')]: {
      width: '100%',
      maxWidth: 630,
      margin: 'auto',
      padding: 32,
      borderRadius: 24,
      background: theme.palette.background.paper,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px',
      borderRadius: 0,
    },
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 0,
    width: 20,
    height: 20,

    [theme.breakpoints.down('1339')]: {
      top: 26,
      right: 24,
      width: 48,
      height: 48,
      borderRadius: '50%',
      boxShadow: '0 3px 10px rgb(0 0 0 / 10%)',
    },
    [theme.breakpoints.down('xs')]: {
      top: 22,
      right: 10,
      boxShadow: 'none',
    },
  },
  btnIconClose: {
    fontSize: 26,
    verticalAlign: 'middle',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    paddingRight: 30,

    [theme.breakpoints.down('1339')]: {
      paddingRight: 50,
    },
  },
  prev: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 48,
    height: 48,
    marginRight: 16,
  },
  btnPrev: {
    color: '#fff',
    fontSize: 24,
  },
  details: {
    flexGrow: 1,
  },
  info: {
    marginBottom: 4,
    fontSize: 18,
    lineHeight: 1.33333,
    fontWeight: 500,
  },
  desc: {
    fontSize: 13,
    lineHeight: 1.23077,
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
}))

export default function BrandsScreen() {
  const { open, setOpen, type, setToggleEdit, setType, brandData } =
    useFormBrand()
  const classes = useStyles({ open })

  const { data: brands, isLoading } = getBrands()

  const handleCreateBrand = () => {
    setType(BRAND_FORM_TYPE.BRAND)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setToggleEdit(false)
  }

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.container}>
          <CardWidget>
            <div className={classes.row}>
              <div className={classes.content}>
                <div className={classes.contentTitle}>
                  1. Créer un restaurant
                  <br />
                  2. Associer une Charte Graphique
                  <br />
                  3. Créer le réglement et la politique de confidentialité
                </div>
              </div>
            </div>
            <div className={classes.btns}>
              <button
                type="button"
                className={classes.btn}
                onClick={handleCreateBrand}
                disabled={open}
              >
                <CreateOutlinedIcon className={classes.btnIcon} />
                Créer
              </button>
            </div>
          </CardWidget>
          {brands?.data.length > 0 && !isLoading && (
            <div className="list_brand">
              <div className={classes.title}>Liste des Restaurants</div>
              <Table
                headLabels={[
                  'Nom',
                  'Logo',
                  'Charte Graphique',
                  'Réglement',
                  'Politique de confidentialité',
                  '',
                ]}
                datas={brands.data}
              />
            </div>
          )}
        </div>
        <aside className={classes.sidebar}>
          {isLoading ? (
            <div className={classes.warp}>
              <CircularProgress
                size={60}
                style={{
                  color: '#eee',
                }}
              />
            </div>
          ) : (
            <>
              {type === 'BRAND' && (
                <div className={classes.warp}>
                  <button
                    type="button"
                    className={classes.close}
                    onClick={handleClose}
                  >
                    <CloseOutlinedIcon className={classes.btnIconClose} />
                  </button>
                  <div className={classes.head}>
                    <div className={classes.prev}>
                      <CreateOutlinedIcon className={classes.btnPrev} />
                    </div>
                    <div className={classes.details}>
                      <div className={classes.info}>Nouveau Restaurant</div>
                      <div className={classes.desc}>Remplir le formulaire</div>
                    </div>
                  </div>
                  <CreateBrand />
                </div>
              )}
              {type === 'GRAPHIC' && (
                <div className={classes.warp}>
                  <button
                    type="button"
                    className={classes.close}
                    onClick={handleClose}
                  >
                    <CloseOutlinedIcon className={classes.btnIconClose} />
                  </button>
                  <div className={classes.head}>
                    <div className={classes.prev}>
                      <CreateOutlinedIcon className={classes.btnPrev} />
                    </div>
                    <div className={classes.details}>
                      <div className={classes.info}>
                        Nouvelle Charte Graphique
                      </div>
                      <div className={classes.desc}>Choisir les couleurs</div>
                    </div>
                  </div>
                  <CreateGraphicBrand />
                </div>
              )}
              {type === 'GRAPHIC_EDIT' && (
                <div className={classes.warp}>
                  <button
                    type="button"
                    className={classes.close}
                    onClick={handleClose}
                  >
                    <CloseOutlinedIcon className={classes.btnIconClose} />
                  </button>
                  <div className={classes.head}>
                    <div className={classes.details}>
                      <div className={classes.info}>Charte Graphique</div>
                      <div className={classes.desc}>{brandData.name}</div>
                    </div>
                  </div>
                  <DisplayBrand />
                </div>
              )}
              {type === BRAND_FORM_TYPE.SETTLEMENT && (
                <div className={classes.warp}>
                  <button
                    type="button"
                    className={classes.close}
                    onClick={handleClose}
                  >
                    <CloseOutlinedIcon className={classes.btnIconClose} />
                  </button>
                  <div className={classes.head}>
                    <div className={classes.details}>
                      <div className={classes.info}>Réglement</div>
                      <div className={classes.desc}>{brandData.name}</div>
                    </div>
                  </div>
                  <CreateSettlement brandName={brandData.name} />
                </div>
              )}
              {type === BRAND_FORM_TYPE.PRIVACY_POLICY && (
                <div className={classes.warp}>
                  <button
                    type="button"
                    className={classes.close}
                    onClick={handleClose}
                  >
                    <CloseOutlinedIcon className={classes.btnIconClose} />
                  </button>
                  <div className={classes.head}>
                    <div className={classes.details}>
                      <div className={classes.info}>
                        Politique de confidentialité
                      </div>
                      <div className={classes.desc}>{brandData.name}</div>
                    </div>
                  </div>
                  <CreatePrivacyPolicy brandName={brandData.name} />
                </div>
              )}
            </>
          )}
        </aside>
      </div>
    </Layout>
  )
}
