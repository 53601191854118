/* eslint-disable no-nested-ternary */
import { Campaign, Wheel, WheelVoucher } from '@airflex/lib-models'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { getVoucherByWheel } from '@hooks/queries/useVoucher'
import { dateFormatedTime, getCurrentWeek } from '@app/utils'
import Dialog from '@components/MaterialUI/Dialog'
import MailIcon from '@material-ui/icons/Mail'
import GetAppIcon from '@material-ui/icons/GetApp'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import DialogDownloadData from '@components/MaterialUI/DialogDownloadData'
import useCampaignMutation from '@hooks/queries/useCampaign'
import wheelEmailService from '@app/services/wheel-email.service'
import useWheelEmailMutation from '@hooks/queries/useWheelEmail'

interface WheelStatsInterface {
  wheel: Wheel
  campaign: Campaign
}

interface GetVouchersByCustomersInterface {
  _id: string
  firstname: string
  email: string
  campaign: string
  createdAt: string
  updatedAt: string
  vouchers: WheelVoucher[]
}

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 20,
    marginBottom: 40,
    borderRadius: 10,
    backgroundColor: '#F9F9F9',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.333333,
    marginBottom: 10,
    color: theme.palette.text.hint,
  },
  subtitle: {
    fontSize: 16,
  },
  generalStatCard: {
    padding: 25,
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: '2px 3px 17px -3px rgba(201,201,201,1)',
    height: '100%',
  },
  generalStatCardTitle: {
    fontSize: 12,
    marginBottom: 5,
  },
  generalStatCardSubtitle: {
    fontWeight: 700,
    fontSize: 20,
  },
  deleteButton: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerElements: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
    alignItems: 'start',
  },
  badge: {
    backgroundColor: '#C7DDFF',
    fontSize: 11,
    fontWeight: 700,
    padding: '5px 10px',
    width: 'fit-content',
    borderRadius: 12,
    textTransform: 'uppercase',
  },
  exportButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    padding: '8px 16px',
    color: theme.palette.common.white,
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 14,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function getVouchersByCustomersData(data: any[]) {
  const transformedArray = []

  for (let i = 0; i < data.length; i += 1) {
    const { customer } = data[i]
    let foundCustomer = false

    for (let j = 0; j < transformedArray.length; j += 1) {
      if (customer._id === transformedArray[j]._id) {
        transformedArray[j].vouchers.push(data[i])

        foundCustomer = true
        break
      }
    }

    if (!foundCustomer) {
      transformedArray.push({
        ...customer,
        vouchers: [{ ...data[i] }],
      })
    }
  }

  return transformedArray
}

function getVouchersDataOfTheWeek(data: GetVouchersByCustomersInterface[]) {
  const today = new Date()
  const firstDayOfWeek = new Date(today)
  const lastDayOfWeek = new Date(today)

  // Définir l'heure et les minutes sur 0
  firstDayOfWeek.setHours(0, 0, 0, 0)
  lastDayOfWeek.setHours(0, 0, 0, 0)

  firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1)
  lastDayOfWeek.setDate(today.getDate() - today.getDay() + 8)

  console.log(firstDayOfWeek, lastDayOfWeek)
  const customersOfTheWeek = data.filter((d: any) => {
    const createdAt = new Date(d.createdAt)
    return createdAt >= firstDayOfWeek && createdAt <= lastDayOfWeek
  })
  return customersOfTheWeek
}

function getCustomerDataOfTheWeek(data: GetVouchersByCustomersInterface[]) {
  const today = new Date()
  const firstDayOfWeek = new Date(today)
  const lastDayOfWeek = new Date(today)

  // Définir l'heure et les minutes sur 0
  firstDayOfWeek.setHours(0, 0, 0, 0)
  lastDayOfWeek.setHours(0, 0, 0, 0)

  firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1)
  lastDayOfWeek.setDate(today.getDate() - today.getDay() + 8)

  const customersOfTheWeek = data.filter((d: any) => {
    for (const voucher of d.vouchers) {
      const createdAt = new Date(voucher.createdAt)
      return createdAt >= firstDayOfWeek && createdAt <= lastDayOfWeek
    }
    return null
  })
  return customersOfTheWeek
}

const headers = [
  { label: 'Action', key: 'action' },
  { label: 'Prénom', key: 'firstname' },
  { label: 'Email', key: 'email' },
  { label: 'Nom du quartier', key: 'quarterName' },
  { label: 'Type du quartier', key: 'quarterType' },
  { label: 'Crée le', key: 'createdAt' },
  { label: 'Status du coupon', key: 'status' },
  { label: 'Relancé le', key: 'mailSentAt' },
]

function dataToExport(data: any) {
  if (data) {
    return data.map((d: any) => ({
      action: d.action?.type || '',
      firstname: d.customer.firstname || '',
      email: d.customer.email || '',
      quarterName: d.offer.label || '',
      quarterType: d.offer.status || '',
      createdAt: d.createdAt ? dateFormatedTime(d.createdAt) : '-',
      status: d.status ? d.status : '-',
      mailSentAt: d.resendAt ? dateFormatedTime(d.resendAt) : '-',
    }))
  }
  return null
}

const WheelStats = ({ wheel, campaign }: WheelStatsInterface) => {
  const classes = useStyle()
  const { resendEmail } = useWheelEmailMutation()
  const [openDetails, setOpenDetails] = useState<boolean>(false)

  const [vouchersByCustomer, setVoucherByCustomer] = useState<
    GetVouchersByCustomersInterface[] | null
  >(null)
  const [winningVouchers, setWinningVouchers] = useState<any[] | null>(null)
  const [customersOfTheWeek, setCustomersOfTheWeek] = useState<any[] | null>(
    null
  )
  const [vouchersOfTheWeek, setVouchersOfTheWeek] = useState<
    GetVouchersByCustomersInterface[] | null
  >(null)

  const [exportCampaignGlobal, setExportCampaignGlobal] = useState<any>(null)
  const [exportCampaignOfCurrentWeek, setExportCampaignOfCurrentWeek] =
    useState<any>(null)

  const { data: vouchers, refetch } = getVoucherByWheel(wheel._id || '')

  const getWinningVouchersData = (data: any[]) =>
    data.filter((voucher: any) => voucher.offer.status === 'WIN')

  useEffect(() => {
    if (vouchers?.data) {
      const getVouchersByCustomers = getVouchersByCustomersData(vouchers?.data)
      setVoucherByCustomer(getVouchersByCustomers)
      const getWinningVouchers = getWinningVouchersData(vouchers?.data)
      setWinningVouchers(getWinningVouchers)
      const getCustomersOfTheWeek = getCustomerDataOfTheWeek(
        getVouchersByCustomers
      )
      console.log(getVouchersByCustomers)
      setCustomersOfTheWeek(getCustomersOfTheWeek)
      const getVouchersOfTheWeek = getVouchersDataOfTheWeek(vouchers?.data)
      setVouchersOfTheWeek(getVouchersOfTheWeek)

      const globalData = dataToExport(vouchers?.data)
      setExportCampaignGlobal({
        data: globalData,
        headers,
        filename: `${campaign?.brandInfo?.name}_Campaign_Data.csv`,
      })
    }
  }, [vouchers])

  useEffect(() => {
    console.log(customersOfTheWeek)
  }, [customersOfTheWeek])

  useEffect(() => {
    const currentWeekData = dataToExport(vouchersOfTheWeek)
    setExportCampaignOfCurrentWeek({
      data: currentWeekData,
      headers,
      filename: `${
        campaign?.brandInfo?.name
      }_Campaign_Data_${getCurrentWeek()}.csv`,
    })
  }, [vouchersOfTheWeek])

  const [popupDeleteCampaignStats, setPopupDeleteCampaignStats] =
    useState<boolean>(false)
  const [campaignDataToDelete, setCampaignDataToDelete] = useState<
    string | null
  >(null)

  const { deleteCampaign } = useCampaignMutation()

  const handleDeleteCampaignData = async (campaignId: string) => {
    setPopupDeleteCampaignStats(true)
    setCampaignDataToDelete(campaignId)
  }

  const handleConfirmDelete = async () => {
    if (campaignDataToDelete) {
      try {
        await deleteCampaign(campaignDataToDelete)
        setPopupDeleteCampaignStats(false)
        setCampaignDataToDelete(null)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleCancelDelete = async () => {
    setPopupDeleteCampaignStats(false)
    setCampaignDataToDelete(null)
  }

  const [popupSendMailReminder, setPopupSendMailReminder] =
    useState<boolean>(false)
  const [customerToSendMailReminder, setCustomerToSendMailReminder] = useState<
    string | null
  >(null)
  const [voucherMailReminder, setVoucherMailReminder] = useState<any | null>(
    null
  )

  const handleSendMailReminder = async (customerId: string, voucher: any) => {
    setPopupSendMailReminder(true)
    setCustomerToSendMailReminder(customerId)
    setVoucherMailReminder(voucher)
  }

  const handleConfirmSendMailReminder = async () => {
    if (customerToSendMailReminder && voucherMailReminder && campaign) {
      try {
        resendEmail({
          customerId: customerToSendMailReminder,
          data: {
            brand: campaign.brand,
            campaign: campaign._id || '',
            title: `Relance : ${wheel.titleEmail}`,
            content: wheel.contentEmail,
            offerLabel: voucherMailReminder.offer?.label
              ? voucherMailReminder.offer?.label
              : voucherMailReminder.offer,
            voucher: voucherMailReminder._id || '',
          },
        })
        setPopupSendMailReminder(false)
        setCustomerToSendMailReminder(null)
        setVoucherMailReminder(null)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleCancelSendMailReminder = async () => {
    setPopupSendMailReminder(false)
    setCustomerToSendMailReminder(null)
  }

  const [popupDownloadData, setPopupDownloadData] = useState<boolean>(false)
  const [campaignToDownloadData, setCampaignToDownloadData] = useState<
    string | null
  >(null)

  const handleDownloadData = async (campaignId: string) => {
    setPopupDownloadData(true)
    setCampaignToDownloadData(campaignId)
  }

  const handleCancelDownloadData = async () => {
    setPopupDownloadData(false)
    setCampaignToDownloadData(null)
  }

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.headerElements}>
          <div className={classes.badge}>#{campaign._id?.slice(16, 24)}</div>
          <div>
            <Button
              className={classes.exportButton}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={() => handleDownloadData(campaign._id as string)}
            >
              Télécharger
            </Button>
          </div>
        </div>
        <h2 className={classes.title}>
          {campaign.brandInfo?.name} | {campaign.title}
        </h2>
      </div>

      <Grid container spacing={8} style={{ marginBottom: 15, marginTop: 5 }}>
        <Grid container spacing={3} item xs={6}>
          <Grid item xs={12}>
            <p className={classes.subtitle}>Statistiques globales</p>
          </Grid>
          <Grid item xs>
            <div className={classes.generalStatCard}>
              <p className={classes.generalStatCardTitle}>
                Nombre de participations
              </p>
              <p className={classes.generalStatCardSubtitle}>
                {vouchers?.data.length}
              </p>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.generalStatCard}>
              <p className={classes.generalStatCardTitle}>
                Nombre de participants
              </p>
              <p className={classes.generalStatCardSubtitle}>
                {vouchersByCustomer?.length}
              </p>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.generalStatCard}>
              <p className={classes.generalStatCardTitle}>
                Nombre de lots gagnés
              </p>
              <p className={classes.generalStatCardSubtitle}>
                {winningVouchers?.length}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={6}>
          <Grid item xs={12}>
            <p className={classes.subtitle}>
              Cette semaine - {getCurrentWeek()}
            </p>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.generalStatCard}>
              <p className={classes.generalStatCardTitle}>
                Nombre de participations
              </p>
              <p className={classes.generalStatCardSubtitle}>
                {vouchersOfTheWeek?.length}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.generalStatCard}>
              <p className={classes.generalStatCardTitle}>
                Nombre de participants
              </p>
              <p className={classes.generalStatCardSubtitle}>
                {customersOfTheWeek?.length}
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div>
        <p className={classes.subtitle}>Détails de la campagne</p>
        <IconButton
          aria-label="expand row"
          size="medium"
          onClick={() => setOpenDetails(!openDetails)}
        >
          {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <TableContainer component={Paper} style={{ marginTop: 15 }}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Action</StyledTableCell>
                  <StyledTableCell>Prénom</StyledTableCell>
                  <StyledTableCell>Mail</StyledTableCell>
                  <StyledTableCell>Nom du quartier</StyledTableCell>
                  <StyledTableCell>Type du quartier</StyledTableCell>
                  <StyledTableCell>Crée le</StyledTableCell>
                  <StyledTableCell>Status du coupon</StyledTableCell>
                  <StyledTableCell>Relance</StyledTableCell>
                  <StyledTableCell>Relancé le</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vouchers?.data.map((row: any) => (
                  <StyledTableRow key={`row-voucher-${row._id}`}>
                    <StyledTableCell component="th" scope="row">
                      {row.action?.type}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.customer.firstname}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.customer.email}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.offer.label}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.offer.status}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {dateFormatedTime(row.createdAt)}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {(row.offer.status === 'WIN' ||
                        row.status === 'DRAFT') && (
                        <div
                          className={classes.badge}
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            background:
                              row.status === 'USED'
                                ? '#76eab8'
                                : row.status === 'VALIDATED'
                                ? '#dcc7ff'
                                : '#C7DDFF',
                          }}
                        >
                          {row.status === 'USED'
                            ? 'Utilisé'
                            : row.status === 'VALIDATED'
                            ? 'Validé'
                            : 'Créé'}
                        </div>
                      )}
                      {row.offer.status === 'LOSE' && row.status === 'USED' && (
                        <div
                          className={classes.badge}
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            background: '#ea7676',
                          }}
                        >
                          Perdu
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'center' }}
                    >
                      {row.offer.status === 'WIN' &&
                      row.status === 'VALIDATED' ? (
                        <IconButton
                          onClick={() =>
                            handleSendMailReminder(
                              row.customer._id as string,
                              row as WheelVoucher
                            )
                          }
                        >
                          <MailIcon color="secondary" />
                        </IconButton>
                      ) : (
                        '-'
                      )}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.resendAt ? dateFormatedTime(row.resendAt) : '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableContainer>
      </div>
      {!wheel.status && campaign.isDeleted && (
        <div className={classes.deleteButton}>
          <Button
            onClick={() => handleDeleteCampaignData(campaign._id as string)}
            style={{ color: 'red', fontSize: 10 }}
          >
            Supprimer
          </Button>
        </div>
      )}
      {popupDeleteCampaignStats && (
        <Dialog
          open={popupDeleteCampaignStats}
          title="Êtes-vous sûr de vouloir supprimer les données de cette campagne ? (la campagne sera supprimée définitivement)"
          handleClose={handleCancelDelete}
          handleValid={handleConfirmDelete}
        />
      )}
      {popupSendMailReminder && (
        <Dialog
          open={popupSendMailReminder}
          title="Êtes-vous sûr de vouloir envoyer un mail de relance à ce participant ?"
          handleClose={handleCancelSendMailReminder}
          handleValid={handleConfirmSendMailReminder}
        />
      )}
      {popupDownloadData && (
        <DialogDownloadData
          open={popupDownloadData}
          title="Quelles données souhaitez-vous télécharger ?"
          handleClose={handleCancelDownloadData}
          downloadData1={exportCampaignGlobal}
          downloadData2={exportCampaignOfCurrentWeek}
          ctaDownloadData1="Statistiques globales"
          ctaDownloadData2="Statistiques de la semaine"
        />
      )}
    </div>
  )
}

export default WheelStats
