import React from 'react'
import {
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { useOpenDrawer } from '../res/context/openDrawer'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: theme.palette.grey[400],
  },
  toggle: {
    fontSize: 24,
    pointerEvents: 'none',
  },
}))

function MenuButtonDrawer() {
  const { open, setOpen } = useOpenDrawer()

  const classes = useStyles()
  const theme = useTheme()

  const matcheSm = useMediaQuery(theme.breakpoints.down('sm'))
  const matcheMd = useMediaQuery(theme.breakpoints.down('md'))

  const handleOpen = () => {
    setOpen(!open)

    if (matcheMd || matcheSm) {
      if (document.body.classList.contains('no-scroll')) {
        document.body.classList.remove('no-scroll')
      } else {
        document.body.classList.add('no-scroll')
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    document.body.classList.remove('no-scroll')
    document.body.removeAttribute('style')
  }

  const IconRtl = () =>
    open ? (
      <MenuOpenIcon className={classes.toggle} />
    ) : (
      <MenuIcon className={classes.toggle} />
    )
  const IconNotRtl = () =>
    open ? (
      <MenuIcon className={classes.toggle} />
    ) : (
      <MenuOpenIcon className={classes.toggle} />
    )

  if (!matcheSm) {
    return (
      <IconButton onClick={handleOpen} classes={{ root: classes.menuButton }}>
        {theme.direction === 'rtl' ? <IconRtl /> : <IconNotRtl />}
      </IconButton>
    )
  }

  return (
    <IconButton onClick={handleClose} classes={{ root: classes.menuButton }}>
      <CloseIcon className={classes.toggle} />
    </IconButton>
  )
}

export default MenuButtonDrawer
