import { Wheel, WheelOffer } from '@airflex/lib-models'
import axios from 'axios'

const urls = {
  createWheel: '/wheel',
  getWheels: '/wheel',
  getWheelById: '/wheel/:id',
  getWheelByCampaignId: '/wheel/campaign/:campaignId',
  updateWheelStatus: '/wheel/:id/update-status',
  updateWheelOffers: '/wheel/:id/update-offers',
  softDeleteWheel: '/wheel/:id/soft-delete-wheel',
  deleteWheel: '/wheel',
}

export interface UpdateWheelStatusVariables {
  status: boolean
  id: string
}
export interface UpdateWheelOffersVariables {
  offers: WheelOffer[]
  id: string
}

const createWheel = (createWheelBody: Wheel) =>
  axios.post(urls.createWheel, createWheelBody)

const getWheels = () => axios.get(urls.getWheels)

const getWheelById = (id: string) =>
  axios.get(urls.getWheelById, {
    urlParams: { id },
  })

const getWheelByCampaignId = (campaignId: string) =>
  axios.get(urls.getWheelByCampaignId, {
    urlParams: { campaignId },
  })

const updateWheelStatus = ({ status, id }: UpdateWheelStatusVariables) =>
  axios.patch(
    urls.updateWheelStatus,
    { status },
    {
      urlParams: { id },
    }
  )

const updateWheelOffers = ({ offers, id }: UpdateWheelOffersVariables) =>
  axios.patch(
    urls.updateWheelOffers,
    { offers },
    {
      urlParams: { id },
    }
  )

const softDeleteWheel = ({
  id,
  isDeleted,
}: {
  id: string
  isDeleted: boolean
}) => axios.patch(urls.softDeleteWheel, { isDeleted }, { urlParams: { id } })

const deleteWheel = (wheelId: string) =>
  axios.delete(urls.deleteWheel, {
    data: { id: wheelId },
  })

const wheelService = {
  createWheel,
  getWheels,
  getWheelById,
  getWheelByCampaignId,
  updateWheelStatus,
  updateWheelOffers,
  softDeleteWheel,
  deleteWheel,
}

export default wheelService
