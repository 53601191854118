import React from 'react'
import {
  makeStyles,
  useTheme,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CSVLink } from 'react-csv'
import Button from './Button'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    h2: {
      fontSize: '2rem',
    },
  },
}))

const DialogDownloadData = ({
  title,
  subtitle = null,
  handleClose,
  open,
  downloadData1,
  downloadData2,
  ctaCancel = 'Annuler',
  ctaDownloadData1,
  ctaDownloadData2,
  children = null,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography
          className={classes.title}
          id="alert-dialog-title"
          variant="h2"
          style={{ padding: 24 }}
        >
          {title}
        </Typography>
        {subtitle && (
          <DialogTitle id="alert-dialog-subtitle">{subtitle}</DialogTitle>
        )}
        {children && <DialogContent>{children}</DialogContent>}
        <DialogActions>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginRight: 20,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <CSVLink
                {...downloadData1}
                style={{ textDecoration: 'none', marginRight: 10 }}
              >
                <Button variant="contained">{ctaDownloadData1}</Button>
              </CSVLink>
              <CSVLink {...downloadData2} style={{ textDecoration: 'none' }}>
                <Button variant="contained">{ctaDownloadData2}</Button>
              </CSVLink>
            </div>
            <div>
              <Button onClick={handleClose} variant="outlined">
                {ctaCancel}
              </Button>
            </div>
          </div>
        </DialogActions>
      </MuiDialog>
    </div>
  )
}

export default DialogDownloadData
