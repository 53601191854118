import axios from 'axios'

const urls = {
  getCustomers: '/customer',
}

const getCustomers = () => axios.get(urls.getCustomers)

const customerService = {
  getCustomers,
}

export default customerService
