import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Control, Controller, UseControllerProps } from 'react-hook-form'
import InputBase from '@material-ui/core/InputBase'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import clsx from 'clsx'
import FormHelperText from '@material-ui/core/FormHelperText'

export interface ICustomInputProps {
  name: string
  control: Control
  label: string
  type: string
  rules: UseControllerProps
  noFocus: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  inputBase: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    background: '#f8f8fa',
    fontWeight: 600,
    letterSpacing: 0.5,
    boxShadow: 'none',
    borderRadius: 20,
    height: 40,
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    fontSize: 14,
    lineHeight: 1.57,
    border: '1px solid transparent',
  },
  input: {
    flexGrow: 1,
    width: '100%',
  },
  iconButton: {
    marginRight: 10,
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: 20,
  },
  helperText: {
    marginLeft: 20,
  },
  error: {
    paddingLeft: 20,
    color: theme.palette.error.main,
    fontSize: 14,
  },
  success: {
    color: theme.palette.success.main,
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}))

function CustomInput({ control, name, label, type, rules }: ICustomInputProps) {
  const [hidden, setHidden] = useState(type === 'password')
  const classes = useStyles()

  const getType = () => (type === 'password' && hidden ? 'password' : 'text')
  const toggleHidden = () => setHidden(!hidden)

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <div className={classes.root}>
          <div
            className={clsx(classes.inputBase, error && classes.errorBorder)}
          >
            <InputBase
              className={classes.input}
              type={type === 'password' ? getType() : type}
              placeholder={label}
              {...field}
            />
            {type === 'password' &&
              (hidden ? (
                <VisibilityIcon
                  className={classes.iconButton}
                  onClick={toggleHidden}
                />
              ) : (
                <VisibilityOffIcon
                  className={classes.iconButton}
                  onClick={toggleHidden}
                />
              ))}
          </div>
          {error && (
            <FormHelperText className={classes.error}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  )
}

export default CustomInput
