import axios from 'axios'

const urls = {
  getVoucherUsed: '/voucher/voucher-used',
  getVoucherByWheel: '/voucher/wheel/:wheelId',
}

const getVoucherUsed = () => axios.get(urls.getVoucherUsed)

const getVoucherByWheel = (wheelId: string) =>
  axios.get(urls.getVoucherByWheel, {
    urlParams: { wheelId },
  })

const voucherService = {
  getVoucherUsed,
  getVoucherByWheel,
}

export default voucherService
