import { CampaignAction, CAMPAIGN_ACTION_TYPE } from '@airflex/lib-models'
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from 'react'

export enum CAMPAIGN_FORM_TYPE {
  BRAND = 'BRAND',
  LOGINPAGE = 'LOGINPAGE',
  ACTION = 'ACTION',
  FINISH = 'FINISH',
}

type loginPageTypes = {
  title: string
  description: string
}

type ActionImageTypes = {
  type: string
  image: FormData
}

export type FormCampaignContextTypes = {
  open: boolean
  type: CAMPAIGN_FORM_TYPE
  brandId: string
  loginPage: loginPageTypes
  loginImage: FormData | undefined
  actions: CampaignAction[]
  actionType: CAMPAIGN_ACTION_TYPE | undefined
  actionsImage: ActionImageTypes[]
  setActions: Dispatch<CampaignAction[]>
  setActionType: Dispatch<CAMPAIGN_ACTION_TYPE | undefined>
  setActionsImage: Dispatch<ActionImageTypes[]>
  setLoginPage: Dispatch<loginPageTypes>
  setLoginImage: Dispatch<FormData | undefined>
  setBrandId: Dispatch<string>
  setOpen: Dispatch<boolean>
  setType: Dispatch<CAMPAIGN_FORM_TYPE>
}

const FormCampaignContext = createContext<FormCampaignContextTypes>(
  {} as FormCampaignContextTypes
)

export interface IFormCampaignProviderProps {
  children: ReactNode
}

export default function FormCampaignProvider({
  children,
}: IFormCampaignProviderProps) {
  const [open, setOpen] = useState(false)
  const [brandId, setBrandId] = useState('')
  const [loginPage, setLoginPage] = useState<loginPageTypes>({
    title: '',
    description: '',
  })
  const [loginImage, setLoginImage] = useState<FormData | undefined>()
  const [actions, setActions] = useState<CampaignAction[]>([])
  const [actionType, setActionType] = useState<CAMPAIGN_ACTION_TYPE>()
  const [actionsImage, setActionsImage] = useState<ActionImageTypes[]>([])
  const [type, setType] = useState(CAMPAIGN_FORM_TYPE.BRAND)

  const context: FormCampaignContextTypes = {
    open,
    type,
    brandId,
    loginPage,
    loginImage,
    actions,
    actionType,
    actionsImage,
    setActions,
    setActionType,
    setActionsImage,
    setLoginPage,
    setLoginImage,
    setOpen,
    setType,
    setBrandId,
  }

  return (
    <FormCampaignContext.Provider value={context}>
      {children}
    </FormCampaignContext.Provider>
  )
}

export const useFormCampaign = () => useContext(FormCampaignContext)
