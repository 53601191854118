import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { CircularProgress } from '@material-ui/core'
import ColorPicker from 'material-ui-color-picker'

import useWheelMutation from '../../../hooks/queries/useWheel'
import RichText from '../../RichText'

interface IChooseFinishProps {
  onSubmit: (data: any) => Promise<void>
}

export type InputsFinish = {
  mainTitle: string
  frame: string
  frameStroke: string
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiInputLabel-formControl[data-shrink=true]': {
      transform: 'translate(0, 4px) scale(1)',
    },
  },
  labelControl: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
    left: 22,
    transform: 'translate(0, 40px) scale(1)',
  },
  field: {
    position: 'relative',
    marginBottom: 15,
  },
  label: {
    position: 'absolute',
    top: 32,
    right: 24,
    left: 24,
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  labelRich: {
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  labelSlider: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInputControl: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  fieldInput: {
    width: '100%',
    height: 80,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  focusError: {
    borderColor: theme.palette.error.main,
    '& .MuiInputBase-input:focus': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  // Color Picker
  inputColorPicker: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      width: '100%',
      height: 80,
      padding: '18px 22px 0',
      borderRadius: 12,
      border: '2px solid #0000',
      '&:focus': {
        borderColor: theme.palette.primary.light,
      },
      background:
        theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.hint,
      transition: 'all .2s',
      outline: 'none',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
}))

const ChooseFinish = ({ onSubmit }: IChooseFinishProps) => {
  const [frame, setFrame] = useState<string>()
  const [frameStroke, setFrameStroke] = useState<string>()
  const classes = useStyles()

  const { isLoading } = useWheelMutation()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<InputsFinish>()

  const rules = {
    mainTitle: register('mainTitle', {
      required: { value: true, message: 'Choisir le titre de la page' },
    }),
    frame: register('frame', {
      required: { value: true, message: 'Choisir la couleur de fond bordure' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    frameStroke: register('frameStroke', {
      required: { value: true, message: 'Choisir la couleur bordure' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.labelRich}>Titre de la Roue</div>
      <RichText control={control} name={rules.mainTitle.name} />
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          Couleur fond Bordure
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.frame && classes.focusError
            ),
          }}
          name={rules.frame.name}
          inputRef={rules.frame.ref}
          defaultValue={frame}
          value={frame}
          onChange={(color) => {
            setFrame(color)
            setValue('frame', color)
          }}
          onBlur={() => clearErrors('frame')}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          Bordure couleur
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.frameStroke && classes.focusError
            ),
          }}
          name={rules.frameStroke.name}
          inputRef={rules.frameStroke.ref}
          defaultValue={frameStroke}
          value={frameStroke}
          onChange={(color) => {
            setFrameStroke(color)
            setValue('frameStroke', color)
          }}
          onBlur={() => clearErrors('frameStroke')}
        />
      </div>
      <button
        type="submit"
        className={clsx(classes.btn, classes.btnBlue)}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        {isLoading ? (
          <CircularProgress
            size={20}
            style={{
              color: '#fff',
            }}
          />
        ) : (
          'Valider'
        )}
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`offer_win-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseFinish
