import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Brand } from '@airflex/lib-models'
import { useForm } from 'react-hook-form'

import { useFormCampaign } from '../../../hooks/useFormCampaign'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

type Inputs = {
  brand: string
}

interface IBrandProps {
  onSubmit: (data: any) => Promise<void>
  brands: Brand[]
}

const ChooseBrand = ({ onSubmit, brands }: IBrandProps) => {
  const classes = useStyles()
  const { setBrandId, brandId } = useFormCampaign()

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>()

  const rules = {
    brand: register('brand', {
      required: { value: true, message: 'Choisir un restaurant' },
    }),
  }

  const handleClearErrors = () => {
    clearErrors('brand')
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBrandId(event.target.value as string)
    setValue('brand', event.target.value as string)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-brand" className={classes.label}>
          Choisir le restaurant
        </InputLabel>
        <Select
          classes={{
            root: classes.fieldInput,
          }}
          labelId="select-brand"
          id="select-brand"
          value={brandId}
          name={rules.brand.name}
          inputRef={rules.brand.ref}
          onChange={handleChange}
          onFocus={() => {
            handleClearErrors()
          }}
        >
          {brands?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <button type="submit" className={classes.btn} disabled={!!errors.brand}>
        suivant
      </button>
      <div className={classes.fieldError}>
        {errors.brand && (
          <span className={classes.invalidFeed}>{errors.brand.message}</span>
        )}
      </div>
    </form>
  )
}

export default ChooseBrand
