import React from 'react'
import { Box, Divider as D, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    background: '#e5ecf4',
  },
}))

export default function Divider(props) {
  const classes = useStyles()

  return (
    <Box mr={2} ml={2}>
      <D {...props} className={classes.root} />
    </Box>
  )
}
