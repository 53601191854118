import React, { useRef, useState } from 'react'
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import clsx from 'clsx'
import PersonIcon from '@material-ui/icons/Person'
import { useAuth } from '../hooks/useAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 34,
    width: 34,
    borderRadius: '50%!important',
    boxShadow: '0 0 6px 0 rgb(71 99 136 / 25%)',
    backgroundColor: theme.palette.background.paper,
    border:
      theme.palette.type === 'dark' && '1px solid rgba(228, 228, 228, 0.4)',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    justifyContent: 'space-around',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0 0 4px 0 rgb(71 99 136 / 50%)',
    },
  },
  iconSize: {
    fontSize: 20,
    color: theme.palette.grey[400],
  },
  header: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    borderRadius: '10px 10px 0 0',
    padding: theme.spacing(3),
    flexDirection: 'column',
    width: '100%',
  },
  centered: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  item: {
    lineHeight: 1.33,
    minHeight: 46,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.4,
    color: '#fff',
    '&:hover': {
      color: '#000',
    },
  },
  menu: {
    width: 300,
    paddingTop: 0,
    paddingBottom: 0,

    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 32px)',
    },
  },
  paper: {
    boxShadow: '-2px 2px 14px 0 rgb(71 99 136 / 30%)',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      marginRight: '16px !important',
      marginLeft: '16px',
    },
  },
}))

export default function MenuProfile() {
  const { user, logout } = useAuth()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = useRef(open)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <div
          role="button"
          className={classes.root}
          onClick={handleToggle}
          onKeyDown={handleToggle}
          ref={anchorRef}
          aria-hidden="true"
        >
          <PersonIcon classes={{ root: classes.iconSize }} />
        </div>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'left bottom',
                marginRight: 30,
                marginTop: 10,
              }}
            >
              <Paper className={classes.paper}>
                <>
                  <div className={classes.header}>
                    <div className={classes.centered}>
                      <PersonIcon fontSize="large" />
                    </div>
                    <div className={classes.centered}>{user?.email}</div>
                  </div>
                  <MenuList
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.menu}
                  >
                    <MenuItem
                      onClick={logout}
                      className={clsx(classes.item, classes.centered)}
                    >
                      Me deconnecter
                    </MenuItem>
                  </MenuList>
                </>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}
