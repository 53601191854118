const route = {
  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/brands': 'Brands',
  '/campaigns': 'Campaigns',
  '/wheel': 'Wheel',
  '/statistics': 'Statistics',
}

export default route
