import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Campaign,
  TIME_MESURE,
  OFFER_STATUS,
  WheelOffer,
} from '@airflex/lib-models'
import { SubmitHandler } from 'react-hook-form'

import { ArrowBack } from '@material-ui/icons'
import StepperProgress from '../StepperProgress'

import { useFormWheel, WHEEL_FORM_TYPE } from '../../hooks/useFormWheel'

import ChooseFinish, { InputsFinish } from './wheel/ChooseFinish'
import ChooseCampaign from './wheel/ChooseCampaign'
import ChooseWheelPage from './wheel/ChooseWheelPage'
import ChooseOffer from './wheel/ChooseOffer'
import useWheelMutation from '../../hooks/queries/useWheel'
import ChooseEmail, { InputsChooseEmail } from './wheel/ChooseEmail'

const steps = Object.keys(WHEEL_FORM_TYPE).map((value) => value)

interface ICreateWheelProps {
  campaigns: Campaign[]
}

type InputCampaign = {
  campaign: string
}

type InputWheelpage = {
  title: string
  description_1: string
  description_2: string
}

const useStyles = makeStyles((theme) => ({
  prevButton: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: '8px 16px',
    fontSize: '14px',
    lineHeight: 1,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    '&:hover': {
      background: '#0d6dfc',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

const CreateWheel = ({ campaigns }: ICreateWheelProps) => {
  const [error, setError] = useState({ status: false, message: '' })
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  )
  const stepperRef: any = useRef()
  const classes = useStyles()

  const { createWheel } = useWheelMutation()

  const {
    open,
    type,
    campaignId,
    offers,
    wheelPage,
    email,
    setEmail,
    setOpen,
    setType,
    setCampaignId,
    setWheelPage,
    setOffers,
  } = useFormWheel()

  useEffect(() => {
    if (!open) {
      stepperRef.current?.handleReset()
      if (type !== WHEEL_FORM_TYPE.CAMPAIGN) {
        setType(WHEEL_FORM_TYPE.CAMPAIGN)
      }
    }
  }, [open])

  const handleStep = async () => {
    await stepperRef.current?.handleComplete()
    setType(steps[stepperRef.current?.activeStep()] as WHEEL_FORM_TYPE)
  }

  const handlePrevStep = async () => {
    await stepperRef.current?.handlePrev()
    setType(steps[stepperRef.current?.activeStep()] as WHEEL_FORM_TYPE)
  }

  const clearAll = () => {
    setCampaignId('')
    setWheelPage({
      title: '',
      description_1: '',
      description_2: '',
    })
    setOffers([])
    setOpen(false)
    setError({ status: false, message: '' })
  }

  const onSubmitCampaign: SubmitHandler<InputCampaign> = (data) => {
    const campaign = campaigns.find((c) => c._id === data.campaign)
    if (campaign) {
      setSelectedCampaign(campaign)
    }
    handleStep()
  }
  const onSubmitWheelPage: SubmitHandler<InputWheelpage> = (data) => {
    setWheelPage(data)
    handleStep()
  }

  const onSubmitOffer: SubmitHandler<WheelOffer> = (data) => {
    if (data.expirationDate) {
      const d = new Date(data.expirationDate)
      const { expirationDate, ...rest } = data
      const delay =
        rest.couldBeUsedAfter &&
        rest.couldBeUsedAfterMesure === TIME_MESURE.MINUTE
          ? rest.couldBeUsedAfter / 60
          : rest.couldBeUsedAfter

      console.log({ delay })
      const newOffers = [...offers]
      newOffers[stepperRef.current.activeStep() - 2] = {
        expirationDate: d,
        ...rest,
        code: rest.useCode ? rest.code : null,
        maxWinner: rest.useMaxWinner ? Number(rest.maxWinner) : null,
        percentage: Number(rest.percentage),
        activatedAt:
          rest.useActivatedAt && rest.activatedAt
            ? new Date(rest.activatedAt)
            : null,
        couldBeUsedAfter:
          rest.useCouldBeUsedAfter && rest.couldBeUsedAfter ? delay : null,
      }
      setOffers(newOffers)
    } else {
      const newOffers = [...offers]
      newOffers[stepperRef.current.activeStep() - 2] = {
        ...data,
        code: data.useCode ? data.code : null,
        maxWinner: data.useMaxWinner ? Number(data.maxWinner) : null,
        percentage: Number(data.percentage),
        activatedAt:
          data.useActivatedAt && data.activatedAt
            ? new Date(data.activatedAt)
            : null,
        couldBeUsedAfter:
          data.couldBeUsedAfter &&
          data.couldBeUsedAfterMesure === TIME_MESURE.MINUTE
            ? data.couldBeUsedAfter / 60
            : data.couldBeUsedAfter,
      }
      setOffers(newOffers)
    }
    handleStep()
  }

  const onSubmitEmail: SubmitHandler<InputsChooseEmail> = (data) => {
    setEmail({ ...data })
    handleStep()
  }

  const onSubmitFinish: SubmitHandler<InputsFinish> = async (data) => {
    const percentageTotal = offers
      .filter((o) => o.status === OFFER_STATUS.WIN)
      .reduce((acc, curr) => acc + curr.percentage, 0)

    const newOffers = offers.map((offer) => ({
      ...offer,
      couldBeUsedAfter:
        offer.couldBeUsedAfterMesure === 'DAY'
          ? Number(offer.couldBeUsedAfter) * 24
          : Number(offer.couldBeUsedAfter),
    }))

    const body = {
      campaign: campaignId,
      ...wheelPage,
      mainTitle: data.mainTitle,
      frame: data.frame,
      frameStroke: data.frameStroke,
      offers: newOffers,
      percentageTotal,
      status: false,
      isDeleted: false,
      ...email,
    }

    try {
      await createWheel(body)
      clearAll()
    } catch (e) {
      console.log(e)
      setError({
        status: true,
        message: 'Une erreur est survenue, veuillez recommencer',
      })
    }
  }

  return (
    <div>
      {stepperRef.current?.activeStep() > 0 && (
        <button
          type="button"
          onClick={handlePrevStep}
          className={classes.prevButton}
        >
          <ArrowBack style={{ marginRight: 8 }} />
          Précédent
        </button>
      )}
      <StepperProgress steps={steps} ref={stepperRef} />
      {type === WHEEL_FORM_TYPE.CAMPAIGN && (
        <ChooseCampaign campaigns={campaigns} onSubmit={onSubmitCampaign} />
      )}
      {type === WHEEL_FORM_TYPE.WHEEL_PAGE && (
        <ChooseWheelPage
          onSubmit={onSubmitWheelPage}
          defaultValue={wheelPage}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_1 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2550}
          defaultValue={offers[0]}
          index={0}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_2 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2580}
          defaultValue={offers[1]}
          index={1}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_3 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2610}
          defaultValue={offers[2]}
          index={2}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_4 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2640}
          defaultValue={offers[3]}
          index={3}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_5 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2670}
          defaultValue={offers[4]}
          index={4}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_6 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2700}
          defaultValue={offers[5]}
          index={5}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_7 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2730}
          defaultValue={offers[6]}
          index={6}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_8 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2760}
          defaultValue={offers[7]}
          index={7}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_9 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2790}
          defaultValue={offers[8]}
          index={8}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_10 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2820}
          defaultValue={offers[9]}
          index={9}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_11 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2850}
          defaultValue={offers[10]}
          index={10}
        />
      )}
      {type === WHEEL_FORM_TYPE.OFFER_12 && (
        <ChooseOffer
          selectedCampaign={selectedCampaign}
          onSubmit={onSubmitOffer}
          positionAngle={2880}
          defaultValue={offers[11]}
          index={11}
        />
      )}
      {type === WHEEL_FORM_TYPE.EMAIL_PAGE && (
        <ChooseEmail onSubmit={onSubmitEmail} defaultValue={email} />
      )}
      {type === WHEEL_FORM_TYPE.FINISH && (
        <ChooseFinish onSubmit={onSubmitFinish} />
      )}
      {error.status && (
        <div className={classes.fieldError}>
          <span className={classes.invalidFeed}>{error.message}</span>
        </div>
      )}
    </div>
  )
}

export default CreateWheel
