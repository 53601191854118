import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Campaign } from '@airflex/lib-models'
import { useForm } from 'react-hook-form'

import { useFormWheel } from '../../../hooks/useFormWheel'
import wheelService from '../../../services/wheel.service'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

type Inputs = {
  campaign: string
}

interface ICampaignProps {
  onSubmit: (data: any) => Promise<void>
  campaigns: Campaign[]
}

const ChooseCampaign = ({ onSubmit, campaigns }: ICampaignProps) => {
  const classes = useStyles()
  const { setCampaignId, campaignId } = useFormWheel()

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>()

  const isWheelExist = async () => {
    try {
      const { data } = await wheelService.getWheelByCampaignId(campaignId)
      if (data && !data.isDeleted) {
        return 'Vous ne pouvez pas créer 2 Jeux sur la même campagne'
      }

      return true
    } catch (e) {
      console.log(e)
      return "Verifiez que vous n'avez pas deja un jeu de créer avec cette Campaigne"
    }
  }

  const rules = {
    campaign: register('campaign', {
      required: { value: true, message: 'Choisir une campagne' },
      validate: {
        isWheelExist,
      },
    }),
  }

  const handleClearErrors = () => {
    clearErrors('campaign')
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCampaignId(event.target.value as string)
    setValue('campaign', event.target.value as string)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-campaign" className={classes.label}>
          Choisir la campagne
        </InputLabel>
        <Select
          classes={{
            root: classes.fieldInput,
          }}
          labelId="select-campaign"
          id="select-campaign"
          value={campaignId}
          name={rules.campaign.name}
          inputRef={rules.campaign.ref}
          onChange={handleChange}
          onClick={handleClearErrors}
        >
          {campaigns?.map(
            (item) =>
              !item.isDeleted && (
                <MenuItem key={item._id} value={item._id}>
                  <strong>{item.brandInfo?.name}</strong>&nbsp;(#
                  {item._id?.slice(16, 24)})
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
      <button
        type="submit"
        className={classes.btn}
        disabled={!!errors.campaign}
      >
        suivant
      </button>
      <div className={classes.fieldError}>
        {errors.campaign && (
          <span className={classes.invalidFeed}>{errors.campaign.message}</span>
        )}
      </div>
    </form>
  )
}

export default ChooseCampaign
