import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from '@tinymce/tinymce-react'
import { useController } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
  },
}))

const RichText = ({ control, name, defaultValue = '' }) => {
  const classes = useStyles()
  const {
    field: { onChange },
  } = useController({ control, name })

  const editorRef = useRef(null)

  const handleEditorChange = (edit) => {
    onChange(edit)
  }

  return (
    <div className={classes.root}>
      <Editor
        apiKey="zv8h8ounqpn70af0fbw7bje0p42folg9lg4uhpfwymb5ggyh"
        onInit={(_, editor) => {
          editorRef.current = editor
        }}
        onEditorChange={handleEditorChange}
        init={{
          height: 250,
          menubar: false,
          branding: false,
          statusbar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | ',
          content_style:
            'body { font-family:Nunito,Helvetica Neue,sans-serif; font-size:14px; } ',
        }}
        initialValue={defaultValue}
      />
    </div>
  )
}

export default RichText
