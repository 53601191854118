import { BrandPrivacyPolicy, BrandSettlement } from '@airflex/lib-models'
import axios from 'axios'

const urls = {
  createSettlement: '/settlement',
  getSettlement: '/settlement/:brandId',
  updateSettlement: '/settlement',
  createPrivacyPolicy: '/privacy-policy',
  getPrivacyPolicy: '/privacy-policy/:brandId',
  updatePrivacyPolicy: '/privacy-policy',
}

const createSettlement = (createBrandSettlement: BrandSettlement) =>
  axios.post(urls.createSettlement, createBrandSettlement)

const getSettlementByBrandId = (brandId: string): Promise<BrandSettlement> =>
  axios
    .get(urls.getSettlement, { urlParams: { brandId } })
    .then(({ data }) => data)

const updateSettlement = (updateSettlementBody: BrandSettlement) =>
  axios.put(urls.updateSettlement, updateSettlementBody)

const createPrivacyPolicy = (createBrandPrivacyPolicy: BrandPrivacyPolicy) =>
  axios.post(urls.createPrivacyPolicy, createBrandPrivacyPolicy)

const getPrivacyPolicyByBrandId = (
  brandId: string
): Promise<BrandPrivacyPolicy> =>
  axios
    .get(urls.getPrivacyPolicy, { urlParams: { brandId } })
    .then(({ data }) => data)

const updatePrivacyPolicy = (updatePrivacyPolicyBody: BrandPrivacyPolicy) =>
  axios.put(urls.updatePrivacyPolicy, updatePrivacyPolicyBody)

const brandLegalResourcesService = {
  createSettlement,
  getSettlementByBrandId,
  updateSettlement,
  createPrivacyPolicy,
  getPrivacyPolicyByBrandId,
  updatePrivacyPolicy,
}

export default brandLegalResourcesService
