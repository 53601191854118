/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useState } from 'react'

const initialState = {
  open: false,
}

const OpenDrawerContext = createContext({
  ...initialState,
  setOpen: (open: boolean) => {},
})

export interface IOpenDrawerProviderProps {
  children: React.ReactNode
}

export default function OpenDrawerProvider({
  children,
}: IOpenDrawerProviderProps) {
  const [open, setOpen] = useState(true)

  const context = {
    open,
    setOpen,
  }

  return (
    <OpenDrawerContext.Provider value={context}>
      {children}
    </OpenDrawerContext.Provider>
  )
}

export const useOpenDrawer = () => useContext(OpenDrawerContext)
