import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'

import {
  CampaignAction,
  CampaignNoAction,
  CAMPAIGN_ACTION_TYPE,
  TIME_MESURE,
} from '@airflex/lib-models'
import { SubmitHandler } from 'react-hook-form'
import clsx from 'clsx'

import ChooseActionSocial from './ChooseActionSocial'
import ChooseActionSms from './ChooseActionSms'
import ChooseActionGoogle from './ChooseActionGoogle'
import ChooseActionMacdo from './ChooseActionMacdo'

import { useFormCampaign } from '../../../hooks/useFormCampaign'

type MultiplePlayInfo = {
  multiplePlay: boolean
  delay: number
  mesure: string
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '8px 22px',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
    background: 'transparent',
    color: 'inherit',
    '&:hover:not(&:disabled)': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  multiplePlayZone: {
    background: 'rgba(228, 228, 228, 0.3)',
    padding: '16px',
    marginBottom: '16px',
    borderRadius: '8px',
    border: '1px solid rgba(228, 228, 228, 1)',
  },
}))

const actionsType = Object.keys(CAMPAIGN_ACTION_TYPE).map((value) => value)

interface IChooseActionProps {
  onSubmit: SubmitHandler<CampaignAction>
  onSubmitAdd: (data: any, setError: any) => void
  onSubmitNoAction: SubmitHandler<CampaignNoAction>
  multiplePlayInfo: MultiplePlayInfo
  handleMultiplePlayInfo: (
    data: any,
    key: 'multiplePlay' | 'delay' | 'mesure'
  ) => void
  actions: CampaignAction[]
}

const ChooseAction = ({
  onSubmit,
  onSubmitAdd,
  onSubmitNoAction,
  multiplePlayInfo,
  handleMultiplePlayInfo,
  actions,
}: IChooseActionProps) => {
  const { actionType, setActionType } = useFormCampaign()
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActionType(event.target.value as CAMPAIGN_ACTION_TYPE)
  }

  return (
    <div>
      <div className={classes.multiplePlayZone}>
        <FormControlLabel
          control={
            <input
              type="checkbox"
              style={{ margin: '0 16px 0 0' }}
              checked={multiplePlayInfo.multiplePlay}
              onChange={() =>
                handleMultiplePlayInfo(
                  !multiplePlayInfo.multiplePlay,
                  'multiplePlay'
                )
              }
            />
          }
          className={classes.label}
          label="Les utilisateurs peuveut jouer plusieurs fois"
          style={{
            marginBottom: !multiplePlayInfo.multiplePlay ? 0 : 16,
            padding: '8px 16px',
          }}
        />
        {multiplePlayInfo.multiplePlay && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                classes={{
                  root: classes.formControl,
                }}
                inputProps={{
                  className: classes.fieldInput,
                  min: 1,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                label="Délais"
                type="number"
                defaultValue={multiplePlayInfo.delay}
                onChange={(e) =>
                  handleMultiplePlayInfo(e.target.value, 'delay')
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="select-type-action" className={classes.label}>
                  Temps
                </InputLabel>
                <Select
                  classes={{
                    root: classes.fieldInput,
                  }}
                  labelId="select-type-action"
                  id="select-type-action"
                  defaultValue={multiplePlayInfo.mesure}
                  onChange={(e) =>
                    handleMultiplePlayInfo(e.target.value, 'mesure')
                  }
                >
                  <MenuItem key="time-select-0" value={TIME_MESURE.MINUTE}>
                    Minutes
                  </MenuItem>
                  <MenuItem key="time-select-1" value={TIME_MESURE.HOUR}>
                    Heures
                  </MenuItem>
                  <MenuItem key="time-select-2" value={TIME_MESURE.DAY}>
                    Jours
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </div>
      {actions.length > 0 && (
        <div
          style={{
            marginBottom: 16,
            padding: '16px 0',
            borderTop: '1px solid rgb(228, 228, 228)',
            borderBottom: '1px solid rgb(228, 228, 228)',
          }}
        >
          <Typography variant="h4" style={{ marginBottom: 8 }}>
            Actions déjà paramétrées :
          </Typography>
          {actions.map((action, index) => (
            <Typography variant="h5" key={`action-${action.type}`}>
              {index + 1}.&nbsp;
              {action.type === CAMPAIGN_ACTION_TYPE.NOTHING
                ? 'AUCUNE ACTION'
                : action.type}
            </Typography>
          ))}
        </div>
      )}
      <FormControl className={classes.formControl}>
        <InputLabel id="select-type-action" className={classes.label}>
          Choisir le type de l&apos;action
        </InputLabel>
        <Select
          classes={{
            root: classes.fieldInput,
          }}
          labelId="select-type-action"
          id="select-type-action"
          value={actionType || ''}
          onChange={handleChange}
        >
          {actionsType.map((item) => (
            <MenuItem key={`action-select-${item}`} value={item}>
              {item === CAMPAIGN_ACTION_TYPE.NOTHING ? 'AUCUNE ACTION' : item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {actionType === CAMPAIGN_ACTION_TYPE.SOCIAL && (
        <ChooseActionSocial onSubmit={onSubmit} onSubmitAdd={onSubmitAdd} />
      )}
      {actionType === CAMPAIGN_ACTION_TYPE.SMS && (
        <ChooseActionSms onSubmit={onSubmit} onSubmitAdd={onSubmitAdd} />
      )}
      {actionType === CAMPAIGN_ACTION_TYPE.GOOGLE && (
        <ChooseActionGoogle onSubmit={onSubmit} onSubmitAdd={onSubmitAdd} />
      )}
      {actionType === CAMPAIGN_ACTION_TYPE.MACDO && (
        <ChooseActionMacdo onSubmit={onSubmit} onSubmitAdd={onSubmitAdd} />
      )}
      {actionType === CAMPAIGN_ACTION_TYPE.NOTHING && (
        <>
          <button
            type="button"
            className={clsx(classes.btn, classes.btnBorder)}
            onClick={() => onSubmitAdd({ type: actionType }, {})}
          >
            Ajouter une action
          </button>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmitNoAction({ type: CAMPAIGN_ACTION_TYPE.NOTHING })
            }}
          >
            <button
              type="submit"
              className={clsx(classes.btn, classes.btnBlue)}
            >
              Suivant
            </button>
          </form>
        </>
      )}
    </div>
  )
}

export default ChooseAction
