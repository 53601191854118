import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

import Layout from '../../../components/Layout'
import CardWidget from '../../../components/CardWidget'
import Card from '../../../components/Card'
import CreateWheel from '../../../components/form/CreateWheel'

import { WHEEL_FORM_TYPE, useFormWheel } from '../../../hooks/useFormWheel'
import { getCampaigns } from '../../../hooks/queries/useCampaign'
import { getWheels } from '../../../hooks/queries/useWheel'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    flexGrow: 1,
    padding: '0 48px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px 32px',
      width: '100%',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  content: {
    flexGrow: 1,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 48,
      padding: 0,
    },
  },
  contentTitle: {
    fontSize: 18,
    lineHeight: 1.333333,
    fontWeight: 500,
    marginBottom: 12,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  btns: {
    display: 'flex',
    position: 'absolute',
    top: 32,
    right: 32,
    [theme.breakpoints.down('xs')]: {
      position: 'initial',
      justifyContent: 'center',
    },
  },
  btn: {
    minWidth: 114,
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnIcon: {
    fontSize: 16,
    fill: '#fff',
    marginRight: 8,
    verticalAlign: 'middle',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.333333,
    marginBottom: 32,
    color: theme.palette.text.hint,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  sidebar: {
    flexShrink: 0,
    width: 364,
    marginRight: ({ open }) => (open ? 0 : -364),
    padding: '0 64px 64px 0',
    transition: 'margin .2s',

    [theme.breakpoints.down('1339')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: 0,
      overflow: 'auto',
      padding: 20,
      background: '#1b1d21e6',
      opacity: ({ open }) => (open ? 1 : 0),
      visibility: ({ open }) => (open ? 'visible' : 'hidden'),
      transition: 'all .2s',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      borderRadius: 0,
    },
  },
  warp: {
    position: 'relative',

    [theme.breakpoints.down('1339')]: {
      width: '100%',
      maxWidth: 630,
      margin: 'auto',
      padding: 32,
      borderRadius: 24,
      background: theme.palette.background.paper,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px',
      borderRadius: 0,
    },
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 0,
    width: 20,
    height: 20,

    [theme.breakpoints.down('1339')]: {
      top: 26,
      right: 24,
      width: 48,
      height: 48,
      borderRadius: '50%',
      boxShadow: '0 3px 10px rgb(0 0 0 / 10%)',
    },
    [theme.breakpoints.down('xs')]: {
      top: 22,
      right: 10,
      boxShadow: 'none',
    },
  },
  btnIconClose: {
    fontSize: 26,
    verticalAlign: 'middle',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    paddingRight: 30,

    [theme.breakpoints.down('1339')]: {
      paddingRight: 50,
    },
  },
  prev: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 48,
    height: 48,
    marginRight: 16,
    borderRadius: '50%',
    background: theme.palette.secondary.light,
  },
  btnPrev: {
    color: '#fff',
    fontSize: 24,
  },
  details: {
    flexGrow: 1,
  },
  info: {
    marginBottom: 4,
    fontSize: 18,
    lineHeight: 1.33333,
    fontWeight: 500,
  },
  desc: {
    fontSize: 13,
    lineHeight: 1.23077,
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
}))

const stepsLabels = [
  { type: 'CAMPAIGN', label: 'Choisir une Campagne' },
  { type: 'WHEEL_PAGE', label: 'Page du Coupon' },
  { type: 'OFFER_1', label: 'Quartier 1' },
  { type: 'OFFER_2', label: 'Quartier 2' },
  { type: 'OFFER_3', label: 'Quartier 3' },
  { type: 'OFFER_4', label: 'Quartier 4' },
  { type: 'OFFER_5', label: 'Quartier 5' },
  { type: 'OFFER_6', label: 'Quartier 6' },
  { type: 'OFFER_7', label: 'Quartier 7' },
  { type: 'OFFER_8', label: 'Quartier 8' },
  { type: 'OFFER_9', label: 'Quartier 9' },
  { type: 'OFFER_10', label: 'Quartier 10' },
  { type: 'OFFER_11', label: 'Quartier 11' },
  { type: 'OFFER_12', label: 'Quartier 12' },
  { type: 'EMAIL_PAGE', label: 'Email' },
  { type: 'FINISH', label: 'Page de la Roue' },
]

const WheelScreen = () => {
  const { open, setOpen, setType, type } = useFormWheel()

  const classes = useStyles({ open })

  const { data: campaigns, isLoading } = getCampaigns()

  const { data: wheels, isLoading: isLoadingWheels } = getWheels()

  const handleCreateWheel = () => {
    setType(WHEEL_FORM_TYPE.CAMPAIGN)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.container}>
          <CardWidget>
            {campaigns?.data && campaigns.data.length > 0 ? (
              <>
                <div className={classes.row}>
                  <div className={classes.content}>
                    <div className={classes.contentTitle}>
                      1. Personnaliser le jeu
                      <br />
                      2. Créer les offres du jeu
                    </div>
                  </div>
                </div>
                <div className={classes.btns}>
                  <button
                    type="button"
                    className={classes.btn}
                    onClick={handleCreateWheel}
                    disabled={open}
                  >
                    <CreateOutlinedIcon className={classes.btnIcon} />
                    Créer
                  </button>
                </div>
              </>
            ) : (
              <div className={classes.row}>
                <div className={classes.content}>
                  <div className={classes.contentTitle}>
                    Commencer par créer une Campagne
                  </div>
                </div>
              </div>
            )}
          </CardWidget>
          {wheels?.data?.length > 0 && campaigns && !isLoadingWheels && (
            <div className="list_wheels">
              <div className={classes.title}>Jeux</div>
              {wheels.data.map((wheel) => (
                <Card
                  key={`card-wheel-${wheel._id}`}
                  wheel={wheel}
                  campaign={campaigns.data.find(
                    (e) => e._id === wheel.campaign
                  )}
                />
              ))}
            </div>
          )}
        </div>
        <aside className={classes.sidebar}>
          {isLoading ? (
            <div className={classes.warp}>
              <CircularProgress
                size={60}
                style={{
                  color: '#eee',
                }}
              />
            </div>
          ) : (
            <div className={classes.warp}>
              <button
                type="button"
                className={classes.close}
                onClick={handleClose}
              >
                <CloseOutlinedIcon className={classes.btnIconClose} />
              </button>
              <div className={classes.head}>
                <div className={classes.prev}>
                  <CreateOutlinedIcon className={classes.btnPrev} />
                </div>
                <div className={classes.details}>
                  <div className={classes.info}>Nouveau Jeu</div>
                  {stepsLabels
                    .map((item) => {
                      if (item.type === type) {
                        return (
                          <div key={`desc-${type}`} className={classes.desc}>
                            {item.label}
                          </div>
                        )
                      }
                      return undefined
                    })
                    .filter((value) => value)}
                </div>
              </div>
              <CreateWheel campaigns={campaigns.data} />
            </div>
          )}
        </aside>
      </div>
    </Layout>
  )
}

export default WheelScreen
