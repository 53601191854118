import { Campaign } from '@airflex/lib-models'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import campaignsService from '../../services/campaign.service'

const useCampaignMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: createCampaign, isLoading: isLoadingCreateCampaign } =
    useMutation('createCampaign', campaignsService.createCampaign, {
      onSuccess: () => queryClient.invalidateQueries('getCampaigns'),
    })

  const {
    mutateAsync: updateCampaignImage,
    isLoading: isLoadingUpdateCampaignImage,
  } = useMutation('updateCampaignImage', campaignsService.updateImageCampaign, {
    onSuccess: () => queryClient.invalidateQueries('getCampaigns'),
  })

  const {
    mutateAsync: updateCampaignImageAction,
    isLoading: isLoadingUpdateCampaignImageAction,
  } = useMutation(
    'updateCampaignImageAction',
    campaignsService.updateImageActionCampaign,
    {
      onSuccess: () => queryClient.invalidateQueries('getCampaigns'),
    }
  )

  const {
    mutateAsync: softDeleteCampaign,
    isLoading: isLoadingSoftDeleteCampaign,
  } = useMutation('softDeleteCampaign', campaignsService.softDeleteCampaign, {
    onSuccess: () => queryClient.invalidateQueries('getCampaigns'),
  })

  const { mutateAsync: deleteCampaign, isLoading: isLoadingDeleteCampaign } =
    useMutation('deleteCampaign', campaignsService.deleteCampaign, {
      onSuccess: () => queryClient.invalidateQueries('getCampaigns'),
    })

  const isLoading =
    isLoadingCreateCampaign ||
    isLoadingUpdateCampaignImage ||
    isLoadingUpdateCampaignImageAction ||
    isLoadingSoftDeleteCampaign ||
    isLoadingDeleteCampaign

  return {
    createCampaign,
    updateCampaignImage,
    updateCampaignImageAction,
    softDeleteCampaign,
    deleteCampaign,
    isLoading,
  }
}

export const getCampaigns = () =>
  useQuery('getCampaigns', campaignsService.getCampaigns)

export const getCampaignById = (id: string) =>
  useQuery<Campaign, Error>(
    ['getBrandGraphicChart', id],
    () => campaignsService.getCampaignById(id),
    {
      enabled: false,
    }
  )

export default useCampaignMutation
