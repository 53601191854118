/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
// outline
// size
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: '0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%)',
      filter: 'lightness(80%)',
    },
    '&:focus': {
      outline: 'none',
    },
    display: 'inline-block',
    color: '#525f7f',
    textAlign: 'center',
    verticalAlign: 'middle',
    userSelect: 'none',
    backgroundColor: 'transparent',
    padding: '.625rem 1.25rem',
    lineHeight: '1.5',
    transition: 'all .5s ease',
    position: 'relative',
    willChange: 'transform',
    cursor: 'pointer',
    borderRadius: '17px!important',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.5,
    height: 43,
  },
  fullWidth: {
    width: '100%',
  },
  secondary: {
    color: theme.palette.primary.main,
    backgroundColor: '#f4f5f7',
    borderColor: '#f4f5f7',
    boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
  },
  small: {
    padding: '.25rem .5rem',
    lineHeight: '1.5',
    borderRadius: '.25rem',
    fontSize: '.75rem',
  },
  medium: {},
  large: {
    padding: '.875rem 1rem',
    fontSize: '.875rem',
    lineHeight: '1.5',
    borderRadius: '.3rem',
  },
  block: {
    width: '100%',
    display: 'block',
  },
  rounded: {
    borderRadius: '50px!important',
  },
  loading: {
    width: 43,
    paddingRight: '0!important',
    paddingLeft: '0!important',
    margin: 'auto',
  },
  successContained: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.success.dark} !important`,
    },
  },
  warningContained: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.warning.dark} !important`,
    },
  },
  errorContained: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.error.dark} !important`,
    },
  },
  infoContained: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.info.dark} !important`,
    },
  },
  primaryContained: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
  },
  secondaryContained: {
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.dark} !important`,
    },
  },
  successOutlined: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.success.main} !important`,
    },
  },
  warningOutlined: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.warning.main} !important`,
    },
  },
  errorOutlined: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.error.main} !important`,
    },
  },
  infoOutlined: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.info.main} !important`,
    },
  },
  primaryOutlined: {
    '&:hover': {
      color: '#fff !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  secondaryOutlined: {
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
}))
const Button = ({
  color = 'primary',
  size = 'medium',
  variant,
  style,
  block,
  rounded,
  fullWidth,
  children,
  className,
  loading,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <button
        type="button"
        {...props}
        className={clsx(
          loading && classes.loading,
          classes.button,
          classes[size],
          classes[color],
          block && classes.block,
          rounded && classes.rounded,
          fullWidth && classes.fullWidth,
          variant === 'outlined'
            ? classes[`${color}Outlined`]
            : classes[`${color}Contained`]
        )}
        style={{
          backgroundColor:
            variant === 'outlined' ? '#fff' : theme.palette[color].main,
          color:
            color !== 'secondary'
              ? variant === 'outlined'
                ? theme.palette[color].main
                : '#fff'
              : theme.palette.primary.main,
          border: `1px solid ${
            variant === 'outlined' ? theme.palette[color].main : 'transparent'
          }`,
          ...style,
        }}
      >
        {loading ? (
          <CircularProgress
            size={20}
            style={{
              color:
                color !== 'secondary'
                  ? variant === 'outlined'
                    ? theme.palette[color].main
                    : '#fff'
                  : theme.palette.primary.main,
            }}
          />
        ) : (
          children
        )}
      </button>
    </div>
  )
}

export default Button
