import React from 'react'
import {
  HomeOutlined,
  // Accessibility,
  DashboardOutlined,
  ViewListOutlined,
  GamesOutlined,
  TrendingUp,
} from '@material-ui/icons'
// import { Outlet } from 'react-router-dom'
import SignInScreen from '../views/screens/signInScreen'
import DashboardScreen from '../views/screens/dashboardScreen'
import routeTypes from './enums/routeTypes'
import BrandsScreen from '../views/screens/brandsScreen'
import CampaignScreen from '../views/screens/campaignScreen'
import WheelScreen from '../views/screens/wheelScreen'
import StatisticsScreen from '../views/screens/statisticsScreen'

// no nested routes for path "/"
const routes = [
  {
    name: 'Sign In',
    path: '/',
    type: routeTypes.PUBLIC,
    hideMenu: true,
    element: <SignInScreen />,
    roles: [],
    children: [],
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    type: routeTypes.PRIVATE,
    element: <DashboardScreen />,
    icon: DashboardOutlined,
    menu: true,
    roles: [],
    children: [],
  },
  {
    name: 'Brands',
    path: '/brands',
    type: routeTypes.PRIVATE,
    element: <BrandsScreen />,
    icon: HomeOutlined,
    menu: true,
    roles: [],
    children: [],
  },
  {
    name: 'Campaign',
    path: '/campaigns',
    type: routeTypes.PRIVATE,
    element: <CampaignScreen />,
    icon: ViewListOutlined,
    menu: true,
    roles: [],
    children: [],
  },
  {
    name: 'Wheel',
    path: '/wheel',
    type: routeTypes.PRIVATE,
    element: <WheelScreen />,
    icon: GamesOutlined,
    menu: true,
    roles: [],
    children: [],
  },
  {
    name: 'Statistics',
    path: '/statistics',
    type: routeTypes.PRIVATE,
    element: <StatisticsScreen />,
    icon: TrendingUp,
    menu: true,
    roles: [],
    children: [],
  },
]

export default routes
