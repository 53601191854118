const route = {
  '/': 'Accueil',
  '/dashboard': 'Tableau de bord',
  '/brands': 'Restaurants',
  '/campaigns': 'Campagne',
  '/wheel': 'Roue',
  '/statistics': 'Statistiques',
}

export default route
