import { WheelOffer } from '@airflex/lib-models'
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from 'react'

export enum WHEEL_FORM_TYPE {
  CAMPAIGN = 'CAMPAIGN',
  WHEEL_PAGE = 'WHEEL_PAGE',
  OFFER_1 = 'OFFER_1',
  OFFER_2 = 'OFFER_2',
  OFFER_3 = 'OFFER_3',
  OFFER_4 = 'OFFER_4',
  OFFER_5 = 'OFFER_5',
  OFFER_6 = 'OFFER_6',
  OFFER_7 = 'OFFER_7',
  OFFER_8 = 'OFFER_8',
  OFFER_9 = 'OFFER_9',
  OFFER_10 = 'OFFER_10',
  OFFER_11 = 'OFFER_11',
  OFFER_12 = 'OFFER_12',
  EMAIL_PAGE = 'EMAIL_PAGE',
  FINISH = 'FINISH',
}

export type wheelPageTypes = {
  title: string
  description_1: string
  description_2: string
}

export type emailTypes = {
  titleEmail: string
  contentEmail: string
}

export type FormWheelContextTypes = {
  open: boolean
  type: WHEEL_FORM_TYPE
  campaignId: string
  wheelPage: wheelPageTypes
  offers: WheelOffer[]
  email: emailTypes
  setEmail: Dispatch<emailTypes>
  setOffers: Dispatch<WheelOffer[]>
  setWheelPage: Dispatch<wheelPageTypes>
  setCampaignId: Dispatch<string>
  setOpen: Dispatch<boolean>
  setType: Dispatch<WHEEL_FORM_TYPE>
}

const FormWheelContext = createContext<FormWheelContextTypes>(
  {} as FormWheelContextTypes
)

export interface IFormWheelProviderProps {
  children: ReactNode
}

export default function FormWheelProvider({
  children,
}: IFormWheelProviderProps) {
  const [open, setOpen] = useState(false)
  const [campaignId, setCampaignId] = useState('')
  const [email, setEmail] = useState<emailTypes>({
    titleEmail: '',
    contentEmail: '',
  })
  const [wheelPage, setWheelPage] = useState<wheelPageTypes>({
    title: '',
    description_1: '',
    description_2: '',
  })
  const [offers, setOffers] = useState<WheelOffer[]>([])
  const [type, setType] = useState(WHEEL_FORM_TYPE.CAMPAIGN)

  const context: FormWheelContextTypes = {
    open,
    type,
    campaignId,
    wheelPage,
    offers,
    email,
    setEmail,
    setCampaignId,
    setWheelPage,
    setOffers,
    setOpen,
    setType,
  }

  return (
    <FormWheelContext.Provider value={context}>
      {children}
    </FormWheelContext.Provider>
  )
}

export const useFormWheel = () => useContext(FormWheelContext)
