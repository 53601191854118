import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  IconButton,
  makeStyles,
  ListItem as LI,
  Theme,
} from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@material-ui/icons/Menu'

import MenuProfile from './MenuProfile'
import { useOpenDrawer } from '../res/context/openDrawer'

import { RouteInfoTypes } from '../res/types'

export interface IHeaderProps {
  routeInfo: RouteInfoTypes
}

export type HeaderStylesTypes = {
  open?: boolean
}

const useStyles = makeStyles<Theme, HeaderStylesTypes>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '48px 64px 36px',
    zIndex: 10,

    [theme.breakpoints.down('lg')]: {
      padding: '48px 48px 36px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '36px 64px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  menuButton: {
    display: 'none',
    marginRight: 'auto',
    paddingLeft: 0,

    '&:hover': {
      background: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      visibility: ({ open }) => (open ? 'hidden' : 'visible'),
    },
  },
  menuIcon: {
    fontSize: 24,
    color: theme.palette.grey[400],
  },
  border: {
    '&::before': {
      display: 'block',
      content: "''",
      position: 'absolute',
      top: 0,
      right: 425,
      bottom: 0,
      width: 1,
      borderLeft: `1px solid ${
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[100]
      }`,

      [theme.breakpoints.down(1419)]: {
        display: 'none',
      },
    },
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  /**
   * Nav.
   */
  nav: {
    flexShrink: 0,
    display: 'flex',
    marginRight: 'auto',
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 34,
    padding: '0 20px',
    borderRadius: 12,
    whiteSpace: 'nowrap',
    fontWeight: 600,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontSize: 14,
    transition: 'all 0.2s',

    '&:hover': {
      backgroundColor: theme.palette.primary.light + 20,
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.grey[200]}4d`,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.light + 20,
    },

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

function Header({ routeInfo }: IHeaderProps) {
  const { t } = useTranslation(['route'])
  const { open, setOpen } = useOpenDrawer()

  const classes = useStyles({ open })

  const handleOpen = () => {
    setOpen(true)
    document.body.classList.add('no-scroll')
  }

  return (
    <header
      className={clsx(
        classes.root,
        routeInfo.firstParent?.border && classes.border
      )}
    >
      <IconButton className={classes.menuButton} onClick={handleOpen}>
        <MenuIcon classes={{ root: classes.menuIcon }} />
      </IconButton>
      <div className={classes.nav}>
        {routeInfo.depth < 2 &&
          routeInfo.firstParent?.children?.map(({ name, path }) => (
            <LI
              key={`header-navItem-${name}`}
              classes={{ root: classes.navItem }}
              component={NavLink}
              to={path}
              selected={routeInfo?.route?.name === name}
              color={routeInfo.color}
            >
              {t(`route:${path}`)}
            </LI>
          ))}
      </div>
      <div className={classes.group}>
        <MenuProfile />
      </div>
    </header>
  )
}

export default Header
