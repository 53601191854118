import { WheelOfferEmail } from '@airflex/lib-models'
import axios from 'axios'

const urls = {
  sendEmail: '/wheel-email/resend-email',
}

const createEmail = ({
  customerId,
  data,
}: {
  customerId: string
  data: WheelOfferEmail
}) => axios.post(`${urls.sendEmail}/${customerId}`, data)

const wheelEmailService = {
  createEmail,
}

export default wheelEmailService
