import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import {
  ListItemText,
  ClickAwayListener,
  useMediaQuery,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useOpenDrawer } from '../res/context/openDrawer'
import { RouteTypes } from '../res/types'

export type RecursiveStyleTypes = {
  color?: string
  open: boolean
}

const useStyles = makeStyles<Theme, RecursiveStyleTypes>((theme: Theme) => ({
  root: {
    height: 'auto',
    width: '100%',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  rootItem: {
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label,&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
      {
        backgroundColor: 'transparent',
      },

    '&.Mui-expanded > .MuiTreeItem-content > .MuiTreeItem-label a.active': {
      borderRadius: '12px 12px 0 0',
    },

    '&.Mui-expanded > .MuiTreeItem-content > .MuiTreeItem-label a:not(a.active)':
      {
        background:
          theme.palette.type === 'light' ? theme.palette.grey[600] : '#191b20',
        borderRadius: '12px 12px 0 0',
      },
  },
  contentItem: {
    position: 'relative',
    flexDirection: 'row-reverse',
  },
  iconContainer: {
    display: ({ open }) => (open ? 'flex' : 'none'),
    position: 'absolute',
    color: theme.palette.text.hint,
    paddingRight: 20,
    zIndex: 5,
  },
  labelItem: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  groupItem: {
    margin: 0,
    paddingLeft: 16,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#191b20',
    borderRadius: '0 0 12px 12px',

    '& a': {
      height: 40,
      padding: '0 16px',
      borderRadius: 8,
    },
  },
  listItemRoot: {
    padding: 0,
  },
  NavlinkRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: 56,
    padding: '0 20px',
    borderRadius: 12,
    color: theme.palette.grey[400],
    textDecoration: 'none',
    transition: 'all .2s',

    '&.active': {
      background: ({ color }) => `${color || theme.palette.primary.main}4d`,
      color: ({ color }) => color || theme.palette.primary.main,
    },
    '&:hover': {
      background:
        theme.palette.type === 'light' ? theme.palette.grey[600] : '#191b20',
      color: ({ color }) => color || theme.palette.primary.main,
    },
  },
  listItemText: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: 14,
  },
  listItemIcon: {
    fontSize: 20,
  },
}))

export interface IRecursiveTreeView {
  route: RouteTypes
  indexNode: number
}

export default function RecursiveTreeView({
  route,
  indexNode,
}: IRecursiveTreeView) {
  const { t } = useTranslation(['route'])
  const { open } = useOpenDrawer()

  const { color, icon: Icon } = route
  const classes = useStyles({ color, open })
  const theme = useTheme()
  const mdMatches = useMediaQuery(theme.breakpoints.down('md'))

  const renderTree = (nodes: RouteTypes | undefined) =>
    nodes && (
      <TreeItem
        classes={{
          root: classes.rootItem,
          content: classes.contentItem,
          iconContainer: classes.iconContainer,
          label: classes.labelItem,
          group: classes.groupItem,
        }}
        key={`${indexNode}`}
        nodeId={`${indexNode}`}
        label={
          <div className={classes.listItemRoot}>
            <NavLink className={classes.NavlinkRoot} to={nodes.path} end>
              {Icon && <Icon className={classes.listItemIcon} />}
              {open && (
                <ListItemText
                  classes={{
                    primary: classes.listItemText,
                  }}
                  primary={t(`route:${nodes.path}`)}
                />
              )}
            </NavLink>
          </div>
        }
      >
        {/* {mdMatches && Array.isArray(nodes.children)
          ? nodes.children.map(
              (node: RouteTypes) => node.menu && renderTree(node)
            )
          : null} */}
        {mdMatches &&
          nodes.children.length > 0 &&
          nodes.children.map((node: RouteTypes, index) => (
            <TreeItem
              classes={{
                root: classes.rootItem,
                content: classes.contentItem,
                iconContainer: classes.iconContainer,
                label: classes.labelItem,
                group: classes.groupItem,
              }}
              key={`${indexNode + index + 1}`}
              nodeId={`${indexNode + index + 1}`}
              label={
                <div className={classes.listItemRoot}>
                  <NavLink className={classes.NavlinkRoot} to={node.path} end>
                    {Icon && <Icon className={classes.listItemIcon} />}
                    {open && (
                      <ListItemText
                        classes={{
                          primary: classes.listItemText,
                        }}
                        primary={t(`route:${node.path}`)}
                      />
                    )}
                  </NavLink>
                </div>
              }
            />
          ))}
      </TreeItem>
    )

  const [expanded, setExpanded] = React.useState<string[]>([])
  const [selected, setSelected] = React.useState<string[]>([])

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds)
  }

  const handleReset = () => {
    setExpanded(['0'])
    setSelected(['0'])
  }

  return (
    <ClickAwayListener onClickAway={handleReset}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {renderTree(route)}
      </TreeView>
    </ClickAwayListener>
  )
}
