import React from 'react'
import { makeStyles } from '@material-ui/core'

import CampaignStats from '../../../components/statistics/CampaignStats'
import Layout from '../../../components/Layout'
import { getCampaigns } from '../../../hooks/queries/useCampaign'

const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.333333,
    marginBottom: 48,
    color: theme.palette.text.hint,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: 0,
    },
  },
}))

const StatisticsScreen = () => {
  const classes = useStyle()

  const { data: campaigns } = getCampaigns()

  return (
    <Layout container>
      <div className={classes.title}>Statistiques</div>
      <div>
        {campaigns?.data.map((campaign) => (
          <CampaignStats campaign={campaign} />
        ))}
      </div>
    </Layout>
  )
}

export default StatisticsScreen
