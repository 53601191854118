import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import useBrandLegalResourcesMutation from '@hooks/queries/useBrandLegalResources'
import { useFormBrand } from '@hooks/useFormBrand'
import useBrandMutation from '@hooks/queries/useBrand'
import { CircularProgress } from '@material-ui/core'
import LegalText from '../LegalText'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

export type InputsPrivacyPolicyPage = {
  privacyPolicy: string
}

type OnSubmitPrivacyPolicy = {
  privacyPolicy: string
}

const CreatePrivacyPolicy = ({ brandName }: { brandName: string }) => {
  const classes = useStyles()

  const { isLoading, createBrandPrivacyPolicy, updatePrivacyPolicy } =
    useBrandLegalResourcesMutation()
  const { brandId, setOpen, privacyPolicy } = useFormBrand()
  const { updatePrivacyPolicyStatus } = useBrandMutation()

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<InputsPrivacyPolicyPage>()

  const rules = {
    privacyPolicy: register('privacyPolicy'),
  }

  const initialValue = `<h3>Politique de confidentialité | ${brandName}</h3>`

  const onSubmit: SubmitHandler<OnSubmitPrivacyPolicy> = async (data) => {
    if (brandId) {
      if (privacyPolicy && privacyPolicy.privacyPolicy.length > 0) {
        const { data: updatePrivacyPolicyResult } = await updatePrivacyPolicy({
          brand: brandId,
          ...data,
        })
      }
      try {
        const { data: result } = await createBrandPrivacyPolicy({
          brand: brandId,
          ...data,
        })

        if (result) {
          await updatePrivacyPolicyStatus({
            id: brandId,
            privacyPolicyStatus: true,
          })
          setOpen(false)
          setValue('privacyPolicy', '')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LegalText
        control={control}
        name={rules.privacyPolicy.name}
        initialValue={
          privacyPolicy ? privacyPolicy.privacyPolicy : initialValue
        }
      />
      <button type="submit" className={classes.btn}>
        {isLoading ? (
          <CircularProgress
            size={20}
            style={{
              color: '#fff',
            }}
          />
        ) : (
          'Enregistrer'
        )}
      </button>
    </form>
  )
}

export default CreatePrivacyPolicy
