/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Brand,
  CampaignAction,
  CampaignNoAction,
  CAMPAIGN_ACTION_TYPE,
  TIME_MESURE,
} from '@airflex/lib-models'
import { SubmitHandler } from 'react-hook-form'

import Stepper from '../Stepper'

import {
  CAMPAIGN_FORM_TYPE,
  useFormCampaign,
} from '../../hooks/useFormCampaign'
import useCampaignMutation from '../../hooks/queries/useCampaign'

import ChooseBrand from './campaign/ChooseBrand'
import ChooseLoginPage from './campaign/ChooseLoginPage'
// eslint-disable-next-line import/no-cycle
import ChooseAction from './campaign/ChooseAction'
import ChooseFinish, { InputsFinish } from './campaign/ChooseFinish'

const steps = Object.keys(CAMPAIGN_FORM_TYPE).map((value) => value)

interface ICreateCampaignProps {
  brands: Brand[]
}

type MultiplePlayInfo = {
  multiplePlay: boolean
  delay: number
  mesure: string
}

type InputBrand = {
  brand: string
}

type InputLoginpage = {
  title: string
  description: string
  image?: FormData
}

const useStyles = makeStyles((theme) => ({
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

const CreateCampaign = ({ brands }: ICreateCampaignProps) => {
  const [error, setError] = useState({ status: false, message: '' })
  const [multiplePlayInfo, setMultiplePlayInfo] = useState<MultiplePlayInfo>({
    multiplePlay: false,
    delay: 24,
    mesure: 'HOUR',
  })

  const stepperRef: any = useRef()
  const classes = useStyles()
  const {
    open,
    setType,
    type,
    setActions,
    actions,
    actionType,
    actionsImage,
    brandId,
    loginPage,
    loginImage,
    setOpen,
    setBrandId,
    setLoginPage,
    setLoginImage,
    setActionsImage,
    setActionType,
  } = useFormCampaign()

  const { createCampaign, updateCampaignImage, updateCampaignImageAction } =
    useCampaignMutation()

  useEffect(() => {
    if (!open) {
      stepperRef.current?.handleReset()
      if (type !== CAMPAIGN_FORM_TYPE.BRAND) {
        setType(CAMPAIGN_FORM_TYPE.BRAND)
      }
    }
  }, [open])

  const handleMultiplePlayInfo = (
    data: any,
    key: 'multiplePlay' | 'delay' | 'mesure'
  ) => {
    const newMultiplePlayInfo = { ...multiplePlayInfo, [key]: data }
    setMultiplePlayInfo(newMultiplePlayInfo)
  }
  const handleStep = async () => {
    await stepperRef.current?.handleComplete()
    setType(steps[stepperRef.current?.activeStep()] as CAMPAIGN_FORM_TYPE)
  }

  const clearAll = () => {
    setBrandId('')
    setLoginPage({
      title: '',
      description: '',
    })
    setLoginImage(undefined)
    setActionsImage([])
    setActions([])
    setOpen(false)
    setError({ status: false, message: '' })
    setActionType(undefined)
    setMultiplePlayInfo({
      multiplePlay: false,
      delay: 24,
      mesure: 'HOUR',
    })
  }

  const onSubmitBrand: SubmitHandler<InputBrand> = (data) => {
    handleStep()
  }

  const onSubmitLoginPage: SubmitHandler<InputLoginpage> = (data: any) => {
    if (data.image) {
      const { image, ...rest } = data
      const formData = new FormData()
      formData.append('image', image[0])
      setLoginPage({ ...rest })
      setLoginImage(formData)
    } else {
      setLoginPage({
        ...data,
      })
    }
    handleStep()
  }

  const onSubmitAddAction = (data: any, setErrorAction: any) => {
    let errorCount = 0

    Object.entries(data).forEach((item) => {
      const key = item[0]
      const value = item[1] as string

      if (
        (key !== 'image' && value === undefined) ||
        (key !== 'image' && value === '')
      ) {
        setErrorAction(key, {
          type: 'manual',
          message: `erreur avec ${key}`,
        })

        errorCount += errorCount + 1
      }
    })

    if (errorCount === 0) {
      if (data.image) {
        const { image, ...rest } = data
        const formData = new FormData()
        formData.append('image', data.image[0])
        setActions([...actions, { ...rest }])
        setActionsImage([...actionsImage, { type: rest.type, image: formData }])
      } else {
        setActions([...actions, data])
      }
      setActionType(undefined)
    }
  }

  const onSubmitAction: SubmitHandler<CampaignAction> = (data: any) => {
    if (data.image) {
      const { image, ...rest } = data
      const formData = new FormData()
      formData.append('image', data.image[0])
      setActions([...actions, { ...rest }])
      setActionsImage([...actionsImage, { type: rest.type, image: formData }])
    } else {
      setActions([...actions, data])
    }

    handleStep()
  }

  const onSubmitNoAction: SubmitHandler<CampaignNoAction> = (data: any) => {
    setActions([...actions, data])
    handleStep()
  }

  const onSubmitFinish: SubmitHandler<InputsFinish> = async (data) => {
    const startDate = new Date(data.startDate)
    const endDate = new Date(data.endDate)

    const delay =
      multiplePlayInfo.mesure === TIME_MESURE.DAY
        ? Number(multiplePlayInfo.delay) * 24
        : multiplePlayInfo.mesure === TIME_MESURE.MINUTE
        ? Number(multiplePlayInfo.delay) / 60
        : Number(multiplePlayInfo.delay)

    const body = {
      brand: brandId,
      ...loginPage,
      actions,
      startDate,
      endDate,
      multiplePlay: multiplePlayInfo.multiplePlay,
      delay: multiplePlayInfo.multiplePlay ? delay : 0,
      isDeleted: false,
    }

    try {
      const { data: campaign } = await createCampaign(body)
      if (loginImage) {
        await updateCampaignImage({ id: campaign._id, formData: loginImage })
      }
      if (actionsImage.length > 0) {
        /* eslint-disable no-await-in-loop */
        for (const action of actionsImage) {
          await updateCampaignImageAction({
            id: campaign._id,
            type: action.type as CAMPAIGN_ACTION_TYPE,
            formData: action.image,
          })
        }
      }
      clearAll()
    } catch (e) {
      console.log(e)
      setError({
        status: true,
        message: 'Une erreur est survenue, veuillez recommencer',
      })
    }
  }

  return (
    <div>
      <Stepper steps={steps} ref={stepperRef} />
      {type === CAMPAIGN_FORM_TYPE.BRAND && (
        <ChooseBrand brands={brands} onSubmit={onSubmitBrand} />
      )}
      {type === CAMPAIGN_FORM_TYPE.LOGINPAGE && (
        <ChooseLoginPage onSubmit={onSubmitLoginPage} />
      )}
      {type === CAMPAIGN_FORM_TYPE.ACTION && (
        <ChooseAction
          onSubmit={onSubmitAction}
          onSubmitNoAction={onSubmitNoAction}
          onSubmitAdd={onSubmitAddAction}
          multiplePlayInfo={multiplePlayInfo}
          handleMultiplePlayInfo={handleMultiplePlayInfo}
          actions={actions}
        />
      )}
      {type === CAMPAIGN_FORM_TYPE.FINISH && (
        <ChooseFinish onSubmit={onSubmitFinish} />
      )}
      {error.status && (
        <div className={classes.fieldError}>
          <span className={classes.invalidFeed}>{error.message}</span>
        </div>
      )}
    </div>
  )
}

export default CreateCampaign
