import React, { forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 16,
    '& .MuiMobileStepper-root': {
      padding: 0,
    },
    '& .MuiMobileStepper-progress': {
      width: '100%',
      backgroundColor: `${theme.palette.primary.main}59`,
      borderRadius: 999,
    },
  },
}))

interface IStepperProps {
  steps: string[]
}

const StepperProgress = forwardRef(({ steps }: IStepperProps, ref) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  useImperativeHandle(ref, () => ({
    handleComplete() {
      handleNext()
    },
    handlePrev() {
      handlePrev()
    },
    activeStep() {
      return activeStep
    },
    handleReset() {
      handleReset()
    },
  }))

  return (
    <div className={classes.root}>
      <MobileStepper
        variant="progress"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
    </div>
  )
})

export default StepperProgress
