import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CustomInput from '../../../components/CustomInput'
import Button from '../../../components/MaterialUI/Button'
import Divider from '../../../components/MaterialUI/Divider'

import config from '../../../res/config'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: 'auto',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  spacer: {
    paddingBottom: theme.spacing(2),
  },
  contentContainer: {
    backgroundColor: '#fff',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    [theme.breakpoints.up('sm')]: {
      boxShadow: '8px 0 6px 0 rgb(71 99 136 / 10%)',
      overflowY: 'auto',
    },
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brand: {
    width: '50%',
    marginBottom: theme.spacing(4),
  },
  imageContainer: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    zIndex: -1,
  },
  error: {
    paddingLeft: 20,
    color: theme.palette.error.main,
    fontSize: 14,
  },
}))

function SignInScreen() {
  const classes = useStyles()
  const { login, loginIsError, loginIsLoading } = useAuth()

  const { handleSubmit, control, setError } = useForm()

  useEffect(() => {
    if (loginIsError) {
      setError(
        'password',
        { type: 'manual', message: 'email ou mot de passe incorrect' },
        { shouldFocus: true }
      )
    }
  }, [loginIsError])

  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Container component="main" maxWidth="xs">
          <div>
            <div className={classes.brandContainer}>
              <img
                src={config.pictures.logo}
                className={classes.brand}
                alt=""
              />
            </div>
            <Divider />
            <div className={classes.spacer} />
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: 'center' }}
            >
              Connexion
            </Typography>
            <div className={classes.spacer} />
            <form className={classes.form} onSubmit={handleSubmit(login)}>
              <div className={classes.spacer} />
              <CustomInput
                control={control}
                label="email"
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: 'Ce Champs Est Requis',
                  },
                  pattern: {
                    value: re,
                    message: 'Mauvais email',
                  },
                }}
              />
              <CustomInput
                control={control}
                name="password"
                label="Password"
                type="password"
                rules={{
                  required: {
                    value: true,
                    message: 'Ce Champs Est Requis',
                  },
                }}
              />
              <div className={classes.spacer} />
              <Button
                rounded
                fullWidth
                data-testid="login-submit-button"
                type="submit"
                variant="contained"
                color="primary"
                loading={loginIsLoading}
              >
                Se Connecter
              </Button>
              <Grid container />
            </form>
          </div>
        </Container>
      </div>
      <div className={classes.imageContainer}>
        <img
          src={config.pictures.signIn}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          alt=""
        />
      </div>
    </div>
  )
}

export default SignInScreen
