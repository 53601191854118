/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'

export enum ThemeScheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface IThemeContext {
  scheme: ThemeScheme
}

const initialState: IThemeContext = {
  scheme: ThemeScheme.LIGHT,
}

const ThemeContext = createContext({
  ...initialState,
  setScheme: (scheme: ThemeScheme) => {},
})

function ThemeContextProvider(props: any) {
  const { children } = props
  const [scheme, setScheme] = useState<ThemeScheme>(initialState.scheme)

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const overrideSetScheme = (newScheme: ThemeScheme) => {
    localStorage.setItem('@azot:scheme', newScheme)
    setScheme(newScheme)
  }

  const context = {
    scheme,
    setScheme: overrideSetScheme,
  }

  useEffect(() => {
    const schemePreference = localStorage.getItem('@azot:scheme') as ThemeScheme

    if (schemePreference) setScheme(schemePreference)
    else if (prefersDarkMode) overrideSetScheme(ThemeScheme.DARK)
    else localStorage.setItem('@azot:scheme', scheme)
  }, [scheme, prefersDarkMode])

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  )
}

const useThemeScheme = () => useContext(ThemeContext)

export { ThemeContextProvider, useThemeScheme }
