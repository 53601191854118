import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import ColorPicker from 'material-ui-color-picker'
import { OFFER_STATUS, WheelOffer } from '@airflex/lib-models'

interface IChooseOfferLoseProps {
  onSubmit: (data: any) => Promise<void>
  positionAngle: number
  defaultValue?: WheelOffer
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiInputLabel-formControl[data-shrink=true]': {
      transform: 'translate(0, 4px) scale(1)',
    },
  },
  labelControl: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
    left: 22,
    transform: 'translate(0, 40px) scale(1)',
  },
  field: {
    position: 'relative',
    marginBottom: 15,
  },
  label: {
    position: 'absolute',
    top: 32,
    right: 24,
    left: 24,
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  labelSlider: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInputControl: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  fieldInput: {
    width: '100%',
    height: 80,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  btnBorder: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  focusError: {
    borderColor: theme.palette.error.main,
    '& .MuiInputBase-input:focus': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  // Color Picker
  inputColorPicker: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      width: '100%',
      height: 80,
      padding: '18px 22px 0',
      borderRadius: 12,
      border: '2px solid #0000',
      '&:focus': {
        borderColor: theme.palette.primary.light,
      },
      background:
        theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.hint,
      transition: 'all .2s',
      outline: 'none',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
}))

type InputsOfferLoseType = {
  status: OFFER_STATUS
  label: string
  bgColor: string
  labelColor: string
  positionAngle: number
}

const ChooseOfferLose = ({
  onSubmit,
  positionAngle,
  defaultValue,
}: IChooseOfferLoseProps) => {
  const [bgColor, setBgColor] = useState<string>()
  const [labelColor, setLabelColor] = useState<string>()

  const classes = useStyles()

  useEffect(() => {
    if (defaultValue?.bgColor) {
      setBgColor(defaultValue.bgColor)
    }
    if (defaultValue?.labelColor) {
      setLabelColor(defaultValue.labelColor)
    }
  }, [defaultValue])

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<InputsOfferLoseType>({
    defaultValues: {
      status: OFFER_STATUS.LOSE,
      label: 'Perdu',
      positionAngle,
    },
  })

  const rules = {
    label: register('label', {
      required: { value: true, message: 'Choisir un texte' },
    }),
    bgColor: register('bgColor', {
      required: { value: true, message: 'Choisir la couleur de fond' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
    labelColor: register('labelColor', {
      required: { value: true, message: 'Choisir la couleur du texte' },
      pattern: { value: /^#[0-9a-f]{3,6}$/i, message: 'Choisir format HEX' },
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInputControl,
        }}
        InputLabelProps={{
          className: classes.labelControl,
        }}
        label="Texte du quartier"
        defaultValue={defaultValue?.label || 'Perdu'}
        type="text"
        {...rules.label}
      />
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          couleur du texte
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.labelColor && classes.focusError
            ),
          }}
          name={rules.labelColor.name}
          inputRef={rules.labelColor.ref}
          defaultValue={defaultValue?.labelColor || '#000000'}
          value={labelColor}
          showPicker
          onChange={(color) => {
            setLabelColor(color)
            setValue('labelColor', color)
          }}
          onBlur={() => clearErrors('labelColor')}
        />
      </div>
      <div className={classes.field}>
        <div
          className={classes.label}
          style={{ transform: 'translateY(-12px)' }}
        >
          couleur du fond
        </div>
        <ColorPicker
          classes={{
            root: clsx(
              classes.inputColorPicker,
              !!errors.bgColor && classes.focusError
            ),
          }}
          name={rules.bgColor.name}
          inputRef={rules.bgColor.ref}
          defaultValue={defaultValue?.bgColor || '#FFFFFF'}
          value={bgColor}
          onChange={(color) => {
            setBgColor(color)
            setValue('bgColor', color)
          }}
          onBlur={() => clearErrors('bgColor')}
        />
      </div>
      <button
        type="submit"
        className={clsx(classes.btn, classes.btnBlue)}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        Suivant
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`offer_win-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseOfferLose
