import {
  Campaign,
  CampaignAction,
  CAMPAIGN_ACTION_TYPE,
} from '@airflex/lib-models'
import axios from 'axios'

const urls = {
  createCampaign: '/campaigns',
  getCampaigns: '/campaigns',
  getCampaignById: '/campaigns/:id',
  updateImageCampaign: '/campaigns/:id/update-image',
  updateImageActionCampaign: '/campaigns/action/:id/:type/update-image',
  softDeleteCampaign: '/campaigns/:id/soft-delete-campaign',
  deleteCampaign: '/campaigns',
}

type CreateCampaignTypes = {
  brand: string
  title: string
  description: string
  actions: CampaignAction[]
  startDate: Date
  endDate: Date
}

interface UpdateCampaignImageVariables {
  formData: FormData
  id: string
}

interface UpdateCampaignImageActionVariables {
  formData: FormData
  id: string
  type: CAMPAIGN_ACTION_TYPE
}

const createCampaign = (createCampaignBody: CreateCampaignTypes) =>
  axios.post(urls.createCampaign, createCampaignBody)

const getCampaigns = () => axios.get(urls.getCampaigns)

const getCampaignById = async (
  id: string | string[] | undefined
): Promise<Campaign> => {
  try {
    const { data } = await axios.get(urls.getCampaignById, {
      urlParams: { id: id as string },
    })
    return data
  } catch (e) {
    throw new Error('invalid campaignId')
  }
}

const updateImageCampaign = ({ formData, id }: UpdateCampaignImageVariables) =>
  axios.patch(urls.updateImageCampaign, formData, { urlParams: { id } })

const updateImageActionCampaign = ({
  formData,
  id,
  type,
}: UpdateCampaignImageActionVariables) =>
  axios.patch(urls.updateImageActionCampaign, formData, {
    urlParams: { id, type },
  })

const softDeleteCampaign = ({
  id,
  isDeleted,
}: {
  id: string
  isDeleted: boolean
}) => axios.patch(urls.softDeleteCampaign, { isDeleted }, { urlParams: { id } })

const deleteCampaign = (campaignId: string) =>
  axios.delete(urls.deleteCampaign, {
    data: { id: campaignId },
  })

const campaignService = {
  createCampaign,
  getCampaigns,
  getCampaignById,
  updateImageCampaign,
  updateImageActionCampaign,
  softDeleteCampaign,
  deleteCampaign,
}

export default campaignService
