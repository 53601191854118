import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextareaAutosize, TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { wheelPageTypes } from '@hooks/useFormWheel'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

interface IChooseWheelPageProps {
  onSubmit: (data: any) => Promise<void>
  defaultValue?: wheelPageTypes
}

export type InputsWheelPage = {
  title: string
  description_1: string
  description_2: string
}

const ChooseWheelPage = ({ onSubmit, defaultValue }: IChooseWheelPageProps) => {
  const [text, setText] = useState('choisir une image')
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InputsWheelPage>()

  const rules = {
    title: register('title', {
      required: { value: true, message: 'Choisir un titre' },
    }),
    description_1: register('description_1', {
      required: { value: true, message: 'Choisir une description 1' },
    }),
    description_2: register('description_2', {
      required: { value: true, message: 'Choisir une description 2' },
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <div className={classes.label}>Texte de Gain</div>
        <TextareaAutosize
          className={classes.fieldInput}
          aria-label="minimum height"
          minRows={3}
          placeholder="Texte de gain en-dessous du titre"
          defaultValue={defaultValue?.title}
          {...rules.title}
        />
      </div>
      <div className={classes.formControl}>
        <div className={classes.label}>Decsription 1</div>
        <TextareaAutosize
          className={classes.fieldInput}
          aria-label="minimum height"
          minRows={3}
          placeholder="Description 1 en-dessous du champ de saisie"
          defaultValue={defaultValue?.description_1}
          {...rules.description_1}
        />
      </div>
      <div className={classes.formControl}>
        <div className={classes.label}>Description 2</div>
        <TextareaAutosize
          className={classes.fieldInput}
          aria-label="minimum height"
          minRows={3}
          placeholder="Description 2 en-dessous du champ de saisie"
          defaultValue={defaultValue?.description_2}
          {...rules.description_2}
        />
      </div>
      <button
        type="submit"
        className={classes.btn}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        suivant
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`wheel_page-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseWheelPage
