import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'

import Layout from '../../../components/Layout'
import Card from '../../../components/Card'

import { getWheelsStatus } from '../../../hooks/queries/useWheel'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.333333,
    marginBottom: 48,
    color: theme.palette.text.hint,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: 0,
    },
  },
  tab: {
    color: 'black',
    fontSize: '12px',
  },
  tabPanel: {
    '&>div': {
      padding: 0,
    },
  },
}))

export default function DashboardScreen() {
  const classes = useStyle()
  const [value, setValue] = useState(0)

  const { data: wheels, isLoading: isLoadingWheel } = getWheelsStatus()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Layout container>
      <div className={classes.title}>Instances</div>
      {wheels && !isLoadingWheel && (
        <>
          <AppBar
            position="static"
            style={{
              boxShadow: 'inherit',
              borderBottom: '1px solid lightgrey',
              background: 'white',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Campagnes actives" className={classes.tab} />
              <Tab label="Campagnes terminées" className={classes.tab} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <div className={classes.list}>
              {wheels.map((wheel) => (
                <Card
                  key={`card-instance-${wheel._id}`}
                  instance={wheel}
                  index={0}
                />
              ))}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <div className={classes.list}>
              {wheels.map((wheel) => (
                <Card
                  key={`card-instance-${wheel._id}`}
                  instance={wheel}
                  index={1}
                />
              ))}
            </div>
          </TabPanel>
        </>
      )}
    </Layout>
  )
}
