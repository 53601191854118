import { useQuery } from 'react-query'
import voucherService from '../../services/voucher.service'

const useVoucherMutation = ['default']

export const getVoucherUsed = () =>
  useQuery('getVoucherUsed', voucherService.getVoucherUsed)

export const getVoucherByWheel = (wheelId: string) =>
  useQuery(['getVoucherByWheel', wheelId], () =>
    voucherService.getVoucherByWheel(wheelId)
  )

export default useVoucherMutation
