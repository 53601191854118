import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core'

function myTheme(themeName = 'light') {
  let theme = createMuiTheme({
    typography: {
      fontFamily: ['Nunito', 'Helvetica Neue', 'sans-serif'].join(','),
    },
    palette: {
      type: themeName,
      background: {
        default: themeName === 'light' ? '#fff' : '#1f2128',
        paper: themeName === 'light' ? '#fff' : '#242731',
      },
      text: {
        primary: themeName === 'light' ? '#11142d' : '#808191',
        secondary: '#88a0bf',
        hint: themeName === 'light' ? '#11142d' : '#fff',
      },
      primary: {
        main: '#3f8cff',
        dark: '#1b1d21',
        light: '#355dff',
      },
      secondary: {
        main: '#5c15c2',
        dark: '#49109b',
      },
      error: {
        main: '#f5365c',
        dark: '#ec0c38',
      },
      warning: {
        main: '#fb6340',
        dark: '#fa441b',
      },
      info: {
        main: '#11cdef',
        dark: '#0eafcc',
      },
      success: {
        main: '#2dce89',
        dark: '#26af74',
      },
      grey: {
        100: 'rgba(228, 228, 228, 0.1)',
        200: '#e4e4e4',
        300: 'rgba(228, 228, 228, 1)',
        400: '#808191',
        600: 'rgba(228, 228, 228, 0.3)',
        700: 'rgba(228, 228, 228, 0.4)',
      },
    },
    paragraph: {
      main: '#181414',
      dark: '#d9d9d9',
    },
  })

  theme = responsiveFontSizes(createMuiTheme(theme))

  return theme
}

export default myTheme
