import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { CircularProgress, TextField } from '@material-ui/core'

import useCampaignMutation from '../../../hooks/queries/useCampaign'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

interface IChooseFinishProps {
  onSubmit: (data: any) => Promise<void>
}

export type InputsFinish = {
  startDate: string
  endDate: string
}

const ChooseFinish = ({ onSubmit }: IChooseFinishProps) => {
  const classes = useStyles()
  const day = new Date().toISOString().split('T')[0]

  const { isLoading } = useCampaignMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<InputsFinish>({
    defaultValues: {
      startDate: day,
    },
  })

  const watchFields = watch()

  const lessThanEndDate = (startDate: any) => {
    if (startDate < watchFields.endDate) {
      return true
    }

    return 'Date de début dois être inférieur a celle de fin'
  }

  const moreThanDayStart = (startDate: any) => {
    if (startDate >= day) {
      return true
    }

    return "Date de début dois être suppérieur a aujourd'hui"
  }

  const moreThanStartDate = (startDate: any) => {
    if (startDate > watchFields.startDate) {
      return true
    }

    return 'Date de fin dois être suppérieur a celle du début'
  }

  const rules = {
    startDate: register('startDate', {
      required: { value: true, message: 'Choisir une date de début' },
      validate: {
        lessThanEndDate,
        moreThanDayStart,
      },
    }),
    endDate: register('endDate', {
      required: { value: true, message: 'Choisir une date de fin' },
      validate: {
        moreThanStartDate,
      },
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        label="Date de début"
        id="start-date"
        type="date"
        {...rules.startDate}
      />
      <TextField
        classes={{
          root: classes.formControl,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        id="end-date"
        label="Date de fin"
        type="date"
        {...rules.endDate}
      />

      <button
        type="submit"
        className={classes.btn}
        disabled={Object.keys(errors).some((val) => !!val)}
      >
        {isLoading ? (
          <CircularProgress
            size={20}
            style={{
              color: '#fff',
            }}
          />
        ) : (
          'Valider'
        )}
      </button>
      <div className={classes.fieldError}>
        {Object.keys(errors)
          .map((key, index) => {
            if ((errors as any)[key]) {
              return (
                <span
                  key={`login_page-error-${key}`}
                  className={classes.invalidFeed}
                >
                  {(errors as any)[key].message}
                </span>
              )
            }
            return undefined
          })
          .filter((value) => value)}
      </div>
    </form>
  )
}

export default ChooseFinish
