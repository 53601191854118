/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import clsx from 'clsx'
import {
  Brand,
  BrandGraphicChart,
  Campaign,
  OFFER_STATUS,
  Wheel,
} from '@airflex/lib-models'
import SportsEsportsOutlinedIcon from '@material-ui/icons/SportsEsportsOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import EditIcon from '@material-ui/icons/Edit'

import dateFormated, { dateInstance } from '../utils'

import Button from './Button'
import Table from './Table'

import useWheelMutation from '../hooks/queries/useWheel'
import useCampaignMutation from '../hooks/queries/useCampaign'

import campaignService from '../services/campaign.service'
import brandsService from '../services/brands.service'
import Dialog from './MaterialUI/Dialog'
import UpdateWheelOffers from './form/UpdateWheelOffers'

const BASE_URL = process.env.REACT_APP_BASE_URL

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      flex: '0 0 calc(50% - 32px)',
      maxWidth: 'calc(50% - 32px)',
      margin: '32px 16px 0',
      borderRadius: 24,
      background: theme.palette.background.paper,
      boxShadow: '0 8px 25px rgb(0 0 0 / 7%)',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        margin: 0,
        '&:not(:last-child)': {
          marginBottom: 32,
        },
      },

      '&:hover': {
        '& #hoverBtn': {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
    textCenter: {
      textAlign: 'center',
    },
    head: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 24,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[100],
      color: theme.palette.text.hint,
      textAlign: 'left',
    },
    sidebar: {
      flexShrink: 0,
      width: 364,
      marginRight: 0,
      transition: 'margin .2s',
      position: 'fixed',
      right: 0,
      top: 0,
      background: 'white',
      height: '100vh',
      zIndex: 999,
      boxShadow: '0px 0px 40px -10px #000',
      padding: 32,

      [theme.breakpoints.down(1339)]: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        margin: 0,
        overflow: 'auto',
        padding: 20,
        background: '#1b1d21e6',
        opacity: 1,
        visibility: 'visible',
        transition: 'all .2s',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        borderRadius: 0,
      },
    },
    warp: {
      position: 'relative',

      [theme.breakpoints.down(1339)]: {
        width: '100%',
        maxWidth: 630,
        margin: 'auto',
        padding: 32,
        borderRadius: 24,
        background: theme.palette.background.paper,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '24px 16px',
        borderRadius: 0,
      },
    },
    warpText: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      width: 48,
      height: 48,
      marginRight: 12,
    },
    logoContent: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      marginRight: 12,
      padding: 8,
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      borderRadius: '50%',
    },
    logoIcon: {
      fontSize: 24,
    },
    category: {
      fontSize: 18,
      lineHeight: 1.33333,
      fontWeight: 500,
    },
    info: {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.grey[400],
      textTransform: 'uppercase',
    },
    badge: {
      alignSelf: 'flex-start',
      padding: '0 8px',
      borderRadius: 12,
      background: '#bceaff',
      fontSize: 10,
      fontWeight: 700,
      letterSpacing: '1.5px',
      lineHeight: '24px',
      color: theme.palette.primary.main,
    },
    badgeList: {
      display: 'flex',
      gap: 3,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    badgeColor: {
      alignSelf: 'flex-start',
      padding: 4,
      borderRadius: '50%',
    },
    body: {
      padding: 24,
      paddingBottom: 72,
    },
    line: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 12,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    lineLabel: {
      fontWeight: 600,
      fontSize: 16,
    },
    action: {
      padding: '2px 0',
      fontSize: 12,
      '&:last-child': {
        paddingBottom: 16,
      },
    },
    date: {
      fontSize: 14,
      lineHeight: 1.33333,
      fontWeight: 500,
      paddingBottom: 16,
    },
    btn: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '0 0 24px 24px',
      visibility: 'hidden',
      opacity: 0,
      transition: 'all .2s',
    },
    btnDelete: {
      backgroundColor: theme.palette.warning.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
        color: '#000',
      },
    },
    close: {},
    btnIconClose: {
      fontSize: 26,
      verticalAlign: 'middle',
    },
    table: {
      [theme.breakpoints.down('xs')]: {
        overflowX: 'auto',
      },
    },
    bgRed: {
      backgroundColor: '#ffc3b5',
    },
  })
)

export interface ICardProps {
  center?: boolean
  campaign?: Campaign
  brand?: Brand
  graphic?: BrandGraphicChart
  wheel?: Wheel
  instance?: Wheel
  index?: number
}

function Card({
  center,
  campaign,
  brand,
  graphic,
  wheel,
  instance,
  index,
}: ICardProps) {
  const [brandData, setBrandData] = useState<Brand | undefined>()
  const [campaignData, setCampaignData] = useState<Campaign | undefined>()
  const [popupDeleteWheel, setPopupDeleteWheel] = useState<boolean>(false)
  const [popupUpdateWheel, setPopupUpdateWheel] = useState<boolean>(false)
  const [wheelToDelete, setWheelToDelete] = useState<string | null>(null)
  const [popupDeleteCampaign, setPopupDeleteCampaign] = useState<boolean>(false)
  const [campaignToDelete, setCampaignToDelete] = useState<string | null>(null)
  const classes = useStyles()

  const fetchDatas = async () => {
    try {
      const campaignFetch = await campaignService.getCampaignById(
        instance?.campaign as string
      )
      const brandFetch = await brandsService.getBrandById(campaignFetch.brand)
      setCampaignData(campaignFetch)
      setBrandData(brandFetch)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (instance) {
      fetchDatas()
    }
  }, [])

  const { softDeleteWheel, updateWheelStatus } = useWheelMutation()
  const { softDeleteCampaign, isLoading: isLoadingCampaign } =
    useCampaignMutation()

  const handleTogglePopupUpdateWheel = () =>
    setPopupUpdateWheel((prev) => !prev)

  const handleDeleteWheel = async (id: string) => {
    setPopupDeleteWheel(true)
    setWheelToDelete(id)
  }

  const handleConfirmDelete = async () => {
    if (wheelToDelete) {
      try {
        await softDeleteWheel({ id: wheelToDelete, isDeleted: true })
        setPopupDeleteWheel(false)
        setWheelToDelete(null)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleCancelDelete = async () => {
    setPopupDeleteWheel(false)
    setWheelToDelete(null)
  }

  const handleDeleteCampaign = async (id: string) => {
    setPopupDeleteCampaign(true)
    setCampaignToDelete(id)
  }

  const handleConfirmDeleteCampaign = async () => {
    if (campaignToDelete) {
      try {
        await softDeleteCampaign({ id: campaignToDelete, isDeleted: true })
        setPopupDeleteCampaign(false)
        setCampaignToDelete(null)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleCancelDeleteCampaign = async () => {
    setPopupDeleteCampaign(false)
    setCampaignToDelete(null)
  }

  const handleUpdateWheelStatus = async (id: string, status: boolean) => {
    try {
      await updateWheelStatus({
        id,
        status,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetInstance = (brandId: string, campId: string) => {
    const URL = `${BASE_URL}/brand/${brandId}/campaign/${campId}`
    window.open(URL, '_blank')
  }

  const delayBetweenActions = () => {
    if (campaign?.multiplePlay && campaign?.delay) {
      if (Number(campaign.delay < 1)) {
        return `Les joueurs peuvent rejouer toutes les ${
          Number(campaign.delay) * 60
        } minutes`
      }
      if (Number(campaign.delay) === 1) {
        return 'Les joueurs peuvent rejouer toutes les heures'
      }
      if (Number(campaign.delay) % 24 === 0) {
        if (Number(campaign.delay) === 24) {
          return 'Les joueurs peuvent rejouer tous les jours'
        }
        return `Les joueurs peuvent rejouer tous les ${
          Number(campaign.delay) / 24
        } jours`
      }

      return `Les joueurs peuvent rejouer toutes les ${Number(
        campaign.delay
      )} heures`
    }
    if (campaign && campaign?.actions.length > 0) {
      return "Il n'y a pas de délais entre les actions"
    }
    return "Les joueurs ne peuvent jouer qu'une seule fois"
  }

  if (campaign && brand && graphic && !campaign?.isDeleted) {
    const newDate = new Date(campaign.endDate).getTime()
    const isValidCampaign = newDate < Date.now()
    if ((index === 0 && !isValidCampaign) || (index === 1 && isValidCampaign)) {
      return (
        <>
          <div
            className={clsx(
              classes.root,
              center && classes.textCenter,
              isValidCampaign && classes.bgRed
            )}
          >
            <div className={classes.head}>
              <div className={classes.warpText}>
                <div className={classes.logo}>
                  <img src={brand.logo} alt="logo" />
                </div>
                <div>
                  <div className={classes.category}>{brand.name}</div>
                  <div className={classes.info}>
                    {!isValidCampaign ? (
                      <>#{campaign._id?.slice(16, 24)}</>
                    ) : (
                      'CAMPAGNE TERMINÉE'
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.badgeList}>
                <div
                  className={classes.badgeColor}
                  style={{ backgroundColor: graphic.bgColorBody }}
                />
                <div
                  className={classes.badgeColor}
                  style={{ backgroundColor: graphic.textColorBody }}
                />
                <div
                  className={classes.badgeColor}
                  style={{ backgroundColor: graphic.btnPrimaryColor }}
                />
                <div
                  className={classes.badgeColor}
                  style={{ backgroundColor: graphic.btnPrimaryTextColor }}
                />
                <div
                  className={classes.badgeColor}
                  style={{ backgroundColor: graphic.btnSecondaryColor }}
                />
                <div
                  className={classes.badgeColor}
                  style={{ backgroundColor: graphic.btnSecondaryTextColor }}
                />
              </div>
            </div>
            <div className={classes.body}>
              <div className={classes.line}>
                <div style={{ minHeight: 96, width: '100%' }}>
                  {campaign.actions.length === 0 ? (
                    <div className={classes.lineLabel}>Aucune Action</div>
                  ) : (
                    <>
                      <div className={classes.lineLabel}>Ordre des Actions</div>
                      {campaign.actions.map((action, idx) => (
                        <div
                          key={`card-action-${action.title}`}
                          className={classes.action}
                        >
                          {idx + 1} {action.type}
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div style={{ minHeight: 96, width: '100%' }}>
                  <div className={classes.lineLabel}>Date de Début</div>
                  <div className={classes.date}>
                    {dateFormated(campaign.startDate)}
                  </div>
                  <div className={classes.lineLabel}>Date de Fin</div>
                  <div className={classes.date}>
                    {dateFormated(campaign.endDate)}
                  </div>
                </div>
              </div>
              <div
                className={classes.line}
                style={{
                  background: '#e4e4e433',
                  padding: '16px',
                  marginLeft: '-24px',
                  width: 'calc(100% + 48px)',
                }}
              >
                <div className={classes.lineLabel} style={{ margin: 'auto' }}>
                  {delayBetweenActions()}
                </div>
              </div>
            </div>
            <Button
              fullWidth
              styles={clsx(classes.btn, classes.btnDelete)}
              id="hoverBtn"
              onClick={() => handleDeleteCampaign(campaign._id as string)}
            >
              {isLoadingCampaign ? (
                <CircularProgress
                  size={20}
                  style={{
                    color: '#fff',
                  }}
                />
              ) : (
                'Supprimer'
              )}
            </Button>
          </div>
          {popupDeleteCampaign && (
            <Dialog
              open={popupDeleteCampaign}
              title="Êtes-vous sûr de vouloir supprimer cette campagne ?"
              handleClose={handleCancelDeleteCampaign}
              handleValid={handleConfirmDeleteCampaign}
            />
          )}
        </>
      )
    }
  }

  if (wheel && wheel.isDeleted === false) {
    return (
      <>
        <div className={classes.root} style={{ maxWidth: '100%' }}>
          <div className={classes.head}>
            <div className={classes.warpText}>
              <div className={classes.logoContent}>
                <SportsEsportsOutlinedIcon className={classes.logoIcon} />
              </div>
              <div>
                <div className={classes.category}>
                  {wheel.percentageTotal} % de chance de Gain
                </div>
                <div className={classes.info}>
                  <strong>{campaign?.brandInfo?.name}</strong> (#
                  {campaign?._id?.slice(16, 24)})
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className={classes.close}
                onClick={handleTogglePopupUpdateWheel}
              >
                <EditIcon className={classes.btnIconClose} />
              </button>
              <button
                type="button"
                className={classes.close}
                onClick={() => handleDeleteWheel(wheel._id as string)}
              >
                <CloseOutlinedIcon className={classes.btnIconClose} />
              </button>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.line}>
              <div
                className={classes.table}
                style={{ minHeight: 96, width: '100%' }}
              >
                <div
                  className={classes.lineLabel}
                  style={{ paddingBottom: 16 }}
                >
                  Offre des Quartiers
                </div>
                <Table
                  headLabels={[
                    'Quartier',
                    'Offre',
                    'code',
                    'Activation',
                    'Expiration',
                    'Pourcentage',
                    'Limite',
                    'Délais',
                  ]}
                  datasWheel={wheel.offers}
                />
              </div>
            </div>
          </div>
          {wheel.status ? (
            <Button
              fullWidth
              styles={clsx(classes.btn, classes.btnDelete)}
              id="hoverBtn"
              onClick={() =>
                handleUpdateWheelStatus(wheel._id as string, false)
              }
            >
              Désactiver
            </Button>
          ) : (
            <Button
              primary
              fullWidth
              styles={classes.btn}
              id="hoverBtn"
              onClick={() => handleUpdateWheelStatus(wheel._id as string, true)}
            >
              Activer
            </Button>
          )}
        </div>
        {popupDeleteWheel && (
          <Dialog
            open={popupDeleteWheel}
            title="Êtes-vous sûr de vouloir supprimer cette roue ?"
            handleClose={handleCancelDelete}
            handleValid={handleConfirmDelete}
          />
        )}
        {popupUpdateWheel && (
          <aside className={classes.sidebar}>
            <div className={classes.warp}>
              <UpdateWheelOffers
                wheel={wheel}
                close={handleTogglePopupUpdateWheel}
              />
            </div>
          </aside>
        )}
      </>
    )
  }

  if (instance && brandData && campaignData) {
    const newDate = new Date(campaignData.endDate).getTime()
    const isValid = newDate < Date.now()
    if ((index === 0 && !isValid) || (index === 1 && isValid)) {
      return (
        <div
          className={clsx(
            classes.root,
            center && classes.textCenter,
            isValid && classes.bgRed
          )}
        >
          <div className={classes.head}>
            <div className={classes.warpText}>
              <div className={classes.logo}>
                <img src={brandData.logo} alt="logo" />
              </div>
              <div>
                <div className={classes.category}>{brandData.name}</div>
                <div className={classes.info}>
                  {!isValid ? (
                    <>
                      {dateInstance(campaignData.startDate)} |{' '}
                      {dateInstance(campaignData.endDate)}
                    </>
                  ) : (
                    'CAMPAGNE TERMINÉE'
                  )}
                </div>
              </div>
            </div>
            <div className={classes.badge}>{instance.percentageTotal}%</div>
          </div>
          <div className={classes.body} style={{ padding: '16px' }}>
            <div className={classes.line}>
              <div style={{ minHeight: 96, width: '100%' }}>
                <Table
                  headLabels={[
                    'Quartier',
                    'Offre',
                    'code',
                    'pourcentage',
                    'limite',
                  ]}
                  datasInstance={instance.offers.filter(
                    (value) => value.status === OFFER_STATUS.WIN
                  )}
                />
              </div>
            </div>
          </div>
          <Button
            primary
            fullWidth
            styles={classes.btn}
            id="hoverBtn"
            onClick={() =>
              handleGetInstance(
                brandData._id as string,
                campaignData._id as string
              )
            }
          >
            Parcourir
          </Button>
        </div>
      )
    }
  }

  return null
}

export default Card
