import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { makeStyles, Button as ButtonDefault } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    minWidth: 114,
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'inherit',
    transition: 'all 0.2s',
  },
  white: {
    background: theme.palette.background.default,
    color: theme.palette.type === 'light' ? '#11142d' : '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      color: '#11142d',
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}bd`,
    },
  },
  fullWidth: {
    minWidth: '100% !important',
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
  },
}))

export interface IButtomProps {
  white?: boolean
  primary?: boolean
  fullWidth?: boolean
  border?: boolean
  children: ReactNode
  styles?: {}
  onClick?: () => void
  id?: string
  data?: string
}

function Button({
  border,
  fullWidth,
  primary,
  white,
  styles,
  children,
  onClick,
  data,
  ...rest
}: IButtomProps) {
  const classes = useStyles()

  return (
    <ButtonDefault
      onClick={onClick && onClick}
      classes={{
        root: classes.root,
      }}
      className={clsx(
        white && classes.white,
        primary && classes.primary,
        fullWidth && classes.fullWidth,
        border && classes.border,
        styles && styles
      )}
      data-cy={data}
      {...rest}
    >
      {children}
    </ButtonDefault>
  )
}

export default Button
