import React, { ReactNode } from 'react'
import { useRoutes } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import config from '../res/config'
import { useAuth } from '../hooks/useAuth'
import LoadingScreen from './MaterialUI/LoadingScreen'
import useGetRoutes from '../hooks/useGetRoutes'
import useRouting from '../hooks/useRouting'

import Drawer from './Drawer'
import Header from './Header'
import { useOpenDrawer } from '../res/context/openDrawer'

const DRAWER_WIDTH = config.drawerWidth

export interface IRootProps {
  children: ReactNode
}

type RootStyleTypes = {
  open: boolean
}

const useStyles = makeStyles<Theme, RootStyleTypes>((theme) => ({
  page: {
    overflow: 'hidden',
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    transition: 'padding 0.2s',
    paddingLeft: ({ open }) => (open ? DRAWER_WIDTH : DRAWER_WIDTH / 2.655),

    [theme.breakpoints.down('md')]: {
      paddingLeft: `${DRAWER_WIDTH / 2.655}px !important`,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important',
      transition: 'transform 0.2s',
    },
  },
  pageToggle: {
    [theme.breakpoints.down('sm')]: {
      '&': {
        transform: `translateX(${DRAWER_WIDTH}px) !important`,
      },
    },
  },
}))

function Root() {
  const { appIsLoaded, isLoggedIn } = useAuth()
  const routes = useGetRoutes(isLoggedIn && isLoggedIn)
  const element = useRoutes(routes)
  const routeInfo = useRouting()

  const { open } = useOpenDrawer()

  const classes = useStyles({ open })

  if (!appIsLoaded) return <LoadingScreen />

  return !routeInfo.route?.hideMenu ? (
    <div className={classes.page}>
      <Drawer routes={routes} routeInfo={routeInfo} />
      <div className={clsx(classes.pageContent, open && classes.pageToggle)}>
        <Header routeInfo={routeInfo} />
        {element}
      </div>
    </div>
  ) : (
    element
  )
}

export default Root
