import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useForm, SubmitHandler } from 'react-hook-form'
import CircularProgress from '@material-ui/core/CircularProgress'

import CustomFileInput from '../CustomFileInput'
import useBrandMutation from '../../hooks/queries/useBrand'

import { useFormBrand } from '../../hooks/useFormBrand'

export type CreateBrandStyleTypes = {
  active: boolean
}

export type Inputs = {
  name: string
  logo: string
}

type OnSubmitTypes = {
  name: string
  logo: any
}

const useStyles = makeStyles<Theme, CreateBrandStyleTypes>((theme) => ({
  field: {
    position: 'relative',
    marginBottom: 15,
  },
  label: {
    position: 'absolute',
    top: 32,
    right: 24,
    left: 24,
    pointerEvents: 'none',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
    transform: ({ active }) => (active ? 'translateY(-12px)' : ''),
    transition: 'transform .2s',
  },
  fieldInput: {
    width: '100%',
    height: 80,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
  focusError: {
    borderColor: theme.palette.error.main,
  },
}))

export const CreateBrand = () => {
  const [active, setActive] = useState(false)
  const { setOpen } = useFormBrand()
  const [textInputFile, setTextInputFile] = useState('Choisir un logo...')
  const classes = useStyles({ active })

  const { isLoading, createBrandName, updateBrandLogo } = useBrandMutation()

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>()

  const rules = {
    name: register('name', {
      required: { value: true, message: 'Choisir un nom' },
    }),
    logo: register('logo', {
      required: { value: true, message: 'Choisir une image' },
    }),
  }

  const onSubmit: SubmitHandler<OnSubmitTypes> = async ({ name, logo }) => {
    try {
      const {
        data: { _id: id },
      } = await createBrandName({ name })
      const formData = new FormData()
      formData.append('logo', logo[0])
      const { data } = await updateBrandLogo({ id, formData })
      if (data) {
        setOpen(false)
        setValue('name', '')
        setValue('logo', '')
        setTextInputFile('Choisir un logo...')
      }
    } catch (e) {
      console.log(e)
      setError('logo', {
        type: 'manual',
        message: 'Une erreur est survenue, veuillez recommencer',
      })
    }
  }

  const removeActive = (e: any) => {
    if (e.target.value === '') {
      setActive(false)
    }
  }

  const handleClearErrors = () => {
    clearErrors('name')
    clearErrors('logo')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.field}>
        <div className={classes.label}>nom</div>
        <div>
          <input
            className={clsx(
              classes.fieldInput,
              !!errors.name && classes.focusError
            )}
            type="text"
            {...rules.name}
            onBlur={removeActive}
            onFocus={() => {
              setActive(true)
              handleClearErrors()
            }}
          />
        </div>
      </div>
      <CustomFileInput
        name="logo"
        control={control}
        clearOnFocus={handleClearErrors}
        text={textInputFile}
        deleteCurrentImage={null}
      />
      <button
        type="submit"
        className={classes.btn}
        disabled={!!errors.name || !!errors.logo}
      >
        {isLoading ? (
          <CircularProgress
            size={20}
            style={{
              color: '#fff',
            }}
          />
        ) : (
          'Créer'
        )}
      </button>
      <div className={classes.fieldError}>
        {errors.name && (
          <span className={classes.invalidFeed}>{errors.name.message}</span>
        )}
        {errors.logo && (
          <span className={classes.invalidFeed}>{errors.logo.message}</span>
        )}
      </div>
    </form>
  )
}
