import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SubmitHandler } from 'react-hook-form'

import { Wheel, WheelOffer } from '@airflex/lib-models'
import useWheelMutation from '@hooks/queries/useWheel'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'

interface IUpdateWheelOffersProps {
  wheel: Wheel
  close: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    position: 'relative',
  },
  title: {
    fontSize: 24,
    paddingTop: 15,
    marginBottom: 20,
  },
  btnIconClose: {
    fontSize: 26,
    verticalAlign: 'middle',
  },
  close: {
    display: 'flex',
    position: 'absolute',
    right: -10,
    top: -15,
  },
  form: {
    overflowY: 'scroll',
    height: '80vh',
    marginRight: -10,
    paddingRight: 20,
  },
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
}))

const UpdateWheelOffers = ({ wheel, close }: IUpdateWheelOffersProps) => {
  const [offers, setOffers] = useState<WheelOffer[] | null>(null)
  const classes = useStyles()

  const { updateWheelOffers } = useWheelMutation()

  useEffect(() => {
    if (wheel.offers) {
      setOffers(wheel.offers)
    }
  }, [wheel])

  const handleChange = (index: number, value: string) => {
    if (offers?.length) {
      const newOffers = [...offers]
      newOffers[index].label = value
      setOffers(newOffers)
    }
  }

  const onSubmitFinish: SubmitHandler<any> = async (data) => {
    if (wheel._id && offers && offers?.length > 0) {
      try {
        await updateWheelOffers({
          id: wheel._id,
          offers: [...offers],
        })
        close()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className={classes.container}>
      <button type="button" className={classes.close} onClick={() => close()}>
        <CloseOutlinedIcon className={classes.btnIconClose} />
      </button>
      <h1 className={classes.title}>
        Modifier les noms des quartiers de la roue
      </h1>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmitFinish(offers)
        }}
        className={classes.form}
      >
        {offers &&
          offers.map((item, index) => (
            <TextField
              type="text"
              value={item.label}
              onChange={(e) => handleChange(index, e.target.value)}
              label={`Quartier ${index + 1}`}
              classes={{
                root: classes.formControl,
              }}
              inputProps={{
                className: classes.fieldInput,
              }}
              InputLabelProps={{
                className: classes.label,
              }}
            />
          ))}
        <button type="submit" className={clsx(classes.btn, classes.btnBlue)}>
          Mettre à jour
        </button>
      </form>
    </div>
  )
}

export default UpdateWheelOffers
